import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContributionsHomeComponent } from './home/contributions-home.component';
import { InsightEditComponent } from './insight-edit/insight-edit.component';
import { ComingSoonComponent } from 'libs/shared/src/lib/pages/coming-soon/coming-soon.component';
import { DeliverableEditParentComponent } from './deliverable-edit-parent/deliverable-edit-parent.component';


const routes: Routes = [
  {
    path: '',
    component: ContributionsHomeComponent,
    // data: { label: 'Contributions homepage', translationKey: 'content.contributions', labelSource: null},
  },
  {
    path: ':deliverable_type', // 
    data: { label: 'Contribution types overview', translationKey: 'content.insights', labelSource: null },
    children: [
      {
        path: '',
        component: ComingSoonComponent,
      },
      {
        path: ':task_type',
        data: { label: 'Task', translationKey: null, labelSource: 'titleService' },
        children: [
          {
            path: '',
            component: DeliverableEditParentComponent,
          },
          {
            path: ':identifier',
            data: { label: 'Edit Insight', translationKey: null, labelSource: 'titleService', consent:['ct','cl'] },
            children: [
              {
                path: '',
                component: DeliverableEditParentComponent,
              }
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContributionsRoutingModule { }
