import { 
  Component, ElementRef, Input, ViewChild, Renderer2, forwardRef, input, signal, computed } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BellCurveModule, IconArrayModule } from '@frontend/shared';
import { TranslocoModule } from '@jsverse/transloco';
import { CultureScoreBenchmark } from '../../../cultures/culture-score-benchmark.model';
import { Culture } from '../../../cultures';
import { MediaService } from '../../../content';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


@Component({
  selector: 'multisite-form-control-lickert-question',
  templateUrl: './form-control-lickert-question.component.html',
  styleUrls: ['./form-control-lickert-question.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IconArrayModule, BellCurveModule, TranslocoModule, TooltipModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlLickertQuestionComponent),
      multi: true
    }
  ]
  // changeDetection: ChangeDetectionStrategy.OnPush, // this solves the harmless ExpressionChangedAfterItHasBeenCheckedError error in the browser. But ChangeDetectionStrategy.OnPush causes the offsetHeight and offsetWidth properties in the template to stop working. TODO: we probably need a custom change detection strategy for this component, to solve the ExpressionChangedAfterItHasBeenCheckedError error. Low priority.
})
export class FormControlLickertQuestionComponent implements ControlValueAccessor {

  visualisation = input<'bell_curve' | 'icon_array' | undefined>();
  anchorSetTranslationKeys = input<string[] | undefined>(undefined);
  anchorSetTranslationScope = input<string | undefined>(undefined);
  anchorSetTexts = input<string[] | undefined>(undefined);
  anchorSet = computed<string[]>(() => {
    if (this.anchorSetTranslationKeys()) {
      return this.anchorSetTranslationKeys();
    } else if (this.anchorSetTexts()) {
      return this.anchorSetTexts();
    } else {
      return [];
    }
  });
  groupedBenchmarks = input<CultureScoreBenchmark[] | undefined>(undefined);
  sliderThumbImageUrl = input<string | undefined>(undefined); // 'https://res.cloudinary.com/cebt/image/upload/w_36,c_fill,ar_1:1,r_max,f_auto/flags/round/gy4FJr.png'
  defaultUnsetValue = input.required<number>(); // for example -1, so we know that the user and the application has not yet set the value (a range input control's value cannot be null)
  min = input<number>(0);
  max = input<number>(100);
  step = input<number>(1);
  totalSteps = computed<number>(() => {
    return (this.max() - this.min()) / this.step();
  });
  stepAsPercentage = computed<number>(() => {
    return 100 / this.totalSteps();
  });
  stepAsPercentageAdjustedForGaps = computed<number>(() => {
    return this.stepAsPercentage() - this.totalSteps(); // Compensate for the accumulated gaps between the steps
  });
  @ViewChild('sliderInput') sliderInput: ElementRef;
  @ViewChild('labelHolder') labelHolder: ElementRef;
  // initialValue : number = 50;
  inputValue = signal<number | undefined>(undefined);
  shapePosition : number = 0; // TODO - looks like this can be removed. Also consider removing from all other components (used in early prototypes)?
  bellCurveRelativeWidth = 25; // 25% of the parent container
  bellCurveRelativeScaleY = .25; // 25% of the parent container
  // isAnimationActive = false;
  sliderActive : boolean = false; // the user is moving the range input control
  sliderDisabled = signal<boolean>(false);

  constructor(private renderer: Renderer2, private el: ElementRef, private mediaService : MediaService) { 
      // this.inputValue = null; // this.initialValue;
  }

  // ControlValueAccessor methods
  writeValue(value: any): void {
    if (value !== undefined) {
      this.inputValue.set(value);
      if (this.sliderInput) {
        this.sliderInput.nativeElement.value = value;
      }
    }
  }

  private onChange: (value: any) => void = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Method to call when the value changes
  onValueChange(value: any): void {
    const newValue = Math.round(value);
    this.inputValue.set(newValue);
    this.onChange(value);
  }

  registerOnTouched(fn: any): void {
    // Implement if needed
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.sliderDisabled.set(true);
    } else {
      this.sliderDisabled.set(false);
    }
  }
  getSliderValue() {
    return this.sliderInput ? Number(this.sliderInput.nativeElement.value) : undefined;
  }
  // setSliderValue(value) {
  //   if(this.sliderInput){
  //     this.renderer.setProperty(this.sliderInput.nativeElement, 'value', this.inputValue());
  //     console.log('setSliderValue', this.getSliderValue());
  //   }
  // }

  onSliderMouseDown() {
    this.sliderActive = true;
  }
  onSliderMouseUp() {
    this.sliderActive = false;
  }

  setProperty(height){
    this.renderer.setStyle(this.el.nativeElement, '--element-height', height + 'px');
  }

  isAnchorActive(anchorIndex){
    
    if (!this.valueHasBeenSet()){
      // console.log('scenario 0');
      return false;
    } else if (anchorIndex === 0 && this.getSliderValue() === this.min()){
      // console.log('scenario 1');
      return true;
    } else if (anchorIndex === this.anchorSet().length-1 && this.getSliderValue() === this.max()){
      // console.log('scenario 2');
      return true;
    } else if (
      (this.getSliderValue() <= (this.max() / this.anchorSet().length) * (anchorIndex + 1)) &&
      (this.getSliderValue() > (this.max() / this.anchorSet().length) * anchorIndex) &&
      this.inputValue() !== undefined // this last condition prevents true when there's been no input yet
    ) {
      // console.log('scenario 3');
      return true;
      }
      // console.log('scenario 4');
      return false;
  }

  setSliderActiveBriefly (){
    this.sliderActive = true;
    setTimeout(()=>{
      this.sliderActive = false;
    },500);
  }

  setValueByLabel(labelIndex){
    let newValue;
    let anchorValue = (this.max()/this.anchorSet().length);
    if (this.anchorSet().length === 2){
      newValue = labelIndex ? this.max() : this.min();
    } else {
      newValue = labelIndex ? (anchorValue*labelIndex) + (anchorValue/2) : (anchorValue/2);
    } 
    // this.inputValue = Math.round(newValue);
    this.onValueChange(Math.round(newValue));
    // this.lickertForm.get('response').setValue(Math.round(newValue));
    // this.setSliderActiveBriefly();
  }
  setValueByValue(newValue:number){
    // this.inputValue = Math.round(newValue);
    this.onValueChange(Math.round(newValue));
    // this.lickertForm.get('response').setValue(Math.round(newValue));
    this.setSliderActiveBriefly();
  }


  isValidValue(): boolean {
    // return this.getSliderValue() === 0 || this.getSliderValue() > 0;
    return this.inputValue() === 0 || this.inputValue() > 0;
  }
  valueHasBeenSet(): boolean {
    return this.inputValue() !== this.defaultUnsetValue();
  }

  whatIsTheValue(){
    return this.getSliderValue();
  }
  getFlagUrlFromHash(item,transformations : string = ''){
    let typedItem = item as Culture;
    return this.mediaService.getFlagUrlFromHash(typedItem.flag?.hash,transformations,true,'.png');
  }

  ngAfterViewInit() {
    this.setProperty(0);
    // debugger;
    // this.setSliderValue(null);
  }

}
