<div class="progress-pie-doughnut" [ngClass]="fillClass" [style]="'width: '+elementWidth+'%'">
  <svg width="100%" height="100%" viewBox="0 0 40 40" class="doughnut">
    <circle class="doughnut-ring" [ngClass]="fillClass" cx="20" cy="20" r="16" fill="blue" stroke-width="8"></circle>
    <circle class="doughnut-segment stroke" [ngClass]="fillClass" cx="20" cy="20" r="16" stroke-width="8" stroke-dashoffset="24.8" [style.strokeDasharray]="progressPercent || progressPercent === 0 ? progressPercent+' '+(100-progressPercent) : null"></circle>
    <circle class="doughnut-hole" cx="20" cy="20" r="16.1"></circle>
  </svg>
  <div class="icon-holder" *ngIf="icon && (text === null || text === undefined)">
    <i [class]="icon.identifier_1" *ngIf="icon.identifier_1"></i>
    <img *ngIf="!icon.identifier_1 && icon.identifier_2" [src]="cloudinary_base_url+'w_32,c_fill,ar_1:1,r_max,f_auto/'+icon.identifier_2+'.svg'" class="mr-1">

  </div>
  <div class="icon-holder" [style]="'--fontSize:'+fontSize" *ngIf="text === 0 || text">
    {{text}}
  </div>
</div>