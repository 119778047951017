<ckeditor class="editor-classic"
    [editor]="Editor"
    [(ngModel)]="editorDataMarkdown"
    (change)="onEditorChange($event, 'simple');"
    (blur)="onEditorBlur();"
    [config]="configSimple"
    [disabled]="isDisabled"
  ></ckeditor>
  <div class="mt-2">
    {{editorDataMarkdown}}
  </div>

