import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { of, Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Cluster, Culture, DataProcessingService, LanguageService } from '@frontend/common';
import { GeoJsonFeature } from '../maps';
import { GeoJsonFeatureGeometry } from '../maps/geojson-feature-geometry.model';
import { ErrorService } from '../error';
import { CultureClusterWrapper } from './culture-cluster-wrapper.model';

// type GeoJsonResponseCluster = {
//   properties: {    
//     identifier : any, // iso_3166_2 in the case of clusters,
//     className : string, // 'Cluster', name of the model in the backend
//     category : string, // 'geographic' // same as the cluster's category
//     type : string, // 'national' // same as the cluster's type
//     geometry_type : string, // 'geometry_110'
//   },
//   geometry: GeoJsonFeatureGeometry,
//   geometry_meta? : any
// }
export type ClusterWrapperWithProperties = {
  properties: {
    cluster: Cluster,
    [key:string]: any
  },
}

@Injectable({
  providedIn: 'root',
})
export class ClusterService {
  activeLanguageSubscription: Subscription;
  clusters: Cluster[] = [];
  clustersGeoJson : GeoJsonFeature[] = [];

  constructor(
    private languageService: LanguageService,
    private http: HttpClient,
    private dataProcessingService : DataProcessingService,
    private errorService: ErrorService,
  ) 
  {
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(() => {
        this.clearTranslations();
      });
  }

  clearTranslations() {
    this.clusters = [];
  };
  cacheClusters(clusters:Cluster[]){
  
    if (this.clusters.length){
      this.clusters = this.dataProcessingService.mergeTwoArraysWithUniqueIdentifiersAvoidingDuplicates(clusters,this.clusters,'id');
    } else {
      this.clusters = clusters;
    }

  }
  
  getClusters(category:string,type:string, freshFromServer:boolean) {
    let filteredClusters : Cluster[] = [];
    if (category !== null && type !== null) {
      filteredClusters = this.clusters.filter(c => c.category === category && c.type === type);
    } else if (category !== null ) {
      filteredClusters = this.clusters.filter(c => c.category === category);
    } else if (type !== null) {
      filteredClusters = this.clusters.filter(c => c.type === type);
    }
    if (filteredClusters?.length && !freshFromServer) {
      return of(filteredClusters);
    }
    let url = 'api/v1/clusters?';
    url = category ? url+='category='+category:url;
    url = type ? url+='&type='+type:url;
    return this.http.get<{data:Cluster[]}>(url).pipe(
      map((response) => {
        if (response?.data?.length) {
          // response.data = this.transformClusters(response.data);
          this.cacheClusters(response.data);
          return response.data;
        }
      }),
      catchError(error=>this.handleError(error))

    );
  }
  getUniqueCulturesInCultureClusters(cultureClusterWrappers:CultureClusterWrapper[] = []):Culture[]{
      return cultureClusterWrappers.map(cluster => cluster.cultures).reduce((acc, val) => acc.concat(val), []).filter((culture, index, self) => self.findIndex(c => c.slug === culture.slug) === index);
  }
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    // if (errorResponse.error.errors?.slug?.[0] === 'The slug has already been taken.'){
    //   errorMessage ="content_management.slug_availability_error";
    //   return throwError(errorMessage);
    // }
    const message = errorResponse.error.message;
    const standardErrorMessageTranslationKey = this.errorService.getCommonErrorMessageTranslationKey(message);
    if(standardErrorMessageTranslationKey){
      errorMessage = standardErrorMessageTranslationKey;
    // } else if(message.includes('Request latest data then try again.')){
    //   errorMessage = 'error.refresh';
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);
  }
}
