import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { AvailableLanguage } from './available-language.model';

import { Observable, BehaviorSubject, of, map } from "rxjs";
import { Language } from './language.model';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { MediaService } from '../content';
import { Tag } from '@frontend/core';
import { RangeInputStep } from '../forms';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languages : Language[];
  _availableLanguages : AvailableLanguage[];
  _characterBasedLanguages : string[];
  public activeLanguageObjectSubject: BehaviorSubject<AvailableLanguage>
  public activeLanguageObject: Observable<AvailableLanguage>
  private _languageSkillLevelRangeInputSteps : RangeInputStep[] = [];


  constructor(
    private translocoService: TranslocoService,
    private location: Location,
    private http : HttpClient,
    private mediaService : MediaService,
    // @Inject(DOCUMENT) private document: Document
    ) {

      this._characterBasedLanguages = ['ja','zh','ko',/*'th','vi','hi','bn','gu','kn','ml','mr','pa','ta','te'*/];
      this._availableLanguages = [
        {
          languageKey : 'en',
          name : 'language.en', // translation key in common translation file
          flag: {hash:'Vy9orn',emoji:'🇬🇧'},
          flag_path: this.mediaService.getFlagUrlFromHash('Vy9orn','c_scale,w_24/',true,'.png'), // 'https://cdn-icons-png.flaticon.com/512/197/197374.png',
          flag_class: 'flag-united-kingdom', // deprecated - this worked with MDB
          change_language: 'Change language'
        },
        {
          languageKey : 'fr',
          name : 'language.fr', // translation key in common translation file
          flag: {hash:'gy4FJr',emoji:'🇫🇷'},
          flag_path: this.mediaService.getFlagUrlFromHash('gy4FJr','c_scale,w_24/',true,'.png'), // 'https://cdn-icons-png.flaticon.com/512/197/197560.png',
          flag_class: 'flag-france', // deprecated - this worked with MDB
          change_language: 'Changez de langue'
        }
      ];
      const activeLanguage = this.getUserPreferredLanguage();
      this.activeLanguageObjectSubject = new BehaviorSubject<AvailableLanguage>(activeLanguage);
      this.activeLanguageObject = this.activeLanguageObjectSubject.asObservable();
      for (let i = 0; i <= 6; i++) {
        this._languageSkillLevelRangeInputSteps.push({
          value: i,
          labelTranslationKey: `language.level.${i}.name`,
          descriptionTranslationKey: `language.level.${i}.description`,
          descriptionShortTranslationKey: `language.level.${i}.description_short`,
        });
      }
  }
  get languageSkillLevelRangeInputSteps() : RangeInputStep[]{
    return this._languageSkillLevelRangeInputSteps;
  };

  get activeLanguageObjectSynchronously (){
    return this.activeLanguageObjectSubject.value;
  }
  get availableLanguages (){
    return this._availableLanguages;
  }
  get characterBasedLanguages (){
    return this._characterBasedLanguages;
  }
  getUserPreferredLanguage () : AvailableLanguage {
    /* 
    queryParamMap does not work before the Angular application boots, so we cannot use it here (this function is called in app.module in a APP_INITIALIZER)
    const queryParams = this.route.snapshot.queryParamMap;
    // const language = this.route.snapshot.queryParams['language'];
    const languageParam = queryParams.get('language');
    */

    const queryParams = this.location?.path(true).split('?')[1] ?? '';
    const parsedQueryParams = new URLSearchParams(queryParams);
    const languageParam = parsedQueryParams.get('language'); // 'fr' from ?language=fr in the URL

    const userBrowserPreferredLang = navigator.language ? navigator.language.substring(0, 2) : null;
    const preferredLanguageKey = localStorage.getItem('language') ? localStorage.getItem('language') : languageParam ? languageParam : userBrowserPreferredLang;
    return this.validatedLanguage(preferredLanguageKey);
  }
  setLanguage(languageKey : string){
    this.translocoService.setActiveLang(languageKey);
    this.activeLanguageObjectSubject.next(this.validatedLanguage(languageKey));
    localStorage.setItem('language',languageKey);
    this.getLanguages(true).subscribe();
  }

  validatedLanguage (languageKey : string) : AvailableLanguage{
    if (languageKey){
      let availableLanguage = this._availableLanguages.find(al=>al.languageKey===languageKey);
      if (availableLanguage){
        return availableLanguage;
      }
    }
    return this._availableLanguages.find(al=>al.languageKey===this.getDefaultLang());
  }

  getDefaultLang(){
    return this.translocoService.getDefaultLang()
  }
  getActiveLang(){
    return this.translocoService.getActiveLang()
  }

  getLanguages(freshFromServer:boolean = false): Observable<Language[]> {

    if (!freshFromServer && this.languages?.length){
      return of(this.languages);
    };
    return this.http.get< {data: Language[]}>('api/v1/languages')
        .pipe(
          map(response =>{
            if (response?.data?.length ){
              this.languages = response.data;
              return response.data;
            };
            return [];
          })
        );
  };
  convertLanguageToTag (language : Language, preserveId : boolean, cloudinary_base_url: string, flagTransformations: string){
    return new Tag(
      preserveId ? language.id : null,
      language.name,
      language.iso,
      'language',
      null,
      null,
      cloudinary_base_url+flagTransformations+'flags/round/'+language.flag?.hash+'.png'
    )
  }
  convertLanguagesToTags (languages : Language[], preserveId : boolean = false, cloudinary_base_url: string, flagTransformations = 'w_32,c_fill,ar_1:1,r_max,f_auto/'){
    if(!languages.length){return [];};
    return languages.map(l => this.convertLanguageToTag(l,preserveId,cloudinary_base_url,flagTransformations));
  }
}
