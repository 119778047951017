import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, computed, effect, input, output, signal } from '@angular/core';
import { Subscription, debounceTime } from 'rxjs';
import { Delivery, DeliverableService} from '..';
import { AvailableLanguage, FieldRequirements, Language, LanguageService, TranslatableFormControl, WordAndCharacterRequirements } from '../../language';
import { FormGroup } from '@angular/forms';
import { TranslationService } from '../../translation/translation.service';
import { DataProcessingService, FormDataStandard } from '../../utilities';
import { TranslocoService } from '@jsverse/transloco';
import { Vote } from '../../votes';
import { Cluster, Culture, CultureScore, CultureService, Difference, DifferenceService, MediaService } from '@frontend/common';
import { CultureClusterWrapper } from '../../clusters/culture-cluster-wrapper.model';
import { CultureScoreBenchmark } from '../../cultures/culture-score-benchmark.model';
import { ClusterService } from '../../clusters/cluster.service';
import { FormControlBooleanRequirements } from '../form-control-requirements.model';

interface GenericFormControl {
  key:string;
  defaultRequirements : any;
  deliverableAttribute:string;
  deliverableTitle:boolean;
}
@Component({
  selector: 'multisite-difference-vote-cco',
  templateUrl: './difference-vote-cco.component.html',
  styleUrls: ['./difference-vote-cco.component.scss']
})
export class DifferenceVoteCcoComponent implements OnInit, OnDestroy, OnChanges {

  // NECESSARY FOR ALL DELIVERABLE COMPONENTS

  delivery = input.required<Delivery>();
  deliveryLocked = input<boolean>(false); // for example, after submission
  formUpdateValueAndValidity = input<number>(0); // update the form value and validity when this changes
  formCachingServiceIdentifier = input.required<string | undefined>(); // required so we can immediately get cached data on instantiation
  disabled = input<boolean>(false);
  parentLoadingObject = input<{[key:string]:boolean}>({});
  loadingObject = signal <{[key:string]:boolean}>({});
  combinedLoadingObject = computed(() => ({...this.parentLoadingObject(),...this.loadingObject()}));
  saveDeliverable = output<{[key:string]:any}>();
  submitDeliverable = output<{formData:{[key:string]:any},allTargetLanguageFormControlsAreNull:boolean}>();
  deliverableForm : FormGroup;
  deliverable = input<Vote | undefined>(undefined); // Should this be required?
  difference = computed<Difference>(() => this.delivery().differences[0]);
  
  // OPTIONAL FOR DELIVERABLE COMPONENTS (NEEDED BY SOME)
  
  truncatedTitle = input<string | undefined>(); // We will re-use this if the user saves the deliverable with an empty title

  // SPECIFIC TO THIS DELIVERABLE COMPONENT

  allCultures = input.required<Culture[]>();
  culture = input.required<Culture>();

  defaultValueRequirements = {
    required: true,
  };
  defaultPublicCommentRequirements = {
      words: {min: 1, max: 30},
      characters: {min: 2, max: 150},
      required: false,
  };
  defaultPrivateNoteRequirements = {
    words: {min: 5, max: 50},
    characters: {min: 15, max: 120},
    required: false,
  }
  allTranslatableFormControls = signal<TranslatableFormControl[]>([
    {key:'public_comment_translation_source', type:'source', pairKey:'public_comment', defaultRequirements : this.defaultPublicCommentRequirements, deliverableAttribute:'public_comment', deliverableTitle:false},
    {key:'public_comment', type:'target', pairKey:'public_comment', defaultRequirements : this.defaultPublicCommentRequirements, deliverableAttribute:'public_comment', deliverableTitle:false},
  ]);

  subscriptions : Subscription[] = [];

  benchmarkClusters = signal<CultureClusterWrapper[]>([]);
  relatedBenchmarkClusters = computed<CultureClusterWrapper[] | undefined> (() => {
    return this.benchmarkClusters().filter(cluster => cluster.cultures.map(c=>c.slug).includes(this.culture().slug) || cluster.slug === 'benchmark-cultures-global');
  });
  relatedBenchmarkClustersWithoutWrappers = computed<Cluster[] | undefined> (() => {
    return this.relatedBenchmarkClusters() ? this.relatedBenchmarkClusters().map(clusterWrapper => clusterWrapper.cluster) : undefined;
  });
  uniqueCulturesInAllRelatedBenchmarkClusters = computed<Culture[] | undefined>(() => {
    return this.relatedBenchmarkClusters() ? this.clusterService.getUniqueCulturesInCultureClusters(this.relatedBenchmarkClusters()).filter(c => this.culture() ? c.slug != this.culture().slug : true) : undefined;
  });
  uniqueCcoCulturesInAllRelatedBenchmarkClusters = computed<Culture[] | undefined>(() => {
    return this.uniqueCulturesInAllRelatedBenchmarkClusters() ? this.uniqueCulturesInAllRelatedBenchmarkClusters().filter(c => c.cco) : undefined;
  });
  uniqueCulturesInSelectedRelatedBenchmarkClusters = computed<Culture[] | undefined>(() => {
    if (this.selectedBenchmarkClusters()){
      const uniqueCultures = this.selectedBenchmarkClusters().map(cluster => cluster.cultures).reduce((acc, val) => acc.concat(val), []).filter((culture, index, self) => self.findIndex(c => c.slug === culture.slug) === index);
      return uniqueCultures;
    }
  });
  ccoBenchmarkVotes = signal<Vote[] | undefined>(undefined);

  userSelectedBenchmarkClusters = signal<CultureClusterWrapper[] | undefined>(undefined);
  defaultSelectedBenchmarkClusters = computed<CultureClusterWrapper[] | undefined>(() => {
    const clustersIncludingTheSelectedCulture = this.relatedBenchmarkClusters().filter(c=>c.slug !== 'benchmark-cultures-global');
    return this.benchmarkClusters().filter(cluster => cluster.slug === 'benchmark-cultures-global').concat(clustersIncludingTheSelectedCulture.length > 2 ? [] : this.relatedBenchmarkClusters().filter(c=>c.slug !== 'benchmark-cultures-global'));
  });
  selectedBenchmarkClusters = computed<CultureClusterWrapper[] | undefined>(() => {
    return this.userSelectedBenchmarkClusters() ? this.userSelectedBenchmarkClusters() : this.defaultSelectedBenchmarkClusters();
  });
  selectedBenchmarkClusterIds = computed<number[] | undefined>(() => {
    return this.selectedBenchmarkClusters() ? this.selectedBenchmarkClusters().map(b=>b.id) : [];
  });
  culturesGroupedByVoteValue = computed<CultureScoreBenchmark[]>(() => {
    if(this.uniqueCulturesInSelectedRelatedBenchmarkClusters() && this.ccoBenchmarkVotes()){
      const cultureScores : CultureScore[] = this.cultureService.generateCultureVoteScoreObjects(this.uniqueCulturesInSelectedRelatedBenchmarkClusters(), this.ccoBenchmarkVotes());
      return this.dataProcessingService.groupObjectsByProperty(cultureScores, 'score',[1,2,3,4,5,6,7]);
    } else {
      return [];
    }
  });
  collapseObject = signal<{[key:string]:boolean}>({});
  ccoDifferenceAnchorSet = computed<string[]>(() => {
    if(this.difference()){
      return this.differenceService.generateAnchorSetFromDifference(this.difference(),7);
    }
    return [];
  });
  toggleCollapse(key: string){
    this.collapseObject()[key] = !this.collapseObject()[key];
  }
  setCollapse(key: string, value: boolean){
    this.collapseObject()[key] = value;
  }
  toggleSelectBenchmarkCluster(cluster:Cluster){
    const selectedCultureClusterWrapper = this.benchmarkClusters().find(c=>c.slug === cluster.slug);
    if(this.userSelectedBenchmarkClusters()){
      if(this.userSelectedBenchmarkClusters().map(c=>c.slug).includes(cluster.slug)){
        this.userSelectedBenchmarkClusters.set(this.userSelectedBenchmarkClusters().filter(c=>c.slug !== cluster.slug));
      } else {
        this.userSelectedBenchmarkClusters.set(this.userSelectedBenchmarkClusters().concat(selectedCultureClusterWrapper));
      }
    } else {
      if(this.defaultSelectedBenchmarkClusters().map(c=>c.slug).includes(cluster.slug)){
        this.userSelectedBenchmarkClusters.set(this.defaultSelectedBenchmarkClusters().filter(c=>c.slug !== cluster.slug));
      } else {

        this.userSelectedBenchmarkClusters.set([...(this.defaultSelectedBenchmarkClusters() as CultureClusterWrapper[]), selectedCultureClusterWrapper]);
      };
    }
  }

  // END OF SPECIFIC TO THIS DELIVERABLE COMPONENT

  // MORE OPTIONAL FOR DELIVERABLE COMPONENTS (NEEDED BY SOME)

  // -- For components with text inputs: --

  deliverable_translations = input<{[key:string]:string} | undefined>(undefined);

  allNonTranslatableFormControls = signal<GenericFormControl[]>([
    {key:'value', defaultRequirements : this.defaultValueRequirements, deliverableAttribute:'value', deliverableTitle:false},
    {key:'private_note', defaultRequirements : this.defaultPrivateNoteRequirements, deliverableAttribute:'private_note', deliverableTitle:false},
  ]);

  allFormControls = computed(() => (this.allNonTranslatableFormControls() as (GenericFormControl | TranslatableFormControl)[]).concat(this.allTranslatableFormControls() as (GenericFormControl | TranslatableFormControl)[]));

  targetControls = computed(() => this.allTranslatableFormControls().filter(control => control.type === 'target'));
  sourceControls = computed(() => this.allTranslatableFormControls().filter(control => control.type === 'source'));

  sourceLanguage = input<Language | undefined>(undefined);
  targetLanguage = input<Language | undefined>(undefined);

  // These signal names are composed in the template by getSignalValue(). You will not find them by string-searches.
  sourceLanguageIsCharacterBased = computed(() => this.languageService.characterBasedLanguages?.includes(this.sourceLanguage()?.iso));
  targetLanguageIsCharacterBased = computed(() => this.languageService.characterBasedLanguages?.includes(this.targetLanguage()?.iso));

  wordAndCharacterRequirements = signal<{words?:WordAndCharacterRequirements[],characters?:WordAndCharacterRequirements[]}>({});
  taskBasedFormControlRequirements = signal<{required?:FormControlBooleanRequirements}>({});
  translatableFieldRequirements = computed(() => {
    const requirementsObject = {};
    this.allTranslatableFormControls().forEach(control => {
      requirementsObject[control.key] = this.getFieldRequirements(control.type === 'target' ? this.targetLanguage()?.iso : this.sourceLanguage()?.iso, control.pairKey, true);
    });
    return requirementsObject;
  });

  editorWords : {[key:string]:number} = {};
  editorCharacters : {[key:string]:number} = {};

  // -- For components which can have local errors: --

  error = signal<string|{message:string,meta:any}|undefined>(undefined);
  errorString = computed(() => {
    if(this.error()){
      return typeof this.error() === 'string' ? this.error() : (this.error() as {message:string,meta:any}).message;
    }
  });
  errorObject = computed(() => {
    if(this.error()){
      return typeof this.error() === 'object' ? this.error() : null;
    }
  });

  // END OF OPTIONAL FOR DELIVERABLE COMPONENTS


  // NOT NECESSARY FOR THIS COMPONENT:

  activeLanguageObject = signal<AvailableLanguage | undefined>(undefined);

  // END OF NOT NECESSARY FOR THIS COMPONENT

  constructor(
    private languageService : LanguageService,
    private translationService : TranslationService,
    private translocoService : TranslocoService,
    private cultureService : CultureService,
    private dataProcessingService : DataProcessingService,
    private clusterService : ClusterService,
    private differenceService : DifferenceService,
    private mediaService : MediaService,
    private deliverableService : DeliverableService,
  ) {

    // SPECIFIC TO THIS DELIVERABLE COMPONENT
    effect(() => {
      if (
        this.difference() &&
        this.uniqueCcoCulturesInAllRelatedBenchmarkClusters() &&
        this.uniqueCcoCulturesInAllRelatedBenchmarkClusters().length > 0) {
        const cultureIds = this.uniqueCcoCulturesInAllRelatedBenchmarkClusters().map(culture => culture.id);
        this.differenceService.getDifferenceVotes(this.difference(),cultureIds,'benchmark','cco_import')
          .subscribe(data => {
            this.ccoBenchmarkVotes.set(data);
          });
      }
    },{
      allowSignalWrites: true,
    });
    // END OF SPECIFIC TO THIS DELIVERABLE COMPONENT
  }

  isAnythingLoading(){
    for (let something in this.combinedLoadingObject()){
      if (this.combinedLoadingObject()[something]){
        return true;
      }
    }
    return false;
  }
  setLoadingStatus(key: string, value: boolean){
    // So we can detect deep changes in the object
    this.loadingObject.set({...this.loadingObject(),[key]:value});
  }

  getTranslatableFormFieldControls(pairKey : string){
    return this.allTranslatableFormControls() ? this.allTranslatableFormControls().filter(control => control.pairKey === pairKey) : [];
  };
  getFormFieldControls(key : string){
    return this.allNonTranslatableFormControls() ? this.allNonTranslatableFormControls().filter(control => control.key === key) : [];
  };

  getFieldRequirements(language_code : string, field_key : string, translatable : boolean) : FieldRequirements{

    return this.deliverableService.getFieldRequirements(
      this.allTranslatableFormControls(),
      this.allNonTranslatableFormControls(),
      this.wordAndCharacterRequirements(),
      this.taskBasedFormControlRequirements(),
      language_code,
      field_key,
      translatable
    );
  };
  
  getSignalValue(signalName: string) {
    return this[signalName]();
  }
  getMax100 (progress : number){
    return Math.min(progress,100);
  }
  formControlCharacterCount(form : FormGroup, controlName: string): number {
    const control = form.get(controlName);
    return control?.value ? control.value.length : 0;
  }

    // FOR ANY COMPONENT WITH TRANSLATABLE TEXT INPUTS

    sendForTranslation(){
      this.error.set(undefined);
      if(!this.sourceLanguage()){
        return;
      }
      const textsAsObject = this.deliverableService.getFormSourceLanguageControlsAsTextObjectForTranslation(this.sourceControls(),this.deliverableForm);
      const defaultLocale : string = this.translationService.getDefaultLocaleFromGenericLanguage(this.targetLanguage().iso);
      if(this.allTargetLanguageFormControlsAreNull()){
        this.getTranslation(textsAsObject,defaultLocale,this.sourceLanguage().iso);
      } else {
        const translation = this.translocoService.translate('contributions.confirm.translation_overwrite');
        if (confirm(translation)) {
          this.getTranslation(textsAsObject,defaultLocale,this.sourceLanguage().iso);
        }
      }
    }
    getTranslation(textsAsObject:{[key:string]:string},target_lang : string, source_lang : string) {
      const keys = Object.keys(textsAsObject);
      const values = Object.values(textsAsObject);
      this.error.set(undefined);
      this.setLoadingStatus('translate',true);
      const translationSubscription = this.translationService.getTranslation(textsAsObject,target_lang,source_lang).subscribe((translations : {[key:string]:string}) => {
        this.setLoadingStatus('translate',false);
        this.deliverableService.handleFormTranslations(translations,this.deliverableForm, this.allTranslatableFormControls(), this.deliveryLocked());
      }, error => {
        this.setLoadingStatus('translate',false);
        this.error.set(error);
      });
      this.subscriptions.push(translationSubscription);
    }

    // -- For components with non-required translatable text fields

    optionalTranslatableControlHasMissingTranslation(controlDefinition: TranslatableFormControl): boolean | null { // This function is only needed for components with a translatable field which is also not a required field
      // Let's warn the user when a non-required field is written but the translation is missing
      // The source language is in the form but the target language is empty
      // If the delivery expects a target language, we will not save the untranslated source (target language is empty) when the delivery is submitted.
      // If the delivery does not expect a specify a language, we will save the untranslated source (target language is empty) when the delivery is submitted.
      return this.deliverableService.optionalTranslatableControlHasMissingTranslation(
        this.deliverableForm,
        this.allTranslatableFormControls(),
        controlDefinition
      );
    }  


  // END OF FOR ANY COMPONENT WITH TRANSLATABLE TEXT INPUTS


  getSavedAndCachedFormData(): FormDataStandard {
    return this.deliverableService.getSavedAndCachedFormData(
      this.formCachingServiceIdentifier(),
      this.allNonTranslatableFormControls(),
      this.allTranslatableFormControls(),
      this.deliverable(),
      this.sourceLanguage(),
      this.deliverable_translations()
    );
  }

  cacheFormData(): void {
    this.deliverableService.cacheFormData(
      this.formCachingServiceIdentifier(),
      this.deliverableForm,
      this.allNonTranslatableFormControls(),
      this.allTranslatableFormControls(),
      this.sourceLanguage()
    );
  }
  
  checkAllCachedTargetLanguageFieldsAreNull(cachedFormData : FormDataStandard) : boolean {
    return this.targetControls().every(control => !cachedFormData?.[control.key]);
  };
  allTargetLanguageFormControlsAreNull() {
    if(!this.deliverableForm){return true;} 
    return this.targetControls().every(control => !this.deliverableForm.get(control.key)?.value);
  };

  // deliverableTargetLanguageTitleFormControlsIsNull is for debugging; you can comment it out

  // deliverableTargetLanguageTitleFormControlsIsNull() : boolean {
  //   if(!this.deliverableForm){return true;}
  //   const control = this.targetControls().find(control => control.deliverableTitle);
  //   let value = control ? this.deliverableForm.get(control.key)?.value : null;
  // value = typeof value === 'string' ? value.trim() : value;
  //   return !(value ?? false);
  // };

  // end of debugging

  noSourceLanguageFormControlsAreNull() : boolean {
    return this.deliverableService.noFormControlsAreNull(this.deliverableForm,this.sourceControls());
  };
  disableAllTargetLanguageTranslatableFields(form : FormGroup){
    this.deliverableService.disableFormControls(this.deliverableForm,this.targetControls());
  };
  addOrRemoveEditingLanguage(language: Language): void {
    this.deliverableService.addOrRemoveEditingLanguage(
      this.deliverableForm,
      this.formCachingServiceIdentifier(),
      this.allTranslatableFormControls(),
      this.allNonTranslatableFormControls(),
      this.targetControls(),
      language,
      this.deliverable(),
      this.deliverable_translations(),
      this.wordAndCharacterRequirements(),
      this.taskBasedFormControlRequirements(),
      this.deliveryLocked(),
    );
  }

  disableTargetLanguageTranslatableFieldsIfNecessary(sourceLanguage : Language){ // Applies the rule that the source language should be done first // TODO - move this function from all components to the DeliverableService (it's mostly duplicated there)
    const cachedFormData = this.getSavedAndCachedFormData();
    if(sourceLanguage && this.checkAllCachedTargetLanguageFieldsAreNull(cachedFormData)){
      this.disableAllTargetLanguageTranslatableFields(this.deliverableForm);
    }
  };
  showCharacters(characterCount : number, editorKey : string){
    this.editorCharacters[editorKey] = characterCount;
  }
  showWords(wordCount : number, editorKey : string){
    this.editorWords[editorKey] = wordCount;
  }

  save(action : 'update' | 'submit'){
    const formData = this.deliverableService.prepareFormDataForSaving(
      this.deliverableForm,
      this.allTranslatableFormControls(),
      this.allNonTranslatableFormControls(),
      this.sourceLanguage(),
      this.truncatedTitle(),
      action,
      this.delivery().target_lang
        );
    if(action === 'update'){
      this.saveDeliverable.emit(formData);
    } else if (action === 'submit'){
      this.submitDeliverable.emit({formData:formData,allTargetLanguageFormControlsAreNull:this.allTargetLanguageFormControlsAreNull()});
    }
  }

  initialiseDeliverableForm(){

    const cachedFormData : FormDataStandard = this.getSavedAndCachedFormData();
    const translationTargetFormControls = this.allTranslatableFormControls().filter(control => control.type === 'target');

    const formGroupObject = this.deliverableService.generateInitialFormGroupObject(
      cachedFormData,
      this.allNonTranslatableFormControls(),
      translationTargetFormControls,
      this.targetLanguage(),
      this.wordAndCharacterRequirements(),
      this.taskBasedFormControlRequirements(),
    );
    this.deliverableForm = new FormGroup(formGroupObject);
    const formSubscription = this.deliverableForm.valueChanges.pipe(
      debounceTime(2000)
    ).subscribe(value => {
      this.cacheFormData();
    });
    for (const control of translationTargetFormControls) {
      if(cachedFormData?.[control.key]){
        this.deliverableForm.get(control.key).markAsDirty();
      }
    }
    if(this.deliveryLocked()){
      this.deliverableForm.disable();
    }
    this.subscriptions.push(formSubscription);
  }


  // SPECIFIC TO THIS DELIVERABLE COMPONENT

  getFlagUrlFromHash(hash:string,transformations : string = ''){
    return this.mediaService.getFlagUrlFromHash(hash,transformations,true,'.png');
  }
  formIsValid(){
    // The form is valid when this.deliverableForm.get('value') is null, so we need to check that it is not null. This is because range inputs cannot be null.
    return !!(this.deliverableForm && this.deliverableForm.valid && this.deliverableForm.get('value')?.value); // 
  };

  getCultureClustersData (culture_category: string, culture_type: string, cluster_category: string, cluster_type:string, freshFromServer:boolean){
    this.cultureService.getCultureClustersData(culture_category,culture_type,cluster_category,cluster_type,freshFromServer).subscribe(([cultures, clusters, cultureClusters]) => {
      let results = [cultures, clusters, cultureClusters];
      if(this.allCultures().length > 0){
        this.benchmarkClusters.set(this.cultureService.prepareCultureClusters(cultures,clusters,cultureClusters,'benchmark_cultures','frequent'));
      }
    }, error => {
      // TODO Handle errors
    });
  }

  // END OF SPECIFIC TO THIS DELIVERABLE COMPONENT


  initialise(freshFromServer : boolean = false){

    // -- For components with translatable text inputs --

    this.wordAndCharacterRequirements.set(this.delivery().task?.meta);
    this.taskBasedFormControlRequirements.set(this.delivery().task?.meta);

    // -- For all deliverable components --

    this.initialiseDeliverableForm();

    // -- For components with translatable text inputs --

    this.addOrRemoveEditingLanguage(this.sourceLanguage());

    // -- For this specific deliverable component --

    this.getCultureClustersData('geographic', 'national', 'benchmark_cultures', 'frequent', freshFromServer);

    if(this.deliverableForm?.get('public_comment')?.value){
      this.setCollapse('public_comment',false);
    } else {
      this.setCollapse('public_comment',true);
    }
    if(this.deliverableForm?.get('private_note')?.value){
      this.setCollapse('private_note',false);
    } else {
      this.setCollapse('private_note',true);
    }


  }
  ngOnInit(): void {

    // SPECIFIC TO THIS DELIVERABLE COMPONENT

    this.activeLanguageObject.set(this.languageService.activeLanguageObjectSynchronously);
    const activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
        // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
        if (newActiveLanguage?.languageKey !== this.activeLanguageObject().languageKey){
          this.activeLanguageObject.set(newActiveLanguage);
          this.initialise(true);  // We need to get new CultureClusters data
        }
    });
    this.subscriptions.push(activeLanguageSubscription);

    this.collapseObject().benchmarks = true;

    // END OF SPECIFIC TO THIS DELIVERABLE COMPONENT

    this.initialise(false);

  }
  ngOnDestroy () : void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnChanges (changes: SimpleChanges) {

    if(changes.formUpdateValueAndValidity && this.deliverableForm){
      this.deliverableForm.updateValueAndValidity();
    }
    if(changes.disabled && this.deliverableForm){
      if(changes.disabled.currentValue){
        this.deliverableForm.disable();
      } else {
        this.deliverableForm.enable();
        if(this.sourceLanguage()){
          this.disableTargetLanguageTranslatableFieldsIfNecessary(this.sourceLanguage());
        }
      }
    }
    if(changes.sourceLanguage && this.deliverableForm){
      if(changes.sourceLanguage){
        this.addOrRemoveEditingLanguage(changes.sourceLanguage.currentValue);
      }
    }
    
  }

}
