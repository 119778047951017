import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  computed,
  effect,
  input,
  output,
  signal,
} from '@angular/core';
import { MediaService } from '../../content';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RangeInputStep } from '../../forms';
import { FlagExtraLite } from '../../flags';
import {
  AvailableLanguage,
  Language,
  LanguageService,
  TranslatableFormControl,
} from '../../language';
import { ProportionInputItem } from '../../surveys/proportion-input-item.model';
import { TranslationService } from '../../translation/translation.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { SaveService } from '../../utilities/save.service';
import { FormCachingService, FormDataStandard } from '../../utilities';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'multisite-lickert-proportion-edit-card',
  templateUrl: './lickert-proportion-edit-card.component.html',
  styleUrl: './lickert-proportion-edit-card.component.scss',
})
export class LickertProportionEditCardComponent
  implements OnInit, OnChanges, OnDestroy
{
  // A card to display and edit things which have a numeric value and optional description

  error = input<string | { message: string; meta: any } | undefined>(undefined);
  loading = input<boolean>();
  item = input.required<ProportionInputItem>();
  numberValueLabelText = input<string | undefined>(undefined);
  numberValueLabelTranslationKey = input<string | undefined>(undefined);
  numberValueLabelTranslationScope = input<string | undefined>(undefined);
  textInputLabelText = input<string | undefined>(undefined);
  textInputLabelTranslationKey = input<string | undefined>(undefined);
  textInputLabelTranslationScope = input<string | undefined>(undefined);
  textInputHelpText = input<string | undefined>(undefined);
  textInputHelpTranslationKey = input<string | undefined>(undefined);
  textInputHelpTranslationScope = input<string | undefined>(undefined);
  textInputMaxLength = input<number>(200);
  nameText = input<string | undefined>(undefined);
  nameTranslationKey = input<string | undefined>(undefined);
  text = input<string | undefined>(undefined); // for example, a description field
  numberValue = input<number | undefined>(undefined);
  flag = input<FlagExtraLite | undefined>(undefined);
  showDetailsButton = input<boolean>(true);
  showEditButton = input<boolean>(true);
  showDeleteButton = input<boolean>(true);
  allowTranslations = input<boolean>(true);
  translationSourceLanguages = input<Language[] | undefined>(undefined);
  editMode = signal<boolean>(false);
  editModeTrueByDefault = input<boolean>(false);
  detailsMode = signal<boolean>(false);
  translationSourceLanguage = signal<Language | undefined>(undefined);
  // translationTargetLanguage = signal<Language | undefined>(undefined);
  activeLanguageObject = signal<AvailableLanguage | undefined>(undefined);
  subscriptions = signal<Subscription[]>([]);
  languagePickerCollapsed = signal<boolean>(true);
  unsaved = input<boolean>(false);
  cardClasses = input<string>(
    'list-group-item card-gray-100 pt-3 overflow-hidden rounded mb-3 w-100'
  );
  textControls = signal<TranslatableFormControl[]>([
    { key: 'text_translation_source', type: 'source', pairKey:'text' },
    { key: 'text', type: 'target', pairKey:'text' },
  ]);

  rangeInputSteps = input<RangeInputStep[] | undefined>(undefined);
  savedRangeInputStep = computed(() => {
    return this.rangeInputSteps()?.find(
      (step) =>
        step.value === this.numberValue() ||
        parseInt(step.value) === this.numberValue()
    );
  });
  activeRangeInputStep = signal<RangeInputStep | undefined>(undefined);
  progress = computed(() => {
    if (
      this.numberValue() === undefined ||
      this.rangeInputSteps()?.length === 0
    ) {
      return 0;
    }
    const sortedSteps = this.rangeInputSteps()
      ?.slice()
      .sort((a, b) => a.value - b.value);
    const activeStep = sortedSteps
      ?.reverse()
      .find((step) => step.value <= this.numberValue());
    if (activeStep) {
      return (activeStep.value / sortedSteps?.[0].value) * 100;
    }
    return 0;
  });
  save = output<ProportionInputItem>();
  delete = output<ProportionInputItem>();
  editForm: FormGroup;

  constructor(
    private mediaService : MediaService,
    private translationService : TranslationService,
    private languageService : LanguageService,
    private saveService : SaveService,
    private formCachingService : FormCachingService,
  ) {
    effect(
      () => {
        if (
          this.translationSourceLanguages() &&
          this.translationSourceLanguages().length > 0
        ) {
          let language;
          if (this.translationService.getPreferredTranslationSourceLanguage()) {
            language = this.translationSourceLanguages().find(
              (language) =>
                language.iso ===
                this.translationService.getPreferredTranslationSourceLanguage()
            );
          } else if (this.activeLanguageObject) {
            language = this.translationSourceLanguages().find(
              (language) =>
                language.iso === this.activeLanguageObject().languageKey
            );
          }
          if (language) {
            // this.translationSourceLanguage.set(language);
            this.setEditingLanguage(language);
          }
        }
      },
      {
        allowSignalWrites: true,
      }
    );
  }

  getFlagUrlFromHash(hash: string, transformations: string = '') {
    return this.mediaService.getFlagUrlFromHash(
      hash,
      transformations,
      true,
      '.png'
    );
  }

  setEditingLanguage(language: Language) {
    this.translationSourceLanguage.set(language);
    this.translationService.setPreferredTranslationSourceLanguage(language.iso);
    if(this.activeLanguageObject().languageKey !== language.iso){
      if(this.editForm && !this.editForm.get('text_translation_source')){
        const cachedFormData = this.getSavedAndCachedFormData();
        this.editForm.addControl('text_translation_source', new FormControl(cachedFormData.translations?.text,[Validators.minLength(2), Validators.maxLength(this.textInputMaxLength())]));
        this.editForm.updateValueAndValidity();
      }
    } else {
      if (this.editForm && this.editForm.get('text_translation_source')) {
        this.editForm.removeControl('text_translation_source');
        this.editForm.updateValueAndValidity();
      }
    }
  }

  toggleEditMode(event: Event | null) {
    if (event) {
      event.stopPropagation();
    }
    if (this.loading()) {
      return;
    }
    this.editMode.set(!this.editMode());
    if (this.editMode()) {
      this.detailsMode.set(false);
      // this.editForm.enable();
      // this.editForm.patchValue({
      //   numberValue: language.skill?.numberValue,
      //   text: language.skill?.description
      // });
    }
  }
  toggleDetailsMode(event: Event | null) {
    if (event) {
      event.stopPropagation();
    }
    if (this.loading()) {
      return;
    }
    this.detailsMode.set(!this.detailsMode());
  }
  toggleCard(event: Event) {
    // event.preventDefault();
    if (this.editMode()) {
      this.toggleEditMode(null);
    } else {
      this.toggleDetailsMode(null);
    }
  }
  toggleLanguagePickerCollapsed() {
    this.languagePickerCollapsed.set(!this.languagePickerCollapsed());
  }

  onSubmit() {
    if (this.editForm.invalid) {
      return;
    }

    const numberValue = +this.editForm.value.numberValue;
    const text = this.editForm.value.text;
    const updatedItem: ProportionInputItem = {
      id: this.item().id,
      relatedObject: this.item().relatedObject,
      numberValue: numberValue,
      textValue: text,
    };
    this.save.emit(updatedItem);
  }

  doDelete(event) {
    if (event) {
      event.stopPropagation();
    }
    this.delete.emit(this.item());
  }
  cancelChanges() {
    if (this.unsaved()) {
      this.doDelete(null);
      return;
    }
    this.editForm.reset();
    this.editForm.patchValue({
      numberValue: this.numberValue(),
      text: this.text(),
    });
    this.editMode.set(false);
  }

  getFormCachingIdentifier() : string {
    const route = window.location.pathname;
    return route+this.item().id;
  }

  cacheFormData(){
    const formCachingIdentifier = this.getFormCachingIdentifier();
    let cachedTranslations = this.formCachingService.getDataItem(formCachingIdentifier)?.translations ?? {};
    const dataToCache : FormDataStandard = {
      numberValue: this.editForm.get('numberValue').value,
      text: this.editForm.get('text').value,
    };
    if(this.editForm.get('text_translation_source')){
      cachedTranslations = {
        ...cachedTranslations,
        [this.translationSourceLanguage().iso]: {
          text: this.editForm.get('text_translation_source').value,
        }
      };
    }
    dataToCache.translations = cachedTranslations;
    this.formCachingService.cacheFormData(dataToCache,formCachingIdentifier);
  }

  getSavedAndCachedFormData() : FormDataStandard {
    // We are giving priority to unsaved text-field changes (TODO: review is the cached data more recent than or as fresh as the saved data?)
    const cachedFormData = this.formCachingService.getDataItem(this.getFormCachingIdentifier());
    const numberValue = this.numberValue() !== undefined ? this.numberValue() : cachedFormData?.numberValue;
    // const text = this.text() !== undefined ? this.text() : cachedFormData?.text; // Prioritising saved data over cached data
    const text = cachedFormData?.text ?? this.text(); // Prioritising saved data over cached data
    const translations = this.translationSourceLanguage() ? cachedFormData?.translations?.[this.translationSourceLanguage().iso] : null;
    return {
      numberValue,
      text,
      translations,
    };
  }


  initialiseForm(){
    
    const cachedFormData = this.getSavedAndCachedFormData();

    this.editForm = new FormGroup({
      numberValue: new FormControl(cachedFormData.numberValue, Validators.required),
      text: new FormControl(cachedFormData.text, [Validators.minLength(2), Validators.maxLength(this.textInputMaxLength())]),
    });
    if(this.allowTranslations() && this.translationSourceLanguage()){
      this.editForm.addControl('text_translation_source', new FormControl(cachedFormData.translations?.text,[Validators.minLength(2), Validators.maxLength(this.textInputMaxLength())]));
      this.editForm.updateValueAndValidity();
    }
    const formSubscription = this.editForm.valueChanges.pipe(
      debounceTime(3000)
    ).subscribe(value => {
      this.cacheFormData();
    });
    this.subscriptions().push(formSubscription);
    // if (this.numberValue() !== undefined || (this.text() !== undefined)) {
    //   this.editForm.patchValue({
    //     numberValue: this.numberValue() !== undefined ? this.numberValue() : ,
    //     text: this.text()
    // }
    // )};
    this.editForm.get('numberValue')?.valueChanges.subscribe(numberValue => { // TODO, why does this exist, numberValue is not used anywhere
      if (isNaN(numberValue)) {
        return;
      }
      if (
        this.rangeInputSteps()?.[0] &&
        typeof this.rangeInputSteps()?.[0].value === 'number' &&
        typeof numberValue !== 'number'
      ) {
        numberValue = parseInt(numberValue, 10);
      }
      const activeRangeInputStep = this.rangeInputSteps()?.find(
        (step) =>
          step.value === numberValue || parseInt(step.value) === numberValue
      );
      if (activeRangeInputStep) {
        this.activeRangeInputStep.set(activeRangeInputStep);
      }
    });
  }

  ngOnInit() {
    this.activeLanguageObject.set(
      this.languageService.activeLanguageObjectSynchronously
    );
    const activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(
        (newActiveLanguage) => {
          // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
          if (
            newActiveLanguage?.languageKey !==
            this.activeLanguageObject().languageKey
          ) {
            this.activeLanguageObject.set(newActiveLanguage);
            debugger; // do something here?
            // this.getSurveys('cultures','knowledge',true);
          }
        }
      );
    this.subscriptions().push(activeLanguageSubscription);
    const saveSubscription = this.saveService.saveSuccess$.subscribe(() => {
      this.editMode.set(false);
    });
    this.subscriptions().push(saveSubscription);
    this.initialiseForm();
    if (this.editModeTrueByDefault()) {
      this.editMode.set(true);
    }
  }
  ngOnChanges(changesObject: SimpleChanges) {
    if (changesObject.loading && this.editForm) {
      if (changesObject.loading.currentValue) {
        this.editForm.get('numberValue')?.disable();
        this.editForm.get('text')?.disable();
      } else {
        this.editForm.get('numberValue')?.enable();
        this.editForm.get('text')?.enable();
      }
    }
    if (changesObject.text && this.editForm) {
      if (
        changesObject.text.currentValue !== undefined &&
        this.editForm.get('text')?.value !== changesObject.text.currentValue
      ) {
        this.editForm.patchValue({
          text: changesObject.text.currentValue,
        });
      }
    }
    if (changesObject.numberValue && this.editForm) {
      if (
        changesObject.numberValue.currentValue !== undefined &&
        this.editForm.get('numberValue')?.value !==
          changesObject.numberValue.currentValue
      ) {
        this.editForm.patchValue({
          numberValue: changesObject.numberValue.currentValue,
        });
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions().forEach((subscription) => subscription.unsubscribe());
  }
}
