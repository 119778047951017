<div
  class="card text-center"
  [style.width]="cardWidth()"
>
  @if(loading() && !randomImageUrl()){
  <multisite-loader-globe-animation-detail
    [width]="100"
    [height]="100"
  >
  </multisite-loader-globe-animation-detail>
  } @else if(randomImageUrl()) {
  <img
    [src]="randomImageUrl()"
    class="card-img-top"
    alt="interculturalist at work"
  >
  }
  <div class="card-body">
    <p class="card-text"><br> <small class="text-muted"></small></p>
    @if(titleText() || titleTranslationKey()){
    <p [ngClass]="titleClasses()">
      {{
      titleText() ??
      (titleTranslationKeyRandomised() | transloco: { scope: titleTranslationScope() })
      }}
    </p>
    }
    @if(descriptionText() || descriptionTranslationKey()){
    <p [ngClass]="descriptionClasses()">
      {{
      descriptionText() ??
      (descriptionTranslationKeyRandomised() | transloco: { scope: descriptionTranslationScope() })
      }}
    </p>
    }
    @if(captionText() || captionTranslationKey()){
    <p [ngClass]="captionClasses()">
      {{
      captionText() ??
      (captionTranslationKeyRandomised() | transloco: { scope: captionTranslationScope() })
      }}
    </p>
    }
    @if(buttonLabelText() || buttonLabelTranslationKey()){
    <button
      class="btn btn-primary my-3"
      (click)="onButtonClicked()"
    >
      {{
      buttonLabelText() ??
      (buttonLabelTranslationKey() | transloco: { scope: buttonLabelTranslationScope() })
      }}
    </button>

    }
  </div>
</div>