import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NationalCultureVisualisationParentComponent } from './parent/national-culture-visualisation-parent.component';
import { NationalCultureVisualisationComponent } from './culture/national-culture-visualisation.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToggleSwitchComponent } from '@frontend/shared';



@NgModule({
  declarations: [
    NationalCultureVisualisationParentComponent,
    NationalCultureVisualisationComponent,
  ],
  exports: [
    NationalCultureVisualisationParentComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CollapseModule,
    TranslocoModule,
    TooltipModule,
    ToggleSwitchComponent,
  ]
})
export class NationalCultureVisualisationModule { }
