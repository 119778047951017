import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileUserRoutingModule } from './profile-user-routing.module';
import { VerticalMenuTypeOneModule } from '../navigation';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { LoadersModule } from '../loaders';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileUserHomeComponent } from './profile-user-home/profile-user-home.component';
import { ProfilePictureSettingsComponent } from './profile-picture-settings/profile-picture-settings.component';
import { ProfileUserLanguagesComponent } from './languages/profile-user-languages.component';
import { ComingSoonModule, PillsModule } from '@frontend/shared';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ProfileUserNameComponent } from './profile-user-name/profile-user-name.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { LanguageModule } from '../language';


@NgModule({
  declarations: [
    ProfileUserHomeComponent,
    ProfilePictureSettingsComponent,
    ProfileUserLanguagesComponent,
    ProfileUserNameComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProfileUserRoutingModule,
    TranslocoModule,
    VerticalMenuTypeOneModule,
    AlertModule,
    LoadersModule,
    ComingSoonModule,
    LanguageModule,


  ],
  exports: [
  
    ProfileUserHomeComponent
  ],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'profile' }],

})
export class ProfileUserModule { }
