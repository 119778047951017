import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { of, Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Difference, DataProcessingService, LanguageService } from '@frontend/common';
import { ErrorService } from '../error';
import { Vote } from '../votes';
import { VoteLite } from '../votes/vote-lite.model';

@Injectable({
  providedIn: 'root',
})
export class DifferenceService {
  activeLanguageSubscription: Subscription;
  differences: Difference[] = [];
  differenceVotes: Vote[] = [];
  differenceVotesNotAvailable: { // The backend cannot return these votes. Maybe it is a new culture for which benchmark votes do not exist yet.
    difference_id: number;
    culture_id: number;
    vote_type: string;
    vote_category: string;
  }[] = [];
  allDifferencesReceived : Date; // have we requested and got all differences (no category and not type) ?
  backendDifferenceClassName : string;
  backendCultureClassName : string;

  constructor(
    private languageService: LanguageService,
    private http: HttpClient,
    private dataProcessingService : DataProcessingService,
    private errorService : ErrorService,
  ) 
  {
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(() => {
        this.clearTranslations();
      });
      this.backendDifferenceClassName = this.dataProcessingService.convertModelNameToBackendClass('difference');
      this.backendCultureClassName = this.dataProcessingService.convertModelNameToBackendClass('culture');
  }

  clearTranslations() {
    this.differences = [];
  }
  cacheDifferences(differences:Difference[]){
  
    if (this.differences.length){
      this.differences = this.dataProcessingService.mergeTwoArraysWithUniqueIdentifiersAvoidingDuplicates(differences,this.differences,'id');
    } else {
      this.differences = differences;
    }
  }

  getDifferences(category:string,type:string) {
    if(!category && !type && this.allDifferencesReceived){
      return of (this.differences);
    }
    let filteredDifferences : Difference[] = [];
    if (category){
      filteredDifferences = this.differences.filter(d=> d.category===category);
    };
    if (type){
      let differences = filteredDifferences.length ? filteredDifferences : this.differences;
      filteredDifferences = differences.filter(d=> d.type===type);
    };
    if (filteredDifferences.length) {
      return of(filteredDifferences);
    }
    let url = 'api/v1/differences?';

    let params = new URLSearchParams();
    
    if (category){
      params.append('category',category.toString())
    };
    if (type){
      params.append('type',type)
    };
    return this.http.get<{data:Difference[]}>(url+params.toString()).pipe(
      map((response) => {
        if (response?.data?.length) {
          // response.data = this.transformDifferences(response.data);
          this.cacheDifferences(response.data);
          if(!category && !type){
            this.allDifferencesReceived = new Date();
          }
          return response.data;
        }
      }),
      catchError((error)=>this.handleError(error)),
    );
  }
  generateAnchorSetFromDifference(difference:Difference,anchorCount:number = 2) : string[]{
    if(!difference){return []};
    let anchorSet = [difference.pole1];
    let emptyAnchors = anchorCount - 2;
    anchorSet = anchorSet.concat(new Array(emptyAnchors).fill(''));
    anchorSet.push(difference.pole2);
    return anchorSet;
  };
  cacheDifferenceVotes(votes:Vote[]){
    if (this.differenceVotes.length){
      this.differenceVotes = this.dataProcessingService.mergeTwoArraysWithUniqueIdentifiersAvoidingDuplicates(votes,this.differenceVotes,'id');
    } else {
      this.differenceVotes = votes;
    }
  };
  cacheVoteNotAvailable(difference_id:number,culture_id:number,vote_type:string,vote_category:string){
    this.differenceVotesNotAvailable.findIndex(vote => vote.difference_id === difference_id && vote.culture_id === culture_id && vote.vote_type === vote_type && vote.vote_category === vote_category) === -1 ? this.differenceVotesNotAvailable.push({difference_id:difference_id,culture_id:culture_id,vote_type:vote_type,vote_category:vote_category}) : null;
  }
  cacheVotesNotAvailable(votesAvailable : Vote[], request:{difference:Difference, culture_ids : number[], vote_category : string, vote_type : string}){
    let culturesAvailableIds = votesAvailable.map(vote => vote.culture_ids).reduce((acc, val) => acc.concat(val), []).filter((value, index, self) => self.indexOf(value) === index);
    let culturesNotAvailableIds = request.culture_ids.filter(id => !culturesAvailableIds.includes(id));
    culturesNotAvailableIds.forEach(culture_id => { this.cacheVoteNotAvailable(request.difference.id,culture_id,request.vote_type,request.vote_category) 
    });
  };
  getCachedDifferenceVotes(difference:Difference, culture_ids : number[], vote_type : string, vote_category : string) : VoteLite[]{
    return this.differenceVotes.filter(vote => vote.difference_ids && vote.difference_ids.includes(difference.id) && vote.culture_ids && vote.culture_ids.some(culture_id => culture_ids.includes(culture_id)) && vote.type === vote_type && vote.category === vote_category);
  };
  getDifferenceVotes(difference:Difference, culture_ids : number[], vote_category : string, vote_type : string, /*return_related_cluster_cultures : boolean*/){

    // NOTE: this will not return GLOBAL benchmark votes because they are not related directly to the culture. Find those global benchmark culture ids first, or alternatively request the global benchmark votes separately.

    // if(return_related_cluster_cultures){
    //   debugger; // We do not have a satsfactory logic for return_related_cluster_cultures yet. We do not cache the related cluster cultures, so we cannot return these from cache. TODO: rethink, or save a cacheKey with the related culture ids.
    // }

    const cachedVotes = this.getCachedDifferenceVotes(difference, culture_ids, vote_type, vote_category);

    const uniqueCultureIdsOfCachedVotes = cachedVotes
      .map(vote => vote.culture_ids)
      .reduce((acc, val) => acc.concat(val), [])
      .filter((value, index, self) => self.indexOf(value) === index);

    const allAreCached = this.dataProcessingService.matchTwoArrays(culture_ids, uniqueCultureIdsOfCachedVotes);

    if (allAreCached){
      return of(cachedVotes);
    }

    const uncachedCultureIds = culture_ids.filter(id => !cachedVotes.find(vote => vote.culture_ids && vote.culture_ids.includes(id)));

    const votesNotAvailable = this.differenceVotesNotAvailable.filter(vote => vote.difference_id === difference.id && vote.vote_type === vote_type && vote.vote_category === vote_category);

    const allUncachedCulturesAreUnavailable = this.dataProcessingService.matchTwoArrays(uncachedCultureIds, votesNotAvailable.map(vote => vote.culture_id));

    if(allUncachedCulturesAreUnavailable){
      return of(cachedVotes);
    }

    let url = 'api/v1/differences/votes?';

    let params = new URLSearchParams();

    params.append('difference_id', difference.id.toString());

    if (vote_type) {
      params.append('type', vote_type);
    }
    if (vote_category) {
      params.append('category', vote_category);
    }

    if (uncachedCultureIds.length == 1) {
      params.append('culture_id', uncachedCultureIds[0].toString());
    } else if (uncachedCultureIds.length > 1) {
      uncachedCultureIds.forEach(id => {
        params.append('culture_ids[]', id.toString());
      });
    }
    // if (return_related_cluster_cultures) {
    //   params.append('return_related_clusters', 'true'); // Note: probably best not to use this. It complicates the caching logic here in the frontend.
    // }

    return this.http.get<{data:Vote[]}>(`api/v1/differences/votes?${params.toString()}`).pipe(
      map((response) => {
      if (response?.data) {
        this.cacheVotesNotAvailable(response.data,{difference:difference,culture_ids:culture_ids,vote_category:vote_category,vote_type:vote_type});
        this.cacheDifferenceVotes(response.data);
        return this.getCachedDifferenceVotes(difference, culture_ids, vote_type, vote_category);
      }
      }),
      catchError((error) => this.handleError(error)),
    );
  }
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    // if (errorResponse.error.errors?.slug?.[0] === 'The slug has already been taken.'){
    //   errorMessage ="content_management.slug_availability_error";
    //   return throwError(errorMessage);
    // }
    const message = errorResponse.error.message;
    const standardErrorMessageTranslationKey = this.errorService.getCommonErrorMessageTranslationKey(message);
    if(standardErrorMessageTranslationKey){
      errorMessage = standardErrorMessageTranslationKey;
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);
  }
}
