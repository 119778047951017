<div class="container-fluid">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <div *ngIf="!loadingObject.tip">
          <div class="row mt-3" *ngIf="tip?.videos?.length">
            <div class="col">
              <h1 class="font-weight-bold mt-3 text-break d-md-block d-lg-block d-none">
                {{ tip.title }}
              </h1>
              <p>
                {{ tip.description }}
              </p>
              <div class="mt-4">
                <frontend-video-player-type-two *ngFor="let video of tip.videos" [video]="video" [classes]="'mb-3'">
                </frontend-video-player-type-two>
                <hr class="my-5" />
              </div>
            </div>
          </div>
          <div class="row mt-3" *ngIf="tip && !tip.videos?.length">
            <div class="col-md-4">
              <img class="w-100" [src]="tip.media?.[0]?.file_url" [alt]="tip.media?.[0]?.description" />
            </div>
            <div class="col-md-8">
              <h1 class="font-weight-bold text-break">
                {{ tip.title }}
              </h1>
              <p>
                {{ tip.description }}
              </p>
            </div>
          </div>
          <div class="row my-4" *ngIf="tip">
            <div class="p-0" [innerHTML]="tip.body"></div>
          </div>
        </div>
        <div class="row mt-3" *ngIf="loadingObject.tip">
          <div class="col-md-4">
            <div class="bg-primary h-100 align-middle text-center" style="opacity: 0.5">
              <multisite-loader-globe-animation-detail [width]="100" [height]="100">
              </multisite-loader-globe-animation-detail>
            </div>
          </div>
          <div class="col-md-8">
            <h5 class="card-title placeholder-glow">
              <span class="placeholder col-6"></span>
            </h5>
            <p class="card-text placeholder-glow">
              <span class="placeholder col-7"></span>
              <span class="placeholder col-4"></span>
              <span class="placeholder col-4"></span>
              <span class="placeholder col-6"></span>
              <span class="placeholder col-8"></span>
            </p>
          </div>
        </div>
        <!-- COMMENTS -->
      </div>
      <div class="col-lg-4">
        <div class="mt-5 sticky-top">
          <div class="container-card-wrap">
            <div class="wrap-body">
              <!-- Place for user pills 
              <div class="card-optional-content">
                <p class="h5">
                  Liked by:
                </p>
                <div class="w-100">
                </div>
                and <span>4</span> other interculturalistis
              </div>
              -->

              <div class="card-optional-content" *ngIf="!myRating || newRatingSaved && !ratingSavingCompleted">
                <!-- <h5 *transloco="let t">{{ t('social.rate_this_tip')}}</h5> -->
                <multisite-rating-basic
                  [translationKey]="ratingTranslationKey"
                  [max]="5"
                  [rate]="myRating"
                  [newRatingSaved]="newRatingSaved"
                  [isReadonly]="loadingObject.rating"
                  [loading]="loadingObject.rating"
                  (submitRating)="rate($event)"
                  (feedbackModalClosed)="acknowledgeRatingFeedback()"
                >
                </multisite-rating-basic>
                <multisite-loader-globe-animation-detail *ngIf="loadingObject.rating" [width]="100" [height]="100">
                </multisite-loader-globe-animation-detail>
              </div>

              <div class="card-optional-content" *transloco="let t">
                <div *ngIf="tip?.contributors?.length" class="mb-3">
                  <h6 class="mb-1" *transloco="let t">{{t('content.created_by')}}</h6>
                  <!-- <p class="text-muted mb-1">{{tip.contributors.length===1 ? t('common.contributor'):t('common.contributors')}}</p> -->
                  <div class="contributors">
                    <multisite-avatar-pill *ngFor="let contributor of tip.contributors"
                    [fallbackImageUrl]="'https://res.cloudinary.com/cebt/image/upload/w_24,h_24/v1616972586/users/avatars/default-avatar.gif'"
                    [fallbackTranslationKey]="'social.verified_user'" [imageUrl]="contributor.user.picture" [text]="contributor.user.pre_honorific+' '+contributor.user.fname+' '+contributor.user.lname+'
                    '+contributor.user.post_honorific"></multisite-avatar-pill>
                  </div>
                  <p class="mb-1">{{t('social.hope_useful')}}</p>
                </div>
          
              
              </div>
              
              <div class="card-optional-content" *ngIf="resourceLinks?.length">
                <multisite-link-type-six *ngFor="let link of resourceLinks"
                  [imageTransformations]="'w_100,c_thumb/'"
                  [titleText]="link.label"
                  [description]="link.description"
                  [imageUrl]="link.media?.base_url"
                  [media]="link.media"
                  [cta_label]="link.cta_label"
                  [cta_type]="link.cta_type"
                  [url]="link.url"
                >
                </multisite-link-type-six>
              </div>
             

              @if (isAdmin) {

                <div class="card-optional-content">
              
                  <div class="alert alert-warning" role="alert">
                    <i class="fa-solid fa-stopwatch"></i>
                    Registration closes today!
                  </div>
                </div>

              

              
                <div class="card-optional-content">
                  <div class="card-optional-content-border">
                    <p class="h5">
                    More tips:
                  </p>
                  <multisite-link-type-seven-list *ngIf="tip?.links?.length"
                  [links]="tip.links"
                  >

                  </multisite-link-type-seven-list>
                </div>
                </div>
              }

              <div *ngIf="callsToAction?.length" class="card-optional-content">

                  <multisite-link-type-eight *ngFor="let cta of callsToAction"
                  [titleText]="cta.label"
                  [cta_label]="cta.cta_label"
                  [cta_type]="cta.cta_type"
                  [description]="cta.description"
                  [descriptionTruncateLength]="110"
                  [imageUrl]="cta.imageUrl"
                  [imageTransformations]="'w_100/'"
                  [media]="cta.media"
                  [url]="cta.url"
                  (linkClicked)="ctaClicked(cta, $event)"
                  >
                    

                  </multisite-link-type-eight>
              </div>

              <div class="card-optional-content" *ngIf="myRating">
                <!-- <h5 *transloco="let t">{{ t('social.rate_this_tip')}}</h5> -->
                <multisite-rating-basic *ngIf="myRating>0"
                  [translationKey]="ratingTranslationKey"
                  [max]="5"
                  [rate]="myRating"
                  [newRatingSaved]="newRatingSaved"
                  [isReadonly]="loadingObject.rating"
                  [loading]="loadingObject.rating"
                  (submitRating)="rate($event)"
                  (feedbackModalClosed)="acknowledgeRatingFeedback()"
                >
                </multisite-rating-basic>
              </div>
             
            </div>

              


          </div>

        </div>
      </div>
    </div>
    <div *ngIf="!loadingObject.tip">
      <div class="mt-4">
        <alert [type]="'warning'" [dismissOnTimeout]="6000" *ngIf="interculturalistRoleAlertComments"><span
            *transloco="let t">{{ t('authentication.interculturalist_role_required')
            }}<a [routerLink]="['/profile']" routerLinkActive="router-link-active">{{ t('common.more_info')
              }}</a></span></alert>
        <comments-post-display-pagination-all-in-one
          [user]="user"
          [paginatedComments]="paginatedComments"
          [loadingComments]="loadingObject.comment"
          [clearCommentForm]="clearCommentForm"
          [titleTranslationKey]="'social.leave_a_comment'"
          [titleClasses]="['h3', 'mb-5']"
          (postComment)="postComment($event, null)"
          (getComments)="getComments($event, null)"
          >
        </comments-post-display-pagination-all-in-one>
      </div>
    </div>
  </div>
</div>