import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlinePillPickerComponent } from './inline-pill-picker.component';
import { PillsModule } from '../pills.module';
import { AvatarsModule } from '../../../users';
import { TranslocoModule } from '@jsverse/transloco';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@NgModule({
    declarations: [InlinePillPickerComponent],
    imports: [
        CommonModule,
        PillsModule,
        AvatarsModule,
        TranslocoModule,
        CollapseModule,
    ],
    exports: [InlinePillPickerComponent]
})
export class InlinePillPickerModule { }