import { Injectable, computed, signal } from '@angular/core';
import { Notification } from './notification.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PaginatedNotifications } from './paginated-notifications.model';
import { ErrorService } from '../error';

export interface LocalStorageNotificationStatus {

  type_dismissed_until : Date, // pending_tasks

}

type LocalStorageNotificationsByModel = {

  [key:string]:{[key:number|string]:{[key:string]:LocalStorageNotificationStatus}}

  /*
    "hub":{"14":{"pending_tasks":{"type_dismissed_until":"2024-12-15 12:49:21"}}}
  */

}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  localstorageNotificationsByModel : LocalStorageNotificationsByModel = {};
  private paginatedNotificationsCollection = signal<{ [page: number]: PaginatedNotifications }>({});
  private singleNotifications = signal<Notification[]>([]);
  private _topThreeUnreadNotifications = computed(() => {
    const paginated = this.paginatedNotificationsCollection()[1]?.data.filter(n => !n.read_at).slice(0, 3);
    if(paginated?.length > 0){
      return paginated;
    } else {
      return this.singleNotifications().filter(n => !n.read_at).slice(0, 3);
    }
  });


  constructor(private http: HttpClient, private errorService: ErrorService) { 
    this.clearExpiredLocalStorageNotifications();
  }

  clearData (){
    localStorage.removeItem('localNotifications');
    this.paginatedNotificationsCollection.set({});
  }
  clearExpiredLocalStorageNotifications(){
    const timeNow = new Date().getTime();
    let storedNotificationStatuses : LocalStorageNotificationsByModel = localStorage.getItem('localNotifications') ? JSON.parse(localStorage.getItem('localNotifications')) : {};
    for (let model in storedNotificationStatuses){
      for (let identifier in storedNotificationStatuses[model]){
        for (let type in storedNotificationStatuses[model][identifier]){
          if (storedNotificationStatuses[model][identifier][type]){ // TODO - consider removing this condition;it corrected an earlier bug and it should be unnecessary
            let dismissed_until = storedNotificationStatuses[model][identifier][type].type_dismissed_until;
            if (dismissed_until){
              let dismissed_until_date = new Date (dismissed_until);
              if (dismissed_until_date.getTime() < timeNow){
                delete storedNotificationStatuses[model][identifier][type];
              }
            }
          }
        }
      }
    }
    localStorage.setItem('localNotifications',JSON.stringify(storedNotificationStatuses))
  }
  get topThreeUnreadNotifications() {
    return this._topThreeUnreadNotifications();
  };
  getLocalNotificationStatusFromLocalStorage (model : string, identifier : string, type : string){
    if (!model || !identifier || !type ){return;};
    let storedNotificationStatuses : LocalStorageNotificationsByModel = localStorage.getItem('localNotifications') ? JSON.parse(localStorage.getItem('localNotifications')) : {};
    return storedNotificationStatuses[model]?.[identifier]?.[type];
  }
  putNotificationStatusInLocalStorage (model : string, identifier : string, type : string, status : LocalStorageNotificationStatus){
    if (!model || !identifier || !type || !status ){return;};
    let storedNotificationStatuses : LocalStorageNotificationsByModel = localStorage.getItem('localNotifications') ? JSON.parse(localStorage.getItem('localNotifications')) : {};
    if (!storedNotificationStatuses[model]){
      storedNotificationStatuses[model] = {[identifier]:{[type]:status}};
    } else if (!storedNotificationStatuses[model][identifier]){
      storedNotificationStatuses[model][identifier] = {[type]:status};
    } else {
      storedNotificationStatuses[model][identifier][type] = status;
    }
    localStorage.setItem('localNotifications',JSON.stringify(storedNotificationStatuses))
  }
  getNotifications(page: number): Observable<PaginatedNotifications> {
    page = page || 1;
    if (this.paginatedNotificationsCollection()[page]) {
      return of(this.paginatedNotificationsCollection()[page]);
    } else {
      return this.http.get<PaginatedNotifications>(`/api/v1/notifications?page=${page}`).pipe(
        tap(notifications => {
          this.paginatedNotificationsCollection.set({...this.paginatedNotificationsCollection(), [page]: notifications});
        }),
        catchError((error) => {
          return this.handleError(error);
        })
      );
    }
  }
  getCachedNotification (id){
    let pageNumber : number = 1;
    let notification : Notification = null;
    while (this.paginatedNotificationsCollection()[pageNumber]){
      notification = this.paginatedNotificationsCollection()[pageNumber].data.find(n=>n.id == id);
      if (notification){
        return notification;
      } else {
        pageNumber++;
      }
    }
    return this.singleNotifications().find(n=>n.id == id);
  };
  cacheSingleNotification(notification: Notification) {
    let pageNumber: number = 1;
    let notificationExistsAmongPaginatedNotifications = false;
    while (this.paginatedNotificationsCollection()[pageNumber]) {
      const page = this.paginatedNotificationsCollection()[pageNumber];
      const index = page.data.findIndex(n => n.id === notification.id);
      if (index !== -1) {
        page.data[index] = notification;
        this.paginatedNotificationsCollection.set({...this.paginatedNotificationsCollection(), [pageNumber]: page});
        notificationExistsAmongPaginatedNotifications = true;
        return;
      }
      pageNumber++;
    }
    if(!notificationExistsAmongPaginatedNotifications){
      let foundIndex = this.singleNotifications().findIndex(n => n.id === notification.id);
      if(foundIndex !== -1){
        this.singleNotifications.set([...this.singleNotifications().slice(0, foundIndex), notification, ...this.singleNotifications().slice(foundIndex + 1)]);
      } else {
        this.singleNotifications.set([...this.singleNotifications(), notification]);
      }
    } else {
      this.singleNotifications.set(this.singleNotifications().filter(n => n.id !== notification.id));
    }
  }

  getNotification (id:string){

    let cachedNotification : Notification = this.getCachedNotification(id);
    if (cachedNotification){
      return of(cachedNotification);
    };

    return this.http.get<{'data' : Notification}>(
      'api/v1/notifications/'+id)
        .pipe(
          map(response =>{
            // let notification : Notification = this.transformNotification(response.data);
            return response.data;
          }),
          catchError((error) => {
            return this.handleError(error);
          })
        )
  };
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    } else {
      const message = errorResponse.error.message;
      const standardErrorMessageTranslationKey = this.errorService.getCommonErrorMessageTranslationKey(message);
      if(standardErrorMessageTranslationKey){
        errorMessage = standardErrorMessageTranslationKey;
      } else if(message.includes('Request latest data then try again.')){
        errorMessage = 'error.refresh';
      }
      if (errorResponse.error.meta){
        return throwError({message:errorMessage,meta:errorResponse.error.meta});
      }
      return throwError(errorMessage);
    }
  }
}
