import { Culture } from "../cultures";
import { Cluster } from "./cluster.model";

export class CultureClusterWrapper {
    constructor (
        public id : number, // This is the cluster id
        public slug : string,
        public category : string,
        public type : string,
        public cultures : Culture[],
        public cluster: Cluster,
    ){

    }
}