<div class="lickert-bar">
  @if(steps()?.[value()]){
    <div class="active-step" id="active-step">
      <span *transloco="let t; scope: steps()[value()].labelTranslationScope">
        {{t(steps()[value()].labelTranslationKey)}}
      </span>
    </div>
  }
  <div class="bar-container">
    <div class="chevron" id="chevron-left" (click)="moveLeft()">
      <i class="fa-solid fa-chevron-left"></i>
    </div>
    <div class="bar" id="lickert-slider">

      @for (step of steps(); track $index) {
        <div 
          class="segment" 
          style="--set-min-width: 24px;"
          [ngClass]="{'active': $index === value() || (volumeControlStyle() && $index <= value())}"
          (click)="onLabelClick(step.value)"
          [ngStyle]="{
            'opacity': minimumOpacity() === 0 || minimumOpacity() ? (100 * (step.value/max()))+'%' : '100%',
            'background-color' : (volumeControlStyle() && $index <= value() && step.colour) ? step.colour : ''
            }"
        >
      
        <span
                class="align-center"
                [ngClass]="{'text-muted': isDisabled}"
                [ngStyle]="{'cursor':isDisabled ? 'default':'pointer' }"
                *transloco="let t; scope: step.labelTranslationScope"
                >
                {{t(step.labelTranslationKey)}}
      </span>
      
      </div>
      }
    </div>
    <div class="chevron" id="chevron-right" (click)="moveRight()">
      <i class="fa-solid fa-chevron-right"></i>
    </div>
  </div>
</div>