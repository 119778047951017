import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export class SaveService {
    private saveSuccessSubject = new Subject<boolean>();
    saveSuccess$ = this.saveSuccessSubject.asObservable();
  
    notifySaveSuccess() {
      this.saveSuccessSubject.next(true);
    }
  }