import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  input,
  output,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { Notification } from '@frontend/common';
import { MainNavigationLink } from '../main-navigation-link.model';
import { Brand, BrandDisplayService } from '../../branding';
import { AuthService, User, UserRole } from '../../auth';
import { Subscription } from 'rxjs';
import {  AvailableLanguage,
          LanguageService,
          MediaService,
          AccountDropdownLink,
          AccountDropdownLinks,
         } from '@frontend/common';


@Component({
  selector: 'multisite-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
})
export class MainNavigationComponent implements OnInit {
  // sidenavToggleButtonDisabled: boolean;
  changeSidenavMode = output<void>();
  logout = output<void>();
  notificationSelected = output<Notification>();
  user = input<User | undefined>(undefined);
  sidenavMode = input<string | undefined>(undefined);
  currentBrandSubscription: Subscription;
  brand: Brand;
  logo = input<string | undefined>(undefined); // 'culturettt' or 'icpendulum'
  navCollapsed : boolean = true;
  navLinks = input<MainNavigationLink[] | undefined>(undefined);
  loading = input<boolean | undefined>(undefined);
  activeLanguageSubscription: Subscription;
  activeLanguageObject : AvailableLanguage;
  availableLanguages : AvailableLanguage[];
  accountDropdownLinks : AccountDropdownLink[];
  notifications = input<Notification[]>([]);

  constructor(
    private router: Router,
    private brandDisplayService: BrandDisplayService,
    private authService: AuthService,
    private languageService: LanguageService,
    private mediaService: MediaService,
    ) {
      this.accountDropdownLinks = AccountDropdownLinks;
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
            this.hideNav();
        }
        
        // if (event instanceof NavigationEnd) {
          //     let url = event.url;          
          //     console.log('The new url is '+event.url);
          //     if (event.url.indexOf('demos')>-1){
            //       this.isDemo = true; // we need to hide the footer; it conflicts with the canvas
            //     } else {
              //       this.isDemo = false;
              //     }
              // }
    });
    this.availableLanguages = this.languageService.availableLanguages;
}

  setActiveLanguage(languageKey){ 
    this.languageService.setLanguage(languageKey);
  }
  getFlagUrl(availableLanguage:AvailableLanguage,transformations:string, file_extension:string){
    return this.mediaService.getFlagUrlFromHash(availableLanguage?.flag.hash,transformations,true,file_extension);
  }
  navigateTo(target) {
    this.router.navigateByUrl(target);
  }
  onNotificationSelected(notification: Notification) {
    if(!notification){return;}
    this.notificationSelected.emit(notification);
  }
  isAdmin (){
    return this.authService.checkRole("Admin");
  }
  hasRole (roleName:string){
    return this.authService.checkRole(roleName); // for example "Admin"
  }

  hideNav(){
    this.navCollapsed = true;
  }
  toggleSidenavMode() {
    this.changeSidenavMode.emit();
  }
  darkModeActive () {
    return this.brandDisplayService.darkMode;
  }
  getLogoFile(type : string){
    if (type === 'logo_banner'){
      if (this.darkModeActive() && this.brand?.logo_banner_inverse){
        return this.brand.logo_banner_inverse;
      } else {
        return this.brand.logo_banner;
      };
    } else {
      if (this.darkModeActive() && this.brand?.logo_square_inverse){
        return this.brand.logo_square_inverse;
      } else {
        return this.brand.logo_square;
      }
    }
  }
  getDefaultAvatar (){
    return this.mediaService._fallback_avatar_url;
  }
  handleClick(functionName:string){
    if(functionName){
      this[functionName]();
    }
  }

  doLogout(){
    this.logout.emit();
  }
  filteredAccountDropdownLinks(accountDropdownLinks: AccountDropdownLink[], userRoles : UserRole[]) {
    return accountDropdownLinks && userRoles ? accountDropdownLinks.filter(link => link.filteredRoles?.length < 1 || this.authService.getMatchingUserRoles(userRoles,link.filteredRoles).length) : [];
  }

  ngOnInit(): void {
    this.currentBrandSubscription = this.brandDisplayService.currentBrand.subscribe( (brand)=>{
      if (brand){
        this.brand = brand
      } else {
        this.brand = null;
      }
    });
    this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( activeLanguageObject => {
      this.activeLanguageObject = activeLanguageObject;
    })
  }
}
