import { Component, OnChanges, OnInit, SimpleChanges, computed, effect, input, output, signal } from '@angular/core';
import { MediaService } from '../../content';
import { Language } from '../language.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RangeInputStep } from '../../forms';
import { LanguageSkill } from '../language-skill.model';
import { TranslocoService } from '@jsverse/transloco';
import { LanguageService } from '../language.service';
import { FlagExtraLite } from '../../flags';
import { TranslationService } from '../../translation/translation.service';
import { ProportionInputItem } from '../../surveys/proportion-input-item.model';

@Component({
  selector: 'multisite-user-language-editor',
  templateUrl: './user-language-editor.component.html',
  styleUrl: './user-language-editor.component.scss'
})
export class UserLanguageEditorComponent implements OnInit {

  allLanguages = input.required<Language[]>();
  myLanguages = input<Language[] | undefined>(undefined);
  error = input<string|{message:string,meta:any}|undefined>(undefined);
  loading = input<boolean>();
  onSaveLanguage = output<LanguageSkill>();
  onDeleteLanguage = output<Language>();
  languageAddForm : FormGroup;
  availableLanguages = computed(() => {
    return this.allLanguages() ?  (!this.myLanguages()?.length ? this.allLanguages() : this.allLanguages().filter(language => !(this.myLanguages().some(myLanguage => myLanguage.id === language.id) ))) : [];
  });
  newLanguage = signal<Language>(undefined);
  myLanguagesAndNewLanguage = computed(() => {
    const languages : Language[] = this.myLanguages() ? this.myLanguages().slice() : [];
    debugger;
    if (this.newLanguage() && !languages.find(language => language.id === this.newLanguage().id)){
      languages.unshift(this.newLanguage());
    }
    return languages;
  });
  translationSourceLanguages = computed(() => {
    return this.allLanguages() ? this.allLanguages()?.filter(language => this.translationService.availableTranslationSourceLanguageCodes.includes(language.iso)) : [];
  });
  // myLanguageUpdateCount = signal<number>(0);


  constructor(
    private mediaService : MediaService,
    private translocoService : TranslocoService,
    private languageService : LanguageService,
    private translationService : TranslationService,
  ) {
    
  }

  get languageSkillLevelRangeInputSteps() : RangeInputStep[]{
    return this.languageService.languageSkillLevelRangeInputSteps;
  };

  instantiateNewLanguage(){
    const name = this.translocoService.translate('common.add_a_language');
    const flagUrl = this.mediaService.getFlagUrlFromHash('missing','w_32,c_fill,ar_1:1,r_max,f_auto/',true,'.png');
    this.newLanguage.set(new Language(0,'',name,new FlagExtraLite('',''),flagUrl));
  }

  getFlagUrlFromHash(hash:string,transformations : string = ''){
    return this.mediaService.getFlagUrlFromHash(hash,transformations,true,'.png');
  }

  onNewLanguageSelect(event : {item: Language}){
    const language = event.item ? event.item : undefined;
    this.newLanguage.set(language);
    this.languageAddForm.reset();
  }

  onSubmit(languageSkillValues: ProportionInputItem){
    if(!languageSkillValues){ return; }
    const language = this.allLanguages()?.find(lang => lang.id === languageSkillValues.id);
    const level = +languageSkillValues.numberValue;
    const description = languageSkillValues.textValue;
    const languageSkill = new LanguageSkill(language.id, level, description); 
    this.onSaveLanguage.emit(languageSkill);
  }

  onLanguageRemove(event, language : Language){
    if(event && typeof event.stopPropagation === 'function'){
      event.stopPropagation();
    }
    const translation = this.translocoService.translate('common.permanent_are_you_sure');
    if (confirm(translation)) {
      if(language.id === this.newLanguage()?.id){
        this.newLanguage.set(null);
        return;
      }
      this.onDeleteLanguage.emit(language);
    }
  }
  initialiseForm(){
    this.languageAddForm = new FormGroup({
      language: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(){
    this.initialiseForm();
  }


}
