import { Component, computed, input, output, signal } from '@angular/core';
import { MediaService } from '../../content';

import { Delivery } from '../delivery.model';
import { CultureConnectorService } from '../../cultureconnector';
import { CloudinaryMediaAsset } from '../../models';
import { DataProcessingService } from '../../utilities';
import { ContributionsService, DeliveryType } from '../contributions.service';
import { Culture } from '@frontend/common';


@Component({
  selector: 'multisite-delivery-status-card',
  templateUrl: './delivery-status-card.component.html',
  styleUrls: ['./delivery-status-card.component.scss']
})
export class DeliveryStatusCardComponent {

  delivery = input.required<Delivery>();
  deliveryType = computed<DeliveryType>(() => this.contributionsService.getContributionType(this.delivery()?.task?.category, this.delivery()?.task?.type));
  deliveryStatus = computed(() => this.contributionsService.getDeliveryStatus(this.delivery()));
  showCredits = input<boolean>(true); // temporary option until we develop the credits feature. Remove when credits are implemented.
  selected = output<Delivery>();
  cloudinary_base_url = signal<string | undefined>(undefined);
  thumbnailTransformations = signal<string>('ar_16:9,w_375/');
  truncatedSubjectDescription = computed(() => this.dataProcessingService.truncateText(this.delivery().subject?.description, 200));
  title = computed(() => {
    if(this.delivery().insight?.s){
      return this.delivery().insight.s;
    } else if (this.delivery().differences?.[0]?.name){
      return this.delivery().differences[0].name;
    }
  });
  cultures = computed<Culture[]>(() => {
    if(this.delivery().insight?.cultures){
      return this.delivery().insight.cultures;
    } else if (this.delivery().votes?.[0]?.cultures){
      return this.delivery().votes[0].cultures;
    }
    return [];
  });

  constructor(
    private mediaService : MediaService,
    private cultureConnectorService : CultureConnectorService,
    private dataProcessingService : DataProcessingService,
    private contributionsService : ContributionsService,
  ) {
    this.cloudinary_base_url.set(this.mediaService.cloudinary_base_url);
  }
  getFlagUrlFromHash(hash:string,transformations : string = ''){
    return this.mediaService.getFlagUrlFromHash(hash,'',true,'.png');
  }
  getThumbnailUrl(delivery:Delivery){
    let imageUrl = this.cloudinary_base_url() + this.thumbnailTransformations() + 'concepts/keyboard-typing-midjourney_dac4c201-ec3b-4bbf-9692-b0991ce7a03c_2_n5xdft';
    let insightThumbnail : CloudinaryMediaAsset = delivery.insight?.media?.find(media => media.category === 'thumbnail' && media.type==='main');
    if(delivery.subject?.category === 'cco_dimension' && delivery.subject?.type){
      imageUrl = this.cultureConnectorService.getDimension(null,delivery.subject.type)?.img_url;
    } else if(insightThumbnail){
      imageUrl = this.cloudinary_base_url() + this.thumbnailTransformations() + insightThumbnail.file_name +'.jpg';
    }
    return imageUrl;
  }
  onButtonClick(){
    this.selected.emit(this.delivery());
  }

}
