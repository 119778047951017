import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { map, catchError, switchMap } from 'rxjs/operators';

import { forkJoin, Observable, of, throwError } from 'rxjs';

import { BrandDisplayService, Culture, CultureService, HubMicro, MediaService, Setting } from '@frontend/common';
// import { LanguageService } from '@frontend/common';

import { Tag, TagService } from '@frontend/core';

import {CcoRecommendation } from './cco-recommendation.model';
import {CcoDimension } from './cco-dimension.model';
import { Icon, IconLinkListLink, IconLinkListLinkGroup } from '@frontend/shared';

@Injectable({
  providedIn: 'root'
})
export class CultureConnectorService {

  // activeLanguageSubscription: Subscription;
  private _ccoDimensions: CcoDimension[] = [];

  constructor(
    private tagService: TagService,
    // private languageService: LanguageService,
    private cultureService: CultureService,
    private mediaService: MediaService,
    private brandDisplayService: BrandDisplayService,
  ) { 
    // this.activeLanguageSubscription =
    //   this.languageService.activeLanguageObject.subscribe(() => {
    //     this.clearTranslations();
    //   });
    this._ccoDimensions = ['space','use-of-time','time-spans','fate','rules','power','responsibility','group-membership','tasks','directness','conflict','problem-solving'].map((d,index)=>new CcoDimension((index+1),d,d,'https://cultureconnector.com/app/images/dimensions/'+(index+1)+'.png'));
  }

  // clearTranslations() {

  // }
  // clearData() {
  //   this.clearTranslations();
  // }
  getDimension(id?:number,slug?:string) : CcoDimension{
    return this._ccoDimensions.find(d=>d.id === id || d.slug === slug);
  }
  convertSingleCultureRecommendationToLinkGroup(recommendation:CcoRecommendation) : IconLinkListLinkGroup {
    let links : IconLinkListLink[] = [];
    links = recommendation.dimensions.map(d=>{
      return new IconLinkListLink('https://www.cultureconnector.com/#/culture/'+recommendation.culture.cco+'/dimension/'+d.id,null,[d.img_url],d.name,null,'common.open_link');
    })
    return new IconLinkListLinkGroup(
      links,
      this.mediaService.getFlagUrlFromHash(recommendation.culture.flag?.hash,'w_40,c_fill,ar_1:1,r_max,f_auto/',true,'.png'),
      null,
      recommendation.culture.name,
      null
      )
  }
  convertMultipleCultureRecommendationsToLinks(recommendations:CcoRecommendation[]){
    let links : IconLinkListLink[] = [];
    recommendations.filter(r=>r.culture?.cco).forEach(recommendation=>{
      recommendation.dimensions.forEach(d=>{
        links.push(new IconLinkListLink(
          'https://www.cultureconnector.com/#/culture/'+recommendation.culture.cco+'/dimension/'+d.id,
          null,
          [
            this.mediaService.getFlagUrlFromHash(recommendation.culture.flag?.hash,'w_40,c_fill,ar_1:1,r_max,f_auto/',true,'.png'),
            d.img_url
          ],
          recommendation.culture.name+': '+d.name,
          null,
          'common.open_link'
          ));
      })
    })
    return links;
  }
  

  formatRecommendationsAsIconLinkListLinks(recommendations : CcoRecommendation[]) : IconLinkListLinkGroup[]{
    let linkGroups : IconLinkListLinkGroup[] = [];
    if (recommendations.length == 1){

      let recommendationsWithContent = recommendations;
      linkGroups = recommendationsWithContent.filter(r=>r.culture?.cco).map(r=>{
        return this.convertSingleCultureRecommendationToLinkGroup(r);
      })

    } else if (recommendations.length > 1){
      
      let links : IconLinkListLink[] = this.convertMultipleCultureRecommendationsToLinks(recommendations);

      if (links?.length){
        linkGroups.push(new IconLinkListLinkGroup(links,null,new Icon('fa-solid fa-globe'),null,'content.key_areas'));
      }

    }
    return linkGroups;
  }
  insertRedirectsIntoIconLinkListLinks(linkGroups : IconLinkListLinkGroup[], settings: Setting[]) : IconLinkListLinkGroup[]{
    if (!settings?.length || !linkGroups?.length){return linkGroups;};
    let redirectSetting = settings.find(s=>s.setting_name === 'redirect_personalised_links' && s.category === 'cco');
    if (redirectSetting && redirectSetting.setting_value && !redirectSetting.disabled && !redirectSetting.deleted_at){
      return linkGroups.map(lg=>{
          lg.links = lg.links.map(l=>{
            l.url = redirectSetting.setting_value;
            return l;
          });
          return lg;
      });
    }
    return linkGroups;
  }

  mergeRecommendationsContent (recommendations: CcoRecommendation[], content: {tags:Tag[],cultures:Culture[],hubMicro:HubMicro }) : CcoRecommendation[]{
    recommendations.forEach(recommendation =>{
      recommendation.culture = content.cultures.find(c=>c.id === recommendation.culture_id);
      recommendation.dimensions = [];
      if(recommendation.dimension_ids){

        recommendation.dimension_ids.forEach(dimension_id => {
          const tagFromDimensionId = content.tags.find(t=> +t.img_url.match(/(\d+)\.png$/)?.[1] === dimension_id);
          recommendation.dimensions.push({
            id: dimension_id,
            name: tagFromDimensionId?.name,
            slug: tagFromDimensionId?.slug,
            img_url: tagFromDimensionId?.img_url,
          })
        })
      }
    })
    return recommendations;
  }

  getRecommendations(recommendations:CcoRecommendation[], freshFromServer : boolean): Observable<any> {
    if (!recommendations){return this.handleError(new HttpErrorResponse({error:'Recommendations missing'}));};
    return this.brandDisplayService.currentHubMicro.pipe(
      switchMap(hubMicro => forkJoin({
          tags: this.tagService.getTagsByType(['cco_dimension'], freshFromServer),
          cultures: this.cultureService.getCultures('geographic', 'national', false),
          hubMicro: of(hubMicro) // Use of() to create an observable from the value because plain this.brandDisplayService.currentHubMicro here did not work
      })),
      map(result => this.mergeRecommendationsContent(recommendations, result)),
      catchError(error => {
          console.error('Error getting recommendations:', error);
          throw error;
      })
  );
  }
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    switch (errorResponse.error.message) {
      case 'The type is invalid.':
        errorMessage = 'error.data_invalid';
        break;
      default :
        // no action needed. We already set the default error message.
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);
  }

}
