<div class="input-container">

    <div class="range-input-column-layout" data-role="rangeslider">
      <input
        type="range"
        id="range-input"
        [min]="min()"
        [max]="max()"
        [step]="stepSize()"
        name="range-input"
        class="form-range"
        [value]="value"
        (input)="onInput($event)"
        [disabled]="isDisabled"
        >
      <div class="label-holder">
        @for(step of steps(); track step){
            <label
                for="range-input"
                (click)="onLabelClick(step.value)"
                class="align-center"
                [ngClass]="{'text-muted': isDisabled}"
                [ngStyle]="{'cursor':isDisabled ? 'default':'pointer' }"
                *transloco="let t; scope: step.labelTranslationScope"
                >
                {{t(step.labelTranslationKey)}}
            </label>
        }
      </div>
    </div>
  </div>

      <!-- Radio Buttons for Levels (testing) -->


  <!-- <div class="input-container">
    <div class="range-input-column-layout">
      
  
      <div class="d-flex flex-column gap-2 align-items-start w-100">
        @for(step of steps(); track step) {
          <div class="form-check">
            <input
              type="radio"
              id="level-{{ step.value }}"
              name="language-level"
              class="form-check-input"
              [value]="step.value"
              [checked]="value === step.value"
              (change)="onLabelClick(step.value)"
              [disabled]="isDisabled"
            />
            <label
              for="level-{{ step.value }}"
              class="form-check-label text-center"
              *transloco="let t; scope: step.labelTranslationScope"
            >
              {{ t(step.labelTranslationKey) }}
            </label>
          </div>
        }
      </div>
    </div>
  </div> -->
  