<main>
  <div class="container" *transloco="let t">
    <div *ngIf="loadingObject.course">
      <multisite-loader-globe-animation-detail [width]="100" [height]="100"></multisite-loader-globe-animation-detail>
    </div>
    @if(course){
      <multisite-product-header-type-one
        [title]="course.heading"
        [subtitle]="course.subheading"
        [description]="course.description"
        [video]="course.videos?.[0]"
        [contributors]="presenters"
        [separateContributorCategories]="['presenter']"
        [selectableContributorCategories]="[]"
        [price]="hasRole('Developer') ? '$124.99' : null"
        [progressTitleTranslationKey]="'tracking.progress'"
        [progressValue]="courseProgressValue"
        [progressUnit]="'%'"
        [tags]="course.tags"
        [list]="course.learnings"
        [listTitleTranslationKey]="'marketing.what_learn'"
        [ctaButton]="ctaButton()"
        [ctaButtonDisabled]="!nextLesson || (!user && !course.allow_guests) || (!user && !guest)"
        (buttonClicked)="onCtaButtonClicked()"
        (goToLogin)="goToLogin()"
        (makeGuest)="makeGuest()"
        [rating]="roundedRating()"
        [ratingMax]="5"
        [dateLabelTranslationKey]="courseDateLabelTranslationKey()"
        [date]="courseDate()"
        [showContinueAsGuestOption]="!user && !guest && course.allow_guests"
        [showLoginOption]="!user"
        [loading]="isAnythingLoading()"
        [guestLoading]="loadingObject.new_guest"
        [generalError]="error"
        [newGuestError]="loadingNewGuestError"
        [iconLinks]="accreditationsRequiringThisCourseAsMetaText"
        [iconLinksLabelTranslationKey]="'courses.required_for_accreditation'"
        (selectIconLink)="gotoAccreditation($event)"
      ></multisite-product-header-type-one>
    }
    @if(course?.target_groups.length){

      <multisite-image-text-list
        class="mt-5"
        [media]="course.media?.[0]"
        [titleTranslationKey]="'courses.who_for'"
        [list]="course.target_groups"
        [cardClasses]="'card-gray-200 border-0'"
      >
      </multisite-image-text-list>
    }
    <div *ngIf="course">
      @if(course){
       
        <div class="lessons-summary">
          <p class="h4 font-weight-bold mb-3">{{t('courses.content')}}</p>
          <p class="text-muted">
            <span *ngIf="courseModules?.modules?.length">{{t('courses.modules')}}:<span
                class="ml-1">{{courseModules?.modules?.length}}</span></span>
  
            <span *ngIf="lessonCount"><span class="mx-2">•</span>{{t('courses.lessons')}}:<span
                class="ml-1">{{lessonCount}}</span></span>
            <span *ngIf="courseDurationConverted"><span class="mx-2">•</span>{{t('common.duration')}}:<span
                class="ml-1">{{courseDurationConverted}} {{t('common.minutes')}}</span></span>
          </p>
          <hr class="m-0 p-0">
          <div class="all-lessons-list" *transloco="let t">
            <div class="all-lessons-body">
              <multisite-playlist-accordion-text *ngIf="playlist" [itemGroups]="playlist"
                [highlightItem]="playlistHighlightItem" [disableHighlightItem]="false"
                [initiallyOpenItemGroupIndex]="currentModuleIndex" [showItemGroupProgress]="!!user || !!guest"
                (selected)="gotoLesson($event)">
              </multisite-playlist-accordion-text>
            </div>
          </div>
        </div>

        <div class="mt-5 mx-3">
          <p class="h4 font-weight-bold mb-3">{{t('content.contributors')}}</p>
          <frontend-person-card-testimonial-type-two *ngFor="let presenter of presenters" class="my-2"
            [imgUrl]="presenter.user.picture ? presenter.user.picture : 'https://res.cloudinary.com/cebt/image/upload/w_40,h_40/v1616972586/users/avatars/default-avatar.gif'"
            [name]="presenter.user.pre_honorific+' '+presenter.user.fname+' '+presenter.user.lname+'
                '+presenter.user.post_honorific" [subtitle]="presenter.user.job_title" [bodyText]="presenter.user.bio">
          </frontend-person-card-testimonial-type-two>
        </div>
      }

    </div>

  </div>

</main>