import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, AvailableLanguage, BreadcrumbService, Contributor, CultureService, Experience, Guest, LanguageService, LessonExtraLite, MediaService, MetaText, PlaylistService, PlaylistTextItem, PlaylistTextItemGroup, TopicService, User, UserExtraLite, WindowService } from '@frontend/common';
import { ActivityLogService, Button } from '@frontend/core';
import { CommentsService, LinksService, PaginatedComments } from '@frontend/shared';
import { Subscription, combineLatest, forkJoin, of } from 'rxjs';
import { PageTitleService } from '../../navigation/title/title.service';
import { DataProcessingService } from '../../utilities/data-processing.service';
import { CourseModule } from '../course-module.model';
import { Course } from '../course.model';
import { CourseModulesByCourse, CourseService } from '../course.service';
import { Progressable } from '../../tracking';
import { ActivityLogParams, TrackingService } from '../../tracking/tracking.service';
import { AccreditationService } from '../../accreditations/accreditation.service';
import { Accreditation } from '../../accreditations/accreditation.model';

@Component({
  selector: 'course-home-type-one',
  templateUrl: './course-home.component.html',
  styleUrls: ['./course-home.component.scss']
})
export class CourseHomeComponent implements OnInit, OnDestroy {

  loadingObject: {[key:string]:boolean} = {}; // .topic .course .personalisedData
  course : Course;
  courseDate = signal<Date>(undefined);
  courseDateLabelTranslationKey = signal<string>(undefined);
  courseModules: CourseModulesByCourse;
  lessonCount: number;
  courseDurationConverted: number;
  presenters: Contributor[];
  comment : Comment;
  private subscriptions: Subscription[] = [];
  clearCommentForm: boolean = false;
  courseProgressValue: number;
  user : User = null;
  guest : Guest = null;
  interculturalistRoleAlertComments: boolean = false;
  ratingMax: number;
  roundedRating = signal<number>(undefined);
  error = null;
  loadingNewGuestError: string;
  paginatedComments : PaginatedComments;
  nextLesson: LessonExtraLite;
  started: boolean; // has the current user started the course already
  activeLanguageObject : AvailableLanguage;
  ctaButton = signal<Button>(undefined);
  courseModulesByCourse : CourseModulesByCourse; // TODO -remove if possible
  playlist : PlaylistTextItemGroup[];
  playlistHighlightItem : PlaylistTextItem;
  currentModuleIndex : number; // so the playlist is initially open at the right module
  thumbnailTransformations: string = 'ar_16:9,w_700/';
  cloudinary_base_url : string;
  accreditationsRequiringThisCourse : Accreditation[];
  accreditationsRequiringThisCourseAsMetaText : MetaText[];

  constructor(
    private courseService : CourseService,
    private accreditationService : AccreditationService,
    private linksService: LinksService,
    private commentsService: CommentsService,
    private route : ActivatedRoute,
    private router : Router,
    private authService : AuthService,
    private activityLogService : ActivityLogService,
    private pageTitleService : PageTitleService,
    private dataProcessingService : DataProcessingService,
    private trackingService: TrackingService,
    private mediaService : MediaService,
    private languageService : LanguageService,
    private breadcrumbService : BreadcrumbService,
    private topicService : TopicService,
    private windowService : WindowService,
    private cultureService : CultureService,
    private playlistService : PlaylistService,
  ) {
    this.cloudinary_base_url = this.mediaService.cloudinary_base_url;

  }

  isAnythingLoading(){
    for (let something in this.loadingObject){
      if (this.loadingObject[something]){
        return true;
      }
    }
    return false;
  }

  getFallbackFlagUrl (){
    return this.mediaService.fallback_flag_round_url;
  }

  goToLogin(){
    this.authService.setRouteForRedirectAfterAuth([window.location.pathname]);
    this.router.navigate(['/login']);
  }

  hasRole (roleName:string){
    return this.authService.checkRole(roleName); // for example "Admin"
  }

  formatSupervisors (supervisors : UserExtraLite[]){
    if (!supervisors?.length){return [];};
    return supervisors.map(user => {
      user = Object.assign(user,{picture:this.mediaService.insertCloudinaryTransformationsIntoMediaUrl(user.picture,'w_24,h_24,c_thumb,g_face/')});
      return user;
    })
  }
  generateContributors(supervisors:UserExtraLite[]){
    return supervisors.map(supervisor => {
      return {
        id: supervisor.id,
        category: 'supervisor',
        type: 'primary',
        weight: 0,
        user: supervisor,
        created_at: null
      }
    });
  }

  getAccreditations(topicSlug:string, courseSlug:string){
    this.loadingObject.accreditations = true;
    const accreditationsSub = this.accreditationService.getAccreditations(topicSlug,false)
      .subscribe(
        response => {
          this.accreditationsRequiringThisCourse = response.filter(a=>a.courses?.map(c=>c.slug).includes(courseSlug));
          this.accreditationsRequiringThisCourseAsMetaText = this.accreditationsRequiringThisCourse.map((a)=>{
            return {id:a.id,text:a.name,icon:a.icon}}
          );
          this.loadingObject.accreditations = false;
        },
        error => {
          this.loadingObject.accreditations = false;
          this.error = error;
        }
      );
  }
  gotoAccreditation(accreditationAsMetaText:MetaText){
    const accreditation = this.accreditationsRequiringThisCourse.find(a=>a.id === accreditationAsMetaText.id);
    if(accreditation){
      this.router.navigate(['../../accreditations/'+accreditation.slug], {relativeTo: this.route});
    }

  }

  makeGuest(){
    this.loadingObject.new_guest = true;
      const makingGuestSub = this.authService.makeGuest(null,null,null)
        .subscribe((response)=>{
          this.loadingObject.new_guest = false;
        }, error => {
          this.loadingObject.new_guest = false;
          this.loadingNewGuestError = error;
        });
      this.subscriptions.push(makingGuestSub);
  }

  onCtaButtonClicked(){
    if(!this.nextLesson){
      return;
    }
    this.router.navigate([this.nextLesson?.slug], { relativeTo: this.route });
  }
  generateCtaButton() : Button{

    const button : Button = {
      classes:'btn btn-primary w-100 w-lg-100 btn-lg',
      iconClasses: 'fa-solid fa-arrow-right',
      labelTranslationKey:this.started ? 'courses.continue_course' : 'courses.join_course',
      type:'start'
    };
    return button;
  }

  gotoLesson(item:PlaylistTextItem){
    this.router.navigate([item.identifier_string], { relativeTo: this.route });
  }

  roundRating (num: number){
    return num ? Math.round(num) : 0;
  }


  makePlaylist(courseModules : CourseModulesByCourse, courseExperiences : Experience[], lessonToHighlight : LessonExtraLite){

    const lessonExperiences = this.courseService.getLessonExperiencesFromCourseExperiences(courseExperiences,['consumed','completed'])
    const playlist = this.courseService.convertModulesToPlaylistText(courseModules.modules,lessonExperiences);
    let playlistStatusData = this.playlistService.findPlaylistItem(null,lessonToHighlight.slug, playlist);
    this.playlist = playlist;
    this.playlistHighlightItem = playlistStatusData.item;
    this.currentModuleIndex = playlistStatusData.itemGroupIndex;
  }

  convertCourseDuration (duration: number){
    let converted : number = 0;
    if (duration){
      converted = Number(this.dataProcessingService.convertMillisecondsToMinutes(duration, 59000));
    };
    return converted;
  }

  formatContributors (contributors : Contributor[]){
    if (!contributors?.length){return [];};
    return contributors.map(c => {
      c.user = Object.assign(c.user,{picture:this.mediaService.insertCloudinaryTransformationsIntoMediaUrl(c.user.picture,'w_24,h_24,c_thumb,g_face/')});
      return c;
    })
  }

  // getCultures(freshFromServer:boolean){ // This code is OK. It's an alternative approach.
  //   const cultureSubscription = this.cultureService.getCultures ('geographic','national',freshFromServer).subscribe(cultures => {
  //     this.courseService.insertCulturesIntoCachedCourses(cultures);
  //   });
  //   this.subscriptions.push(cultureSubscription);
  // }
  // getTopics(freshFromServer:boolean){ // This code is OK. It's an alternative approach.
  //   const topicSubscription = this.topicService.getTopics (null,null,freshFromServer).subscribe(topics => {
  //     this.courseService.insertTopicsIntoCachedCourses(topics);
  //     this.course = this.courseService.insertTopicsIntoCourse(this.course, topics);
  //   })
  //   this.subscriptions.push(topicSubscription);
  // }

  getDataFromServices(freshFromServer: boolean) {
    this.loadingObject.topicsAndCultures = true;

    const culturesObservable$ = this.cultureService.getCultures('geographic','national',freshFromServer);
    const topicsObservable$ = this.topicService.getTopics (null,null,freshFromServer);

    const joinedSubscription = forkJoin({
      cultures :culturesObservable$,
      topics :topicsObservable$,
    })
    .subscribe({ 
      next : (result) => {
          this.loadingObject.topicsAndCultures = false;

          this.courseService.insertCulturesIntoCachedCourses(result.cultures); // the service will ignore if it's already been done
          this.courseService.insertTopicsIntoCachedCourses(result.topics); // the service will ignore if it's already been done

          // This line should be unnecessary because the course should have updated cultures & topics by reference
          // this.course = this.courseService.getCachedCourse(slug);
    
    
          // We are not converting topics and cultures to tags, but here's how to do it
          // const culturesAsTags = this.cultureService.convertCulturesToTags(result.cultures,false);
          // const topicsAsTags = this.topicService.convertTopicsToTags(result.topics,false);
          // const cultureAndTopicTags = culturesAsTags.concat(topicsAsTags);
          // this.newCourseTagsArray = [...this.course.tags ?? [],...cultureAndTopicTags];
          
          if(this.route.snapshot.params['topic']){
            this.getTopic(this.route.snapshot.params['topic'],freshFromServer);
          }
      },
      error: (error) => {
        this.loadingObject.topicsAndCultures = false;

        console.error('Error loading topics and cultures:', error);
        // Handle the error appropriately, e.g., display an error message to the user
      },
      complete : () => {
        console.log('Completed loading topics and cultures');

      },
    });

    this.subscriptions.push(joinedSubscription);
  }

  getUserAndGuest(courseSlug : string, freshFromServer: boolean){
    this.loadingObject.auth = true;
   const userAndGuestCombinedSubscription = combineLatest([this.authService.user, this.authService.guest]).subscribe(([user, guest]) => {
      this.loadingObject.auth = false;
      // Code to run when both user and guest have returned a response or when in future either user or guest emits a new value
      if (user || guest) {
        this.user = user;
        this.guest = guest;
      }
      if (this.guest && !guest) { // old guest has been deleted
        this.guest = guest;
      }
      this.joinContent(courseSlug,freshFromServer);
    }, error => {
      this.loadingObject.auth = false;
      this.error = error;
    });
    this.subscriptions.push(userAndGuestCombinedSubscription);
  }

  joinContent(courseSlug : string, freshFromServer: boolean){
    this.loadingObject.content = true;

    const relatedExperiencesObservable = this.user || this.guest ? this.courseService.getRelatedExperiences (this.course.slug) : of([]);
    const courseModulesObservable = this.courseService.getCourseModules (courseSlug,freshFromServer);

    const joinedObservable = forkJoin({
      relatedExperiences :relatedExperiencesObservable,
      courseModules :courseModulesObservable,
    })
    .subscribe({ 
      next : (result) => {
          this.loadingObject.content = false;
          this.courseModules = result.courseModules;
          let nextLesson =  this.courseService.getFirstLessonInCourse(result.courseModules);
          this.nextLesson = nextLesson;
          if ((this.user || this.guest) && result.relatedExperiences.length){
            nextLesson =  this.courseService.getNextLessonNotYetExperiencedInCourse(result.courseModules,result.relatedExperiences);
          }
          if(nextLesson){
            this.nextLesson = nextLesson;
          }
          this.makePlaylist(result.courseModules, result.relatedExperiences, this.nextLesson);
          this.started = this.courseService.getCourseExperience(this.course.id,result.relatedExperiences,['started'],false);
          this.ctaButton.set(this.generateCtaButton());
          this.lessonCount = result.courseModules.modules.reduce((carry,current)=>{return carry + current.lessons?.length},0);
          this.courseDurationConverted = this.convertCourseDuration(result.courseModules.modules.reduce((carry,current)=>{return carry + current.duration},0));
          this.getCourseProgress (courseSlug); 

          if((this.course?.cultureSlugs?.length && !this.course.cultures) || (this.course?.topicSlugs?.length && !this.course.topics)){

            this.getDataFromServices(freshFromServer);
          
          } else {

            if(this.route.snapshot.params['topic']){
              this.getTopic(this.route.snapshot.params['topic'],freshFromServer);
            }

          }
          this.getLinks();
          this.getComments(1, false);
          this.checkForCachedComment();
      },
      error: (error) => {
        this.loadingObject.content = false;

        console.error('An error occurred:', error);
        // Handle the error appropriately, e.g., display an error message to the user
      },
      complete : () => {
        console.log('All observables completed!');

      },
    });

   
  }

  getCourse (slug:string, freshFromBackend: boolean){
    this.loadingObject.course = true;
    this.error = null;
    slug = slug ? slug : this.route.snapshot.params['course'];
    let activityLogParams : ActivityLogParams = {
      'model':'course',
      'identifier' : slug,
      'identifier_type' : 'slug',
      'activity_type' : 'viewed',
      'properties' : null
    };
    const courseSubscription = this.courseService.getCourse (slug, freshFromBackend)
      .subscribe(
        response => {
          if (response){
            this.course = response;
            this.presenters = this.formatContributors(response.presenters);
            this.roundedRating.set(this.roundRating(response.rating));
            if(response.published_at >= response.updated_at){
              this.courseDate.set(new Date(response.published_at));
              this.courseDateLabelTranslationKey.set('common.published');
            } else {
              this.courseDate.set(new Date(response.updated_at));
              this.courseDateLabelTranslationKey.set('common.updated');
            }
            this.activityLogService.log(activityLogParams);
            this.pageTitleService.setTitle(this.course.name);
            this.getUserAndGuest(slug,freshFromBackend);
          }
          this.loadingObject.course = false;
        },
        error => {
          this.error = error;  
          this.loadingObject.course = false;
        }
      );
    this.subscriptions.push(courseSubscription);
  };

  getLinks (){
    this.loadingObject.links = true;
    const linksSub = this.linksService.getLinks('course',this.route.snapshot.params['course'])
      .subscribe(
        response => {
          this.course.links = response;
          this.loadingObject.links = false;
        },
        error => {
          this.loadingObject.links = false;
          this.error = error;
        }
      );
    this.subscriptions.push(linksSub);
  };

  getComments (page, freshFromServer){
    this.loadingObject.comments = true;
    const commentsSub = this.commentsService.getComments('course',this.route.snapshot.params['course'],page, false, false,10,'created_at','desc',freshFromServer)
      .subscribe(
        response => {
          this.paginatedComments = response;
          debugger; // Comments are currently missing from the template
          this.loadingObject.comments = false;
        },
        error => {
          this.error = error;
          this.loadingObject.comments = false;
        }
      );
    this.subscriptions.push(commentsSub);
  };

  postComment (message,course_id){ // TODO, probably remove this feature; probably will not allow posting of comments on this page; allow on completion of course instead
    course_id = course_id ? course_id : this.course.id;
    if (!this.user){
      this.commentsService.setCachedPostingOfCommentForAfterAuthentication('course',course_id,message); // TODO - probably irrelevant because you should not be commenting on a course if you have not registered for it
      this.authService.setRouteForRedirectAfterAuth([this.router.url]);
      this.router.navigate(['/login']);
      return;
    }
    this.loadingObject.comment = true;
    const postCommentSub = this.commentsService.postAComment('course',course_id,message)
      .subscribe((result)=>{
        this.loadingObject.comment = false;
        this.clearCommentForm = !this.clearCommentForm;
        if (!this.authService.checkRole("Interculturalist")){
          this.interculturalistRoleAlertComments = true;
        }
        let current_page = this.paginatedComments?.meta.current_page ? this.paginatedComments?.meta.current_page : 1;
        this.getComments(current_page, true);
      }, error => {
        this.loadingObject.comment = false;
        this.error = error;
      });
    this.subscriptions.push(postCommentSub);
  };

  checkForCachedComment (){
    let cachedComment = this.commentsService.getCachedPostingOfCommentForAfterAuthentication();
    if (cachedComment && cachedComment.modelNameSingular === 'course'){
      this.postComment(cachedComment.message,cachedComment.identifier);
    };
  };

  findCurrentCourseProgress (courseSlug: string, progressables : Progressable[] = []){
    const backendClass = this.dataProcessingService.convertModelNameToBackendClass('course');
    let result = progressables.find(p=>p.type === backendClass && p.slug === courseSlug)?.progress;
    return result === 0 || result > 0 ? Math.round(result) : null;
  }

  getCourseProgress (courseSlug : string){
    const progressablesSubscription = this.trackingService.progressables.subscribe( progressables => {
      this.courseProgressValue = this.findCurrentCourseProgress(courseSlug, progressables);
    });
    this.subscriptions.push(progressablesSubscription);
  }

  getTopic(topicSlug : string, freshFromBackend : boolean) {
    this.loadingObject.topic = true;
    const topicSubscription = this.topicService.getTopic(topicSlug,freshFromBackend).subscribe((topic) => {
      this.breadcrumbService.setBreadcrumbFragment({urlFragment:'topic',fragmentName:topic.name});
      this.loadingObject.topic = false;
      },
      error => {
        this.loadingObject.topic = false;
        this.error = error;
      });
    this.subscriptions.push(topicSubscription);
  }

  ngOnInit(): void {

    this.windowService.goToTop();    
    
    const courseSlug : string = this.route.snapshot.params['course'];
    const topicSlug : string = this.route.snapshot.params['topic'];
    // const cultureSlug : string = this.route.snapshot.params['culture']; // Todo handle culture in the URL

    this.getCourse (this.route.snapshot.params['course'],false);

    if(courseSlug && topicSlug){
      this.getAccreditations (topicSlug,courseSlug);
    }

    this.activeLanguageObject = this.languageService.activeLanguageObjectSynchronously;
    const activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
      // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
      if (newActiveLanguage?.languageKey !== this.activeLanguageObject.languageKey){
        this.activeLanguageObject = newActiveLanguage;
        this.getCourse (this.route.snapshot.params['course'],true);
      }
    });
    this.subscriptions.push(activeLanguageSubscription);

  }

  ngOnDestroy () {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
