<button class="btn btn-link-gray active border-0 d-flex gap-1" [disabled]="disabled()" (click)="doToggle()">
    <div class="switch" [ngClass]="{'active': active()}">
      <div class="switch-handle">
        <span class="option-a">
            @if(optionAiconClasses()){
                <i [class]="optionAiconClasses()"></i>
            }
        </span>
        <span class="option-b">
            @if(optionBiconClasses()){
                <i [class]="optionBiconClasses()"></i>
            }
        </span>
      </div>
    </div>
</button>