import { Component, input } from '@angular/core';
import { Icon } from '../icon.model';
import { MediaService } from '@frontend/common';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'multisite-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss'
})
export class IconComponent {

  icon = input<Icon | undefined>(undefined);
  imageTransformations = input<string>('w_32,c_fill,ar_1:1,r_max,f_auto/');
  fallbackIconClasses = input<string | undefined>(undefined); // 'fa-solid fa-star'
  iconClasses = input<string | undefined>(undefined); // 'mr-2'
  imgClasses = input<string | undefined>(undefined); // 'mr-2'
  
  constructor(private mediaService : MediaService) {}

  getUrl(icon: Icon) : string {

    return this.mediaService.getIconUrlFromIconIdentifier2(icon.identifier_2,this.imageTransformations());

   }

}
