export class FieldRequirements {

    characters_min_warning?: number;
    characters_max_warning?: number;
    characters_min_required?: number;
    characters_max_required?: number;
    words_min_warning?: number;
    words_max_warning?: number;
    words_min_required?: number;
    words_max_required?: number;
    required?: boolean;
  }