import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService, LanguageInterceptorService, LanguageService } from '@frontend/common';
import { BrandDisplayService, JwtInterceptor } from '@frontend/common';
import { ErrorInterceptor } from '@frontend/common';

import { EnvironmentModule } from '@frontend/environment';
import { NavigationModule } from '@frontend/common';
import { BrandingModule } from '@frontend/common';
import { FooterModule } from '@frontend/common';
import { AuthModule } from '@frontend/common';

import { TranslocoService, TRANSLOCO_LOADER } from '@jsverse/transloco';
import { TranslocoCustomLoader, TranslocoRootModule } from "@frontend/angular-translations";


// MDB Imports
// import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
// import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
// import { MdbLightboxModule } from 'mdb-angular-ui-kit/lightbox';



import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeCiProfileModule } from './home/home-ciprofile.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    EnvironmentModule.withConfig({
      appDomain: environment.appDomain,
      appKey: environment.appKey,
      appUrl: environment.appUrl,
      name: environment.name,
      production: environment.production,
    }),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslocoRootModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    NavigationModule,
    AuthModule,
    FooterModule,
    BrandingModule,
    HomeCiProfileModule,
    // MDB Imports
    // MdbCollapseModule,
    // MdbCarouselModule,
    // MdbLightboxModule,
  ],
  providers: [
    { provide: Window, useValue: window },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true,},
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptorService, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoCustomLoader,deps: [HttpClient]},
    { provide: APP_INITIALIZER, useFactory: (transloco : TranslocoService, languageService: LanguageService) =>
      () => {
        const activeLanguageObject = languageService.activeLanguageObjectSynchronously;
        const lang = activeLanguageObject?.languageKey ? activeLanguageObject.languageKey: transloco.getDefaultLang();
        transloco.setActiveLang(lang);
        return transloco.load(lang).toPromise();
      },
      deps: [TranslocoService,LanguageService,Location],
      multi: true
    },
    { provide: APP_INITIALIZER, useFactory: (brandDisplayService: BrandDisplayService) =>
        () => {
          let subdomain;
          const hostSplit = window.location.host.split('.'); // ['megacorp','ciprofile','com'] or ['mySubdomain', 'localhost:8004']
          if ((hostSplit.length>2) || (hostSplit.length>1 && hostSplit.find(part=>part.indexOf('localhost')>-1))){
            subdomain = window.location.host.split('.')[0];
          }
          brandDisplayService.setSubdomain(subdomain);
          return brandDisplayService.getBranding()
        },
          deps: [BrandDisplayService],
          multi: true}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
