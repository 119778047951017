 import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AuthGuard } from '@frontend/common';
import { AuthenticationOverviewComponent, VerifyEmailLandingPageComponent } from '@frontend/common';

import { HomeComponent } from './home/home.component';


const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  { path: 'login', component: AuthenticationOverviewComponent,  data: { appKey: 'ciprofile'/*used in the component until we get environment variables working*/} },
  { path: 'register', component: AuthenticationOverviewComponent,  data: { domain: 'ciprofile.com', appKey: 'ciprofile'/*used... as above'*/} },
  { path: 'forgot-password', component: AuthenticationOverviewComponent,  data: { domain: 'ciprofile.com', appKey: 'ciprofile'/*used... as above'*/}  },
  { path: 'email/verify/:user_id/:hash', component: VerifyEmailLandingPageComponent,  data: { appKey: 'ciprofile'/*used... as above'*/}  },
  { path: 'password/reset/:hash/:email', component: AuthenticationOverviewComponent,  data: { appKey: 'ciprofile'/*used... as above'*/, label: 'Reset password', translationKey: 'authentication.new_password'}  },
  
  { path: 'snapshots', canActivateChild: [() => import('@frontend/common').then(m => m.HasSubdomainGuard)], canActivate: [() => import('@frontend/common').then(m => m.AuthOrGuestGuard)], loadChildren: () => import('./app-snapshots.module').then(m => m.CiProfileSnapshotsModule), data: { label: 'Snapshots', translationKey: 'survey.snapshots', labelSource: null}},

  { path: 'community/search', canActivateChild: [() => import('@frontend/common').then(m => m.HasNoSubdomainGuard)], loadChildren: () => import('@frontend/common').then(m => m.CommunitySearchModule), data: { label: 'Community', translationKey: 'navigation.community', labelSource: null} },

  { path: 'community', redirectTo: 'community/search' },

  { path: 'hubs', canActivate: [() => import('@frontend/common').then(m => m.AuthGuard)], loadChildren: () => import('@frontend/common').then(m => m.HubsModule), data: { label: 'Hubs', translationKey: 'navigation.hubs', labelSource: null} },

  { path: 'account', canActivate: [() => import('@frontend/common').then(m => m.AuthGuard)], loadChildren: () => import('@frontend/common').then(m => m.UserAccountModule), data: { label: 'Account', translationKey: 'navigation.account', labelSource: null} },
  
  { path: 'connections', canActivate: [() => import('@frontend/common').then(m => m.AuthGuard)], loadChildren: () => import('@frontend/common').then(m => m.UserConnectionsModule), data: { label: 'Connections', translationKey: 'navigation.connections', labelSource: null} },
  
  { path: 'design', canActivate: [() => import('@frontend/common').then(m => m.AuthGuard)], loadChildren: () => import('@frontend/common/design').then(m => m.DesignModule), data: { label: 'Design', translationKey: 'navigation.design', labelSource: null} },

  { path: 'demo', redirectTo: 'coming-soon' },
  {
    path: 'coming-soon',
    loadChildren: () =>
    import('@frontend/shared').then((m) => m.ComingSoonModule),
  },
  {
    path: 'open-science',
    loadChildren: () =>
    import('@frontend/shared').then((m) => m.OpenScienceModule),
  },
  { path: 'help/faqs', loadChildren: () => import('@frontend/common').then(m => m.FaqsModule), data: { label: 'FAQs', translationKey: 'content.faqs', labelSource: null} },


  { path: 'openscience', redirectTo: 'open-science' },
  
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
