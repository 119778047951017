import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LickertProportionEditCardComponent } from './lickert-proportion-edit-card.component';
import { FormControlsModule } from '../../forms';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadersModule } from '../../loaders';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AvatarsModule, InlinePillPickerModule } from '@frontend/shared';

@NgModule({
    declarations: [LickertProportionEditCardComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormControlsModule,
        LoadersModule,
        AlertModule,
        TranslocoModule,
        CollapseModule,
        AvatarsModule,
        InlinePillPickerModule,
    ],
    exports: [LickertProportionEditCardComponent],
    providers: [
        { provide: TRANSLOCO_SCOPE, useValue: 'contributions' }
    ],
})
export class SurveyProportionEditCardModule { }