import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeEvent } from "@ckeditor/ckeditor5-angular";
import { EditorConfig } from '@ckeditor/ckeditor5-core';
/*
// we don't need to import these. They are built in during our custom build.
import { Markdown } from '@ckeditor/ckeditor5-markdown-gfm';
import { WordCount, WordCountConfig } from '@ckeditor/ckeditor5-word-count'
import { Essentials } from '@ckeditor/ckeditor5-essentials';
*/
import Editor from '../../../../build/ckeditor'; // libs/ckeditor/build/ckeditor

@Component({
  selector: 'multisite-form-control-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReactiveFormInputRichTextEditorComponent),
      multi: true
    }
  ]
})
export class ReactiveFormInputRichTextEditorComponent implements ControlValueAccessor, OnChanges {

  @Output() characters = new EventEmitter<number>();
  @Output() words = new EventEmitter<number>();
  @Output() contentChanged = new EventEmitter<string>();
  @Input() defaultEditorDataHtml = "<p>Please write about the <b>culture</b> here</p>";
  @Input() defaultEditorDataMarkdown = "Please write about the **culture** here";
  @Input() placeholder = "Write here";

  public Editor = Editor;
  public isDisabled = false;
  configSimple : EditorConfig & any; //EditorConfig; // RJF 15.3.2024: type of `any` is required here for the wordCount configuration object; the library's types are incorrect, but this wordCount config starts to work if we give it the `any` type here.
  // configReactiveForm : EditorConfig;
  editorDataHtml : string = this.defaultEditorDataHtml ?? "<p>Write about the <b>culture</b> here</p>";
  editorDataMarkdown : string = this.defaultEditorDataMarkdown ?? "Write about the **culture** here";
  // editorDataReactiveForm : string; // default content
  // toolbarSimple: {items:string[]};
  // toolbarReactiveForm: {items:string[]};

  constructor() {

    this.editorDataHtml = this.defaultEditorDataHtml ?? "<p>So write about the <b>culture</b> here</p>";
    this.editorDataMarkdown = this.defaultEditorDataMarkdown ?? "So write about the **culture** here";
    this.configSimple = {
      language: "de",
      toolbar: ["undo", "heading", "|", "bold", "italic","link", "blockquote", "bulletedList","indent", "numberedList", "|", "trackChanges"],
      placeholder : this.placeholder,
      plugins: [ 
        'BlockQuote', 'Bold', 'Essentials', 'Heading', 'Indent', 'Italic', 'Link', 'List', 'Markdown', 'Paragraph', 'PasteFromOffice', 'Undo', 'WordCount'
        // WordCount,
        /*Markdown*/ // This causes an error if we use a predefined build (ClassicEditor). Use a custom build instead https://ckeditor.com/ckeditor-5/online-builder/. The custom build can be based on the ClassicEditor template.
      ],
      wordCount: {
        onUpdate: (stats : any) => {
            console.log( `Characters: ${ stats.characters }\nWords: ${ stats.words }` ); // nicely strips out the markdown and HTML before counting
            this.characters.emit(stats.characters);
            this.words.emit(stats.words);
        },
      }
    };

    // this.editorDataReactiveForm = "<p>I am the <b>default</b> content for the reactive form editor</p>";
    // this.configReactiveForm = {
    //   language: "fr",
    //   toolbar: ["heading", "|", "bold", "italic","link", "blockquote", "bulletedList", "numberedList", "|", "trackChanges"],
    //   placeholder : 'I am the placeholder'
    // };
    // this.toolbarReactiveForm = {
    //   items: ["bold", "italic","link", "blockquote", "blockquote", "bulletedList", "numberedList", "|", "trackChanges"]
    // };

    // This is useful:
    // const builtinPluginsObject = Editor.builtinPlugins.map( plugin => plugin.pluginName );
    // console.log(builtinPluginsObject);
  }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};

  writeValue(value: string): void {
    if (value !== undefined) {
      this.editorDataMarkdown = value;
      // Update the editor content if the editor instance is available
      // const editorInstance = this.Editor.instances[0]; // Assuming a single instance
      // if (editorInstance) {
      //   editorInstance.setData(value);
      // }
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }


  public onEditorChange({ editor }: ChangeEvent, editorType: string) {
    const data = editor.data.get();
    this.contentChanged.emit(data);
    this.onChange(data);
    // console.log('editor content: ',data);
  }
  public onEditorBlur(): void {
    if (this.onTouched) {
      this.onTouched();
    }
    // console.log('editor blurred');
  }

  ngOnChanges(changesObject: SimpleChanges) {
    if(changesObject['placeholder']?.currentValue && this.configSimple){
      this.configSimple['placeholder'] = changesObject['placeholder'].currentValue;
      console.log('placeholder changed to: ',changesObject['placeholder'].currentValue);
    }
  }

}
