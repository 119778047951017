<div class="comment">
  <div class="img-holder">
    <img src="{{poster().picture || defaultAvatar}}" [alt]="poster().fname+' '+poster().lname">
  </div>
  <div class="content-holder">
      <p class="name">{{poster().fname+' '+poster().lname}}</p>
          <div class="d-flex flex-column">
            <textarea
            class="form-control md-textarea pt-0"
            id="commentTextArea"
            [rows]="rows()"
            name="message"
            [disabled]="loading() || loadingTranslation() || disabled()"
            [(ngModel)]="message"
            placeholder="{{'common.write_something_here' | transloco}}">
          </textarea>
             <div class="button-group mt-2 align-self-end">
              @for(pair of translatablePairs(); track pair){
                <button class="btn btn-secondary btn-sm" (click)="sendForTranslation(pair)" [disabled]="!message || loading() || loadingTranslation() || disabled()">
                  <span class="mr-1" *transloco="let t">{{t('content_management.translate')}}</span>
                  <span>{{pair.source_lang.name}}</span>
                  <span class="fa-solid fa-arrow-right mx-2"></span>
                  <span>{{pair.target_lang.name}}</span>

                </button>
              }
                <button
                (click)="doPostComment()"
                class="btn btn-primary btn-sm"
                [disabled]="!message || loading() || loadingTranslation() || disabled()">
                <!-- TODO - investigate why importing the LoadersModule causes runtime errors in the browser
                <multisite-loader-globe-animation-detail *ngIf="loading()" width="24" height="24"
              class="d-inline-block align-middle"></multisite-loader-globe-animation-detail> -->
                @if(loading()){
                  <span>
                    <span class="fa-solid fa-arrow-rotate-right fa-spin"></span>
                  </span>
                }
                <span *transloco="let t">{{t('common.post_a_comment')}}</span>
              </button>
             </div>
          </div>
      <hr>
  </div>
</div>