  <div class="score-highlights" [style]="'--color: '+colourRGB()">
      <span class="icon-holder">
          <i [class]="iconClasses()"></i>
      </span>
      <div class="content">
          @if(titleText() || titleTranslationKey()){
            <p class="title {{ titleClasses() }}" *transloco="let t">
              {{ titleTranslationKey() ? t(titleTranslationKey()) : titleText() }}
            </p>
          }
          <span class="score">
              <span class="numerator">{{score()}}</span>
              @if(denominator()){
                <span class="denominator">/&nbsp;{{denominator()}}</span>
              }
          </span>
      </div>
  </div>