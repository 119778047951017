import { Component, input, output } from '@angular/core';
import { TranslatablePair, User } from '@frontend/common';
import { PaginatedComments } from '../comments-paginated.model';

@Component({
  selector: 'comments-post-display-pagination-all-in-one',
  templateUrl: './comments-post-display-pagination-all-in-one.component.html',
  styleUrls: ['./comments-post-display-pagination-all-in-one.component.scss']
})
export class CommentsPostDisplayPaginationAllInOneComponent {

  user = input<User>();
  rows = input<number | undefined>(undefined);
  loadingComments = input<boolean>(false);
  disabled = input<boolean>(false);
  clearCommentForm = input<boolean | undefined>(undefined); // if this changes, clear the comment form, no matter true or false
  postComment = output<string>();
  getComments = output<number>();
  paginatedComments =input<PaginatedComments | undefined>(undefined);
  titleText = input<string | undefined>(undefined);
  titleClasses = input<string>('h3 mb-3');
  titleTranslationKey = input<string | undefined>(undefined);
  titleTranslationScope = input<string | undefined>(undefined);
  translatablePairs = input<TranslatablePair[]>([]); // Allow the user to translate FR->EN, for example

  constructor() { }

  doGetComments(whichPage){
    this.getComments.emit(whichPage);
  }

  doPostComment (message){
    this.postComment.emit(message);
  }
}
