import { Component, computed, input, output, signal } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { GalleryService, MediaService } from '../../media';
import { Media } from '@frontend/core';
import { LoadersModule } from '../../../loaders';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'multisite-randomised-message-card',
  standalone: true,
  imports: [CommonModule,TranslocoModule,LoadersModule],
  templateUrl: './randomised-message-card.component.html',
  styleUrl: './randomised-message-card.component.scss'
})
export class RandomisedMessageCardComponent {

  cardWidth = input<string | undefined>('18rem');

  titleText = input<string | undefined>();
  titleTranslationKey = input<string | undefined>();
  titleTranslationScope = input<string | undefined>();
  titleClasses = input<string>('card-title font-weight-bold');
  randomTitleNumberMax = input<number | undefined>(undefined); // If 15, will randomly add number between 1 and 15, for example 'contributions.salutation.thanks.12'
  titleTranslationKeyRandomised = computed(() => {
    if (this.randomTitleNumberMax() !== undefined) {
      return `${this.titleTranslationKey()}.${Math.floor(Math.random() * this.randomTitleNumberMax()) + 1}`;
    }
    return this.titleTranslationKey();
  });

  descriptionText = input<string | undefined>(undefined);
  descriptionTranslationKey = input<string | undefined>(undefined);
  descriptionTranslationScope = input<string | undefined>(undefined);
  descriptionClasses = input<string | undefined>(undefined);
  randomDescriptionNumberMax = input<number | undefined>(undefined); // If 15, will randomly add number between 1 and 15, for example 'contributions.praise.intercultural_work.6'
  descriptionTranslationKeyRandomised = computed(() => {
    if (this.randomDescriptionNumberMax() !== undefined) {
      return `${this.descriptionTranslationKey()}.${Math.floor(Math.random() * this.randomDescriptionNumberMax()) + 1}`;
    }
    return this.descriptionTranslationKey();
  });

  captionText = input<string | undefined>(undefined);
  captionTranslationKey = input<string | undefined>(undefined);
  captionTranslationScope = input<string | undefined>(undefined);
  captionClasses = input<string>('small text-muted');
  randomCaptionNumberMax = input<number | undefined>(undefined); // If 15, will randomly add number between 1 and 15, for example 'contributions.captions.congrats.14'
  captionTranslationKeyRandomised = computed(() => {
    if (this.randomCaptionNumberMax() !== undefined) {
      return `${this.captionTranslationKey()}.${Math.floor(Math.random() * this.randomCaptionNumberMax()) + 1}`;
    }
    return this.captionTranslationKey();
  });

  buttonLabelText = input<string | undefined>(undefined); // 'Paris'
  buttonLabelTranslationKey = input<string | undefined>(undefined); // 'common.hq'
  buttonLabelTranslationScope = input<string | undefined>(undefined); // 'shop'
  buttonClicked = output<void>();

  gallery_category = input.required<string>();
  gallery_type = input.required<string>();
  gallery_tag_query_params = input<URLSearchParams | undefined>();
  gallery = signal<Media[]>([]);
  loading = signal<boolean>(false);

  imageTransformations = input<string >('w_275,c_fill,ar_16:9/');

  randomImageUrl = computed(() => {
    if (this.gallery().length > 0) {
      const randomIndex = Math.floor(Math.random() * this.gallery().length);
      const mediaItem : Media = this.gallery()[randomIndex];
      const url = mediaItem.file_url;
      return this.mediaService.insertCloudinaryTransformationsIntoMediaUrl(url,this.imageTransformations());
    }
  });

  constructor(private galleryService: GalleryService, private mediaService: MediaService) {
  }
  onButtonClicked() {
    this.buttonClicked.emit();
  }

  ngOnInit() {
    this.loading.set(true);
    this.galleryService.getGallery(this.gallery_category(), this.gallery_type(), this.gallery_tag_query_params()).subscribe(gallery => {
      this.gallery.set(gallery.data);
      this.loading.set(true);
    },
    error => this.loading.set(false)
    );
  }

}
