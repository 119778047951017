import { Component, Input, OnChanges, computed, input, signal } from '@angular/core';
import { Icon } from '@frontend/shared';
import { MediaService } from '../../content';

@Component({
  selector: 'multisite-difference-scale',
  templateUrl: './difference-scale.component.html',
  styleUrls: ['./difference-scale.component.scss']
})
export class DifferenceScaleComponent implements OnChanges {

  @Input() titleTranslationKey : string; // 'ciprofile.get_access_title'
  @Input() titleText : string; // 'How to get access'
  @Input() pole1TranslationKey : string; // 'cultural_difference.silence'
  @Input() pole1Text : string; // 'Silence'
  @Input() pole2TranslationKey : string; // 'cultural_difference.talk'
  @Input() pole2Text : string; // 'Talk'
  @Input() range : number; // 23 (width as a percentage of the total scale width)
  @Input() rangeCentre : number = 50; // 45 (percentile position on the scale where to centre the range)
  @Input() iconPosition : number; // 45 (percentile position on the scale where to centre the icon)
  @Input() iconIdentifier1 : string; // fontawesome needs change-detection to be trigger, so this is our trigger
  icon = input<Icon | undefined>(undefined); // 'fa-solid fa-right-left-large'
  iconIdentifier2Url = computed<string>(() => {
    return this.icon()?.identifier_2 ? this.mediaService.getIconUrlFromIconIdentifier2(this.icon().identifier_2,'w_32,c_fill,ar_1:1,r_max,f_auto/'):null;
  });
  fallbackIconClasses = input<string>('fa-solid fa-right-left-large'); // 'fa-solid fa-right-left-large'
  @Input() showRangeGrid : boolean; // false, shows the S,M,L narrow, wide, extra wide benchmarks
  abbreviationNarrow = input<string>('ci_profile_analysis.narrow_abbreviation');
  abbreviationWide = input<string>('ci_profile_analysis.wide_abbreviation');
  abbreviationExtraWide = input<string>('ci_profile_analysis.extra_wide_abbreviation');
  iconIsInstantiated = signal<boolean>(true);

  constructor(private mediaService : MediaService) { }

  calculateGridPosition (rangeCentre : number){
    let coreWidth = 50;
    let wideWidth = 10;
    let extraWideWidth = (100 - (coreWidth + (2 * wideWidth))) / 2;
    let grid = {
      left_extra_wide : {show : true, class : 'leftmost', width : extraWideWidth},
      left_wide : {show : true, class : '', width : wideWidth},
      core : {show : true, class : '', width : coreWidth},
      right_wide : {show : true, class : '', width : wideWidth},
      right_extra_wide : {show : true, class : '', width : extraWideWidth},
    }
    if (rangeCentre < (coreWidth/2)){
      grid.left_extra_wide.show = false;
      grid.left_wide.show = false;
      grid.core.width = rangeCentre + (coreWidth/2);
    } else if (rangeCentre < ((coreWidth/2) +wideWidth)){
      grid.left_extra_wide.show = false;
      grid.left_wide.width = rangeCentre - (coreWidth/2);
      if (grid.left_wide.width < 5){
        grid.left_wide.show = false;
      }
    } else if (rangeCentre < ((coreWidth/2) + wideWidth +extraWideWidth)){
      grid.left_extra_wide.width = rangeCentre - (coreWidth/2) - wideWidth;
      if (grid.left_extra_wide.width < 5){
        grid.left_extra_wide.show = false;
      }
    } else if ((100 - rangeCentre) < (coreWidth/2)){
      grid.right_extra_wide.show = false;
      grid.right_wide.show = false;
      grid.core.width = (100 - rangeCentre) + (coreWidth/2);
    } else if ((100 - rangeCentre) < ((coreWidth/2) +wideWidth)){
      grid.right_extra_wide.show = false;
      grid.right_wide.width = (100 - rangeCentre) - (coreWidth/2);
      if (grid.right_wide.width < 5){
        grid.right_wide.show = false;
      }
    } else if ((100 - rangeCentre) < ((coreWidth/2) + wideWidth +extraWideWidth)){
      grid.right_extra_wide.width = (100 - rangeCentre) - (coreWidth/2) - wideWidth;
      if (grid.right_extra_wide.width < 5){
        grid.right_extra_wide.show = false;
      }
    }
    if (rangeCentre > 50) {
      grid.left_extra_wide.width += (rangeCentre-50);
    }
    if (rangeCentre < 50) {
      grid.right_extra_wide.width += (50-rangeCentre);
    }
    if(!grid.left_extra_wide.show){
      grid.left_wide.class = 'leftmost';
    }
    if(!grid.left_wide.show){
      grid.core.class = 'leftmost';
    }
    return grid;
  }

  ngOnChanges(changesObject): void {
    if (changesObject.iconIdentifier1){
      this.iconIsInstantiated.set(false); // Fontawesome will not update the browser if we change the class with no interval
      setTimeout(() => {
        this.iconIsInstantiated.set(true);
      },25)
    }
  }

}
