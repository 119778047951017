<div class="d-flex flex-column gap-3">
  <div class="card card-gray-200 p-3 border-rounded border-0">
    <h5 class="mb-1">{{ difference().short_name }}</h5>
    <!-- <h6 class="mb-1">{{ difference().name }}</h6> -->
    <small class="text-muted">{{ delivery().task.description }}</small>
    <p class="mb-1">{{ difference().description }}</p>
  </div>

  <div class="editing-card">
    <div class="card">
      @if(deliverableForm){
      <form
        [formGroup]="deliverableForm"
        role="form"
        class="d-flex flex-column w-100"
        *transloco="let t"
      >
        <div class="card-body card-gray-200 p-3 my-4 border-rounded">
          <div class="form-group">

            @if(relatedBenchmarkClusters()?.length > 1) {
            <div class="p-3">
              <multisite-inline-pill-picker
                [items]="relatedBenchmarkClustersWithoutWrappers()"
                [selectedItemIdentifiers]="selectedBenchmarkClusterIds()"
                [selectedPropertyKey]="'id'"
                [collapsedPrefixTranslationKey]="null"
                [pillType]="'avatar'"
                (onSelect)="toggleSelectBenchmarkCluster($event)"
                (onToggleCollapse)="toggleCollapse('benchmarks')"
                [collapsed]="collapseObject().benchmarks"
                [collapseOnSelect]="true"
                [collapsedItemCount]="2"
              ></multisite-inline-pill-picker>
            </div>
            }

            <!-- <label for="value">CultureConnector difference</label> -->

            <label for="value" class="sr-only">CultureConnector difference</label>

            <multisite-form-control-lickert-question
              formControlName="value"
              [visualisation]="'bell_curve'"
              [anchorSetTexts]="ccoDifferenceAnchorSet()"
              [groupedBenchmarks]="culturesGroupedByVoteValue()"
              [min]="1"
              [max]="ccoDifferenceAnchorSet()?.length"
              class="my-4"
              [sliderThumbImageUrl]="getFlagUrlFromHash(culture().flag?.hash,'w_36,c_fill,ar_1:1,r_max,f_auto/')"
              [defaultUnsetValue]="-1"
            ></multisite-form-control-lickert-question>
          </div>
        </div>
        <div class="card-body card-gray-200 p-3 my-2 border-rounded">
          <div class="form-group">
            <div class="input-self">
              <div
                class="d-flex justify-content-between"
                (click)="toggleCollapse('public_comment')"
              >
                <label class="form-label h5 fw-bold">
                  {{ t('social.public_comment') }}
                </label>
                <a
                  class="text-nowrap link-primary-dark fw-bold d-flex align-items-center gap-2"
                  *transloco="let t"
                >
                  @if(collapseObject().public_comment){
                  <span>{{t('common.show_more')}} <i class="fa-solid fa-angle-left ml-1"></i></span>

                  } @else {
                  <span>{{t('common.show_less')}} <i class="fa-solid fa-angle-down ml-1"></i></span>

                  }
                </a>
              </div>

              <div
                class=""
                [collapse]="collapseObject().public_comment"
                [isAnimated]="true"
              >
                <p
                  class="mb-2 form-text"
                  *transloco="let t; scope : 'contributions'"
                >
                  <span
                    class="mr-1"
                    *transloco="let t"
                  >{{t('common.optional')}}</span>
                  <span
                    *transloco="let t; scope : 'contributions'">{{t('contributions.data_with_public_comment_help')}}</span>
                </p>

                @for(publicCommentControl of getTranslatableFormFieldControls('public_comment'); track
                publicCommentControl){
                @if(deliverableForm.get(publicCommentControl.key)){
                <div
                  class="input-group textarea-control mb-3"
                  [ngClass]="{
                          'ng-invalid':
                            deliverableForm.get(publicCommentControl.key)?.invalid &&
                            (deliverableForm.get(publicCommentControl.key)?.touched ||
                              deliverableForm.get(publicCommentControl.key)?.dirty),
                          'ng-valid':
                            deliverableForm.get(publicCommentControl.key)?.valid &&
                            deliverableForm.get(publicCommentControl.key)?.touched &&
                            deliverableForm.get(publicCommentControl.key)?.dirty
                        }"
                >
                  <span class="input-group-text stacked-form-control-header">
                    <span>
                      {{
                      publicCommentControl.type === 'source'
                      ? sourceLanguage()?.name
                      : targetLanguage()?.name
                      }}
                    </span>



                    @if(getSignalValue(publicCommentControl.type+'LanguageIsCharacterBased')){
                    <span>
                      <!-- DEBUGGING
                              r:{{translatableFieldRequirements()[publicCommentControl.key].words_max_required}} /// w:{{translatableFieldRequirements()[publicCommentControl.key].words_max_warning}} /// {{getMax100((editorCharacters[publicCommentControl.key]/translatableFieldRequirements()[publicCommentControl.key].characters_max_warning)*100)+'%'}} ////  -->
                      {{editorCharacters[publicCommentControl.key]}}
                    </span>


                    <div
                      class="progress"
                      [style]="'--color: var(--traffic-light-200); --progress-percent: '+getMax100((editorCharacters[publicCommentControl.key]/translatableFieldRequirements()[publicCommentControl.key]?.characters_max_warning)*100)+'%'"
                    >
                      <div class="progress-indicator bg-warning"></div>
                      <!-- The following code will apply traffic light colour styles -->
                      <!-- <div class="progress-indicator" [ngClass]="(editorCharacters[publicCommentControl.key] > translatableFieldRequirements()[publicCommentControl.key].characters_max_warning) || (editorCharacters[publicCommentControl.key] < translatableFieldRequirements()[publicCommentControl.key].characters_min_warning) ? 'traffic-light-1' : 'traffic-light-6'"></div> -->
                    </div>

                    } @else {

                    <!-- <span>
  
                              {{editorWords[publicCommentControl.key]}}
                            </span> -->


                    <!-- The following code will NOT vary the traffic light colour styles
                                <div class="progress"
                                [style]="'--color: var(--traffic-light-200); --progress-percent: '+getMax100((formControlCharacterCount(deliverableForm,publicCommentControl.key)/translatableFieldRequirements()[publicCommentControl.key]?.characters_max_warning)*100)+'%'"
                                > 
                                </div>
                              -->
                    <div class="progress-indicator bg-warning"></div>
                    <!-- The following code will apply traffic light colour styles -->
                    <div
                      class="progress-indicator"
                      [ngClass]="(formControlCharacterCount(deliverableForm,publicCommentControl.key) > translatableFieldRequirements()[publicCommentControl.key].characters_max_warning) || (formControlCharacterCount(deliverableForm,publicCommentControl.key) < translatableFieldRequirements()[publicCommentControl.key].characters_min_warning) ? 'traffic-light-1' : 'traffic-light-6'"
                    ></div>

                    }

                  </span>
                  <textarea
                    class="form-control"
                    [disabled]="isAnythingLoading()"
                    [formControlName]="publicCommentControl.key"
                    [placeholder]="publicCommentControl.type === 'target' && sourceLanguage() && allTargetLanguageFormControlsAreNull() && deliverableForm.get(publicCommentControl.key).disabled ? ('contributions.locked_awaiting_translation' | transloco) : ('content_management.write_text' | transloco)"
                  >
                      </textarea>

                  <div
                    *ngIf="
                            deliverableForm.get(publicCommentControl.key)?.errors
                              ?.maxlength &&
                            deliverableForm.get(publicCommentControl.key)?.dirty
                          "
                    class="invalid-feedback"
                  >
                    {{ t('common.too_long') }}
                  </div>
                  <!-- The following warning text is only needed if the field is translatable but not a required field -->
                  @if(publicCommentControl.type === 'source' && optionalTranslatableControlHasMissingTranslation(publicCommentControl)){
                    <div
                
                      class="invalid-feedback" *transloco="let t; scope : 'contributions'">
                      {{t('contributions.missing_translation_erasure_warning')}}
                    </div>
                  }
                  <div
                    *ngIf="
                            deliverableForm.get(publicCommentControl.key)?.errors
                              ?.minlength &&
                            deliverableForm.get(publicCommentControl.key)?.touched
                          "
                    class="invalid-feedback"
                  >
                    {{ t('common.too_short') }}
                  </div>

                  <!-- <div
                          *ngIf="
                            deliverableForm.get(publicCommentControl.key)?.errors
                              ?.required &&
                            deliverableForm.get(publicCommentControl.key)?.dirty
                          "
                          class="invalid-feedback"
                        >
                          {{ t('common.required') }}
                        </div> -->
                </div>
                }
                }

              </div>
            </div>
          </div>
        </div>
        <div class="card-body card-gray-200 p-3 my-2 border-rounded">
          <div class="form-group">
            <div class="">
              <div class="input-self">
                <div
                  class="d-flex justify-content-between"
                  (click)="toggleCollapse('private_note')"
                >
                  <label class="form-label h5 fw-bold">
                    {{ t('social.private_note') }}
                  </label>
                  <a
                    class="text-nowrap link-primary-dark fw-bold d-flex align-items-center gap-2"
                    *transloco="let t"
                  >
                    @if(collapseObject().private_note){
                    <span>{{t('common.show_more')}} <i class="fa-solid fa-angle-left ml-1"></i></span>

                    } @else {
                    <span>{{t('common.show_less')}} <i class="fa-solid fa-angle-down ml-1"></i></span>

                    }
                  </a>
                </div>

                <div
                  class=""
                  [collapse]="collapseObject().private_note"
                  [isAnimated]="true"
                >
                  <p class="mb-2 form-text">
                    <span
                      class="mr-1"
                      *transloco="let t"
                    >{{t('common.optional')}}.</span>
                    <span class="mr-1">{{t('contributions.record_doubts_suggest_changes')}}</span>
                    <span>{{t('contributions.editor_will_review')}}</span>
                  </p>

                  @if(deliverableForm.get('private_note')){

                  <div
                    class="input-group textarea-control mb-3"
                    [ngClass]="{
                'ng-invalid':
                  deliverableForm.get('private_note')?.invalid &&
                  (deliverableForm.get('private_note')?.touched ||
                    deliverableForm.get('private_note')?.dirty),
                'ng-valid':
                  deliverableForm.get('private_note')?.valid &&
                  deliverableForm.get('private_note')?.touched &&
                  deliverableForm.get('private_note')?.dirty
              }"
                  >
                    <!-- <span class="input-group-text">
                Heading
              </span> -->



                    <textarea
                      class="form-control"
                      type="text"
                      rows="2"
                      [disabled]="isAnythingLoading()"
                      formControlName="private_note"
                      [placeholder]="'content_management.write_text' | transloco"
                    >
                    </textarea>

                    <div
                      *ngIf="
                  deliverableForm.get('private_note')?.errors
                    ?.maxlength &&
                  deliverableForm.get('private_note')?.dirty
                "
                      class="invalid-feedback"
                    >
                      {{ t('common.too_long') }}
                    </div>

                    <div
                      *ngIf="
                  deliverableForm.get('private_note')?.errors
                    ?.minlength &&
                  deliverableForm.get('private_note')?.touched
                "
                      class="invalid-feedback"
                    >
                      {{ t('common.too_short') }}
                    </div>

                    <!-- <div
                *ngIf="
                  deliverableForm.get('private_note')?.errors?.required &&
                  deliverableForm.get('private_note')?.dirty
                "
                class="invalid-feedback"
              >
                {{ t('common.required') }}
              </div> -->
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="button-group align-self-end">
          @if(combinedLoadingObject().update || combinedLoadingObject().translate || combinedLoadingObject().submit)
          {
          <button class="btn btn-link p-0 my-0">
            <multisite-loader-globe-animation-detail
              [width]="40"
              [height]="40"
            ></multisite-loader-globe-animation-detail>
          </button>
          }
          @if(sourceLanguage()){
          <button
            class="btn btn-secondary"
            [disabled]="!noSourceLanguageFormControlsAreNull() || isAnythingLoading() || deliveryLocked()"
            (click)="sendForTranslation()"
          >
            <span *transloco="let t">
              {{ t('content_management.translate') }}
            </span>
          </button>
          }
          <button
            class="btn btn-secondary"
            (click)="save('update')"
            [disabled]="isAnythingLoading() || deliveryLocked() || !formIsValid()"
          >
            <span *transloco="let t">{{ t('common.save') }}</span>
          </button>
          <button
            (click)="save('submit')"
            [disabled]="!formIsValid() || isAnythingLoading() || deliveryLocked()"
            class="btn btn-primary"
            type="button"
          >
            {{ t('common.submit') }}
          </button>
        </div>
        @if(error()){
        <alert
          [type]="'warning'"
          [dismissOnTimeout]="9000"
          class="my-3"
        >
          <strong>{{ t('common.problem')! }}</strong><br>
          <p>{{ errorObject() ? t(errorObject()?.message) : t(errorString()) }}</p>
        </alert>
        }

      </form>
      }
    </div>
  </div>
</div>