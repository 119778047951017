 <!-- Content holder is card with flexible styling it can be reusable at the same parent -->
 <div class="content-holder" *transloco="let t">
  <div class="header align-items-center">
    <p class="title">

      <span>{{t('account.consent')}}</span>
    </p>
    <!-- <div class="subtitle">I am a subtitle</div> -->
  </div>
  <!-- 
      Use bootstrap grid system for content placment inside of body element
     -->
  <div class="row body">



    <multisite-loader-globe-animation-detail *ngIf="isAnythingLoading()" width="80" height="80" class="my-3"></multisite-loader-globe-animation-detail>

    <div class="d-flex flex-row align-items-center justify-content-start align-self-start flex-wrap gap-2 mt-2">
    @for(filterOption of filterOptions(); track filterOption){

      <multisite-icon-pill
        [fallbackIconClasses]="'fa-regular fa-filter'"
        [labelTranslationKey]="'account.consent_term_category.'+filterOption"
        [selectable]="true"
        [inverted]="filterOption === selectedFilterOption()"
        (click)="selectedFilterOption.set(filterOption)"
        >
      </multisite-icon-pill>
      
    }
      <multisite-icon-pill
          [fallbackIconClasses]="'fa-solid fa-filter'"
          [labelTranslationKey]="'common.all'"
          [selectable]="true"
          [inverted]="!selectedFilterOption()"
          (click)="selectedFilterOption.set(undefined)">
        </multisite-icon-pill>
  </div>

    <hr class="my-4">

    <div>
    @for(consentSet of displayConsents(); track consentSet.latest){

        
        <div class="my-3">
          <consent-updater-card
            [consentSet]="consentSet"
            [loading]="loadingObject().consents"
            [error]="error()"
            (updateStatus)="updateConsentStatus($event.consentSet, $event.status)"
          >
          </consent-updater-card>
        </div>

    }
  </div>


  </div>

</div>


<ng-template #declineModal>
  <div class="modal-header" *transloco="let t">
    <h4 id="dialog-static-name" class="modal-title pull-left">{{t('common.are_you_sure')}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </button>
  </div>

  @if(currentConsentSet()){


    <div class="modal-body" *transloco="let t">

      <p class="lead my-3">{{currentConsentSet().latest.question}}</p>

      <div class="d-flex align-items-center">

        @if(!currentConsentSet().latest.given_at && !currentConsentSet().latest.declined_at){
          <button class="btn btn-warning" (click)="updateConsentStatus(currentConsentSet(),'declined')">{{t(currentConsentSet().latest.verb_reject)}}</button>
          <button class="btn btn-primary" (click)="updateConsentStatus(currentConsentSet(),'given')">{{t(currentConsentSet().latest.verb_accept)}}</button>
        }
        @if(currentConsentSet().latest.withdrawal_allowed && currentConsentSet().latest.given_at && !currentConsentSet().latest.declined_at && !currentConsentSet().latest.withdrawn_at){
          <button class="btn btn-primary" (click)="updateConsentStatus(currentConsentSet(),'withdrawn')">{{t('common.withdraw')}}</button>
        }
        <multisite-loader-globe-animation-detail
          *ngIf="loadingObject().consents"
          class="ml-2 d-block-inline" [width]="40" [height]="40">
        </multisite-loader-globe-animation-detail>

      </div>


      <div class="my-3">
        <strong>{{t('account.what_if_say_no')}}</strong>
      <p class="card-text mb-4">{{currentConsentSet().latest.decline_info}}</p>
      </div>
      <div class="embed-responsive" *ngIf="currentConsentSet().latest.videos?.[0]">
        <frontend-video-player-type-two
          [video]="currentConsentSet().latest.videos[0]"
          [classes]="'mb-3'"
        ></frontend-video-player-type-two>
      </div>


      <h6 class="mt-4 d-flex justify-content-between align-items-center" (click)="showCurrentConsentSetMoreInfo.set(!showCurrentConsentSetMoreInfo())"
      style="cursor: pointer;" [attr.aria-expanded]="showCurrentConsentSetMoreInfo()" aria-controls="collapseBasic">
        <span><i class="fa-solid fa-circle-info mr-2"></i>{{t('common.more_info')}}</span>
        @if(showCurrentConsentSetMoreInfo()){
          <span><i class="fa-solid fa-chevron-up ml-1"></i></span>
        } @else {
          <span><i class="fa-solid fa-chevron-down ml-1"></i></span>
        }
      </h6>
      <div class="mt-3" [collapse]="!showCurrentConsentSetMoreInfo()" [isAnimated]="true" id="modalMoreInfo">
        <p>{{currentConsentSet().latest.description}}</p>
      </div> 
    </div>
  }
</ng-template>