import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Media } from '@frontend/core';
import { MediaService } from './media.service';
import { map, of } from 'rxjs';
import { DataProcessingService } from '../../utilities';

export interface Gallery {
  category:string,type:string,tagSlugsCacheKey:string,data:Media[]
}

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  
  galleries : Gallery[] = [];

  constructor(private mediaService : MediaService, private http : HttpClient, private dataProcessingService : DataProcessingService) { }


  transformResponse (galleryResponse : Media[], category:string, type:string,tagSlugsCacheKey:string) : Gallery{
    let gallery : Gallery = {category:category,type:type,tagSlugsCacheKey,data:galleryResponse};
    return gallery;
  }
  transformMedia (media){
    let custom_urls = this.mediaService.setupCloudinaryImageMediaUrls(media);
    return new Media(media.id,null,null,media.file_url,custom_urls.base_url,custom_urls.transformations_url,custom_urls.asset_url,media.file_name,media.file_type,null,null,media.description,media.credits,null,media.category,media.type,null,null);
  }
  transformMediaArray (mediaFromBackend){
    let transformedMedia : Media[] = [];
    mediaFromBackend.forEach(m=>{
      transformedMedia.push(this.transformMedia(m));
    });
    return transformedMedia;
  }
  getGallery (category:string,type:string,tag_query_params:URLSearchParams = null) {

    let tagSlugsCacheKey;
    if(tag_query_params){
      tagSlugsCacheKey = this.dataProcessingService.makeUniqueCacheKeyFromSearchParams(tag_query_params);
    }

    let cachedGallery : Gallery = this.getCachedGallery(category,type,tagSlugsCacheKey);
    if (cachedGallery){
      return of(cachedGallery);
    };
    let url = 'api/v1/media/gallery';
    let searchParams = new HttpParams();
    searchParams = searchParams.append('category', category);
    searchParams = searchParams.append('type', type);
    if(tag_query_params){
      tag_query_params.forEach((value, key) => {
        searchParams = searchParams.append(key, value);
      });
    }
    url = `${url}?${searchParams.toString()}`;
    return this.http.get<{'data' : Media[]}>(url)
        .pipe(
          map(response =>{
            let media = this.transformMediaArray(response.data);
            let gallery : Gallery = this.transformResponse(media,category,type,tagSlugsCacheKey);
            this.cacheGallery(gallery);
            return gallery;
          })
        )
  };
  getCachedGallery (category:string,type:string,tagSlugsCacheKey:string) : Gallery {

    if (!this.galleries.length){
      return null;
    }
    let cachedGallery = this.galleries.find(g=>g.category===category&&g.type===type&&g.tagSlugsCacheKey===tagSlugsCacheKey);
    if (cachedGallery?.data?.length){
      return cachedGallery;
    } else {
      return null;
    }

  };

  cacheGallery (gallery : Gallery){
    let index = this.galleries.findIndex(g=>g.category===gallery.category&&g.type===gallery.type&&g.tagSlugsCacheKey===gallery.tagSlugsCacheKey);
    if (index >-1){
      this.galleries[index]= gallery;
    } else {
      this.galleries.push(gallery);
    }
  };


}