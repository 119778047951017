import { Topic } from "../content";
import { Culture } from "../cultures";
import { Difference } from "../differences";
import { Vote } from "../votes";
import { Insight } from "./insight.model";
import { Subject } from "./subject.model";
import { Task } from "./task.model";

export class Delivery {
    id: number;
    task: Task;
    subject: Subject; // in future could be some other types too
    featurable_type: string; // in future could be some other types too
    insight: Insight;
    votes: Vote[];
    differences: Difference[];
    source_lang: string;
    target_lang: string;
    user_id: number;
    handler_id: number;
    started_at: Date | null;
    submitted_at: Date | null;
    reopen_count: number;
    abandoned_at: Date | null;
    completed_at: Date | null;
    credits: number;
    cultures: Culture[];
    topics: Topic[];
    insight_translations?: { [key: string]: string };
    vote_translations?: { [key: string]: string };

    constructor(
        id: number,
        task: Task,
        subject: Subject, // in future could be some other types too
        featurable_type: string, // in future could be some other types too
        insight: Insight,
        source_lang: string,
        target_lang: string,
        userId: number,
        handler_id: number,
        started_at: Date | null,
        submitted_at: Date | null,
        reopen_count: number,
        abandoned_at: Date | null,
        completed_at: Date | null,
        credits: number,
        votes?: Vote[],
        differences?: Difference[],
        cultures?: Culture[],
        topics?: Topic[],
        insight_translations?: { [key: string]: string },
        vote_translations?: { [key: string]: string },
) {
        this.id = id;
        this.task = task;
        this.subject = subject;
        this.featurable_type = featurable_type;
        this.insight = insight;
        this.source_lang = source_lang;
        this.target_lang = target_lang;
        this.user_id = userId;
        this.handler_id = handler_id;
        this.started_at = started_at;
        this.submitted_at = submitted_at;
        this.reopen_count = reopen_count;
        this.abandoned_at = abandoned_at;
        this.completed_at = completed_at;
        this.credits = credits;
        this.votes = votes;
        this.differences = differences
        this.cultures = cultures;
        this.topics = topics;
        this.insight_translations = insight_translations;
        this.vote_translations = vote_translations;
    }
}