import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContributionsHomeComponent } from './home/contributions-home.component';
import { ContributionsRoutingModule } from './contributions-routing.module';
import { LoadersModule } from '../loaders';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { AvatarsModule, CommentsModule, InlinePillPickerModule, LinksModule, MultilingualFormsModule, PaginationModule, PillsModule, UploadersModule } from '@frontend/shared';
import { DeliveryStatusCardComponent } from './delivery-status-card/delivery-status-card.component';
import { DeliverableEditParentComponent } from './deliverable-edit-parent/deliverable-edit-parent.component';
import { InsightEditComponent } from './insight-edit/insight-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { RichTextEditorBasicCustomBuildModule, RichTextEditorReactiveFormInputModule } from '@frontend/ckeditor';
import { ProgressIndicatorDoughnutModule } from '../tracking';
import { NationalCultureVisualisationModule } from '../cultures/national-culture-visualisation';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { RandomisedMessageCardComponent } from '../content/message';
import { ScoreHighlightModule } from '../content/cards';
import { DifferenceVoteCcoComponent } from './difference-vote-cco/difference-vote-cco.component';
import { FormControlLickertQuestionComponent } from '../forms';
import { DeliverableService } from './deliverable.service';

@NgModule({
    declarations: [
        ContributionsHomeComponent,
        DeliveryStatusCardComponent,
        DeliverableEditParentComponent,
        InsightEditComponent,
        DifferenceVoteCcoComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TypeaheadModule,
        CollapseModule,
        ContributionsRoutingModule,
        LoadersModule,
        TranslocoModule,
        LinksModule,
        PaginationModule,
        AvatarsModule,
        MultilingualFormsModule,
        UploadersModule,
        PillsModule,
        RichTextEditorBasicCustomBuildModule, // Our own library
        RichTextEditorReactiveFormInputModule,
        FormsModule,
        ProgressIndicatorDoughnutModule,
        NationalCultureVisualisationModule,
        InlinePillPickerModule,
        ModalModule, 
        AlertModule,
        CommentsModule,
        ScoreHighlightModule,
        RandomisedMessageCardComponent,
        FormControlLickertQuestionComponent,
    ],
    exports: [
        // ContributionsHomeComponent,
    ],
    providers: [
        { provide: TRANSLOCO_SCOPE, useValue: 'contributions' },
        DeliverableService,
    ],
})
export class ContributionsModule { }