import { Injectable, WritableSignal, signal } from '@angular/core';
import { IModelsToContentItemsConverter } from '@frontend/core';
import { AuthService, UserExtraLite } from '../auth';
import { ContentItem, MediaService } from '../content';
import { Language, LanguageService, LanguageSkill } from '../language';
import { CultureService } from '../cultures';
import { Observable, Subscription, catchError, map, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../error';

@Injectable({
  providedIn: 'root'
})
export class ProfileUserService implements IModelsToContentItemsConverter {

  private _myLanguages : WritableSignal<Language[] | undefined> = signal(undefined);
  activeLanguageSubscription: Subscription;
  myLanguagesSubscription: Subscription;


  constructor(
    private mediaService : MediaService,
    private languageService : LanguageService,
    private cultureService : CultureService,
    private http: HttpClient,
    private errorService: ErrorService,
    private authService: AuthService,
  ) {

    this.activeLanguageSubscription =
    this.languageService.activeLanguageObject.subscribe(() => {
      this._myLanguages.set(undefined);
      if(this.authService.user.getValue())
      this.myLanguagesSubscription = this.getMyLanguages(true).subscribe();
    });

  }

  clearData() {
    this._myLanguages.set(undefined);
  }

  getMyLanguages(freshFromServer:boolean): Observable<Language[]> {

    if (!freshFromServer && this._myLanguages()) {
      return of(this._myLanguages());
    } else {
      return this.http.get<Language[]>('api/v1/user-profiles/my-languages').pipe(
        map((languages) => {
          this._myLanguages.set(languages);
          return languages;
        }),
        catchError((error)=>this.handleError(error)),  
      );
    }
  }
  updateMyLanguage(languageSkill: LanguageSkill): Observable<Language[]> {
    return this.http.post<Language[]>('api/v1/user-profiles/my-languages/update', languageSkill).pipe(
      map((response) => {

        const savedLanguageIndex = this._myLanguages().findIndex(language => language.id === languageSkill.language_id);
        if (savedLanguageIndex > -1) {
          const savedLanguage = this._myLanguages()[savedLanguageIndex];
          savedLanguage.skill.level = languageSkill.level;
          savedLanguage.skill.description = languageSkill.description;
          const languages = [...this._myLanguages()];
          languages[savedLanguageIndex] = savedLanguage;
            languages.sort((a, b) => (b.skill?.level ?? 0) - (a.skill?.level ?? 0));
          this._myLanguages.set(languages);
        }
        return this._myLanguages();
      }),
      catchError((error)=>this.handleError(error)),
    );
  }

  storeNewMyLanguage(languageSkill: LanguageSkill): Observable<Language[]> {
    return this.http.post<Language[]>('api/v1/user-profiles/my-languages/store', languageSkill).pipe(
      map((languages) => {
        this._myLanguages.set(languages);
        return languages;
      }),
      catchError((error)=>this.handleError(error)),
    );
  }

  deleteMyLanguage(languageId: number): Observable<Language[]> {
    return this.http.delete(`/api/v1/user-profiles/my-languages/${languageId}`).pipe(
      map((languages) => {
        const myLanguages = this._myLanguages().filter(language => language.id !== languageId);
        this._myLanguages.set(myLanguages);
        return this._myLanguages();
      }),
      catchError((error)=>this.handleError(error)),
    );
  }

  get myLanguages() {
    return this._myLanguages.asReadonly();
  }

  convertUserToContentItem(user: UserExtraLite): ContentItem {
    const languagesAsTags = user.advancedLanguages ? this.languageService.convertLanguagesToTags(user.advancedLanguages,true,this.mediaService.cloudinary_base_url) : [];
    const culturesAsTags = user.cultures ? this.cultureService.convertCulturesToTags(user.cultures,true) : [];
    let contentItem = new ContentItem(
      user.id,
      user.name_full_honorific,
      null,
      null,
      null,
      user.bio,
      null,
      null,
      user.slug,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [...languagesAsTags,...culturesAsTags],
      user,
      null
    );
    return contentItem;
  }
  
  makeContentItems(users: UserExtraLite[]): ContentItem[] {
    let content: ContentItem[] = [];
    if (users?.length) {
      content = users.map(user => this.convertUserToContentItem(user));
    }
    return content;
  }

  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    } else {
      const message = errorResponse.error.message;
      const standardErrorMessageTranslationKey = this.errorService.getCommonErrorMessageTranslationKey(message);
      if(standardErrorMessageTranslationKey){
        errorMessage = standardErrorMessageTranslationKey;
      } else if(message.includes('Request latest data then try again.')){
        errorMessage = 'error.refresh';
      }
      if (errorResponse.error.meta){
        return throwError({message:errorMessage,meta:errorResponse.error.meta});
      }
      return throwError(errorMessage);
    }
  }


}