
<h5 class="mb-3 text-primary" *transloco="let t">
  {{
     t('common.add_new')
  }}
</h5>

@if(languageAddForm) {
<form class="mb-4 rounded" [formGroup]="languageAddForm" *transloco="let t">
  <div class="form-group">
    <label for="language" class="form-label">{{ t('common.language') }}</label>
    <input
      [typeahead]="availableLanguages()"
      formControlName="language"
      [typeaheadMinLength]="0"
      (typeaheadOnSelect)="onNewLanguageSelect($event)"
      class="form-control mt-2"
      typeaheadOptionField="name"
      autocomplete="off"
      [placeholder]="t('common.start_typing')"
    />
    <div
      *ngIf="
        languageAddForm.get('language').invalid &&
        languageAddForm.get('language').touched
      "
      class="text-danger"
    >
      {{ t('common.required') }}
    </div>
  </div>
</form>
}

<div class="list-group">
  @for(language of myLanguagesAndNewLanguage(); track language.id) {
  <multisite-lickert-proportion-edit-card
    [item]="language" 
    [nameText]="language.name"
    [flag]="language.flag"
    [numberValue]="language.skill?.level"
    [numberValueLabelTranslationKey]="'common.level'"
    [textInputLabelTranslationKey]="'common.description'"
    [textInputHelpTranslationKey]="'profile.my_language_skills_description_info'"
    [textInputHelpTranslationScope]="'profile'"
    [text]="language.skill?.description"
    [allowTranslations]="true"
    [translationSourceLanguages]="translationSourceLanguages()"
    [rangeInputSteps]="languageSkillLevelRangeInputSteps"
    [editModeTrueByDefault]="language.id === newLanguage()?.id"
    [unsaved]="language.id === newLanguage()?.id"
    (save)="onSubmit($event)"
    (delete)="onLanguageRemove($event, language)"
  ></multisite-lickert-proportion-edit-card>
  }
</div>
