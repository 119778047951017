import { Injectable, signal } from '@angular/core';
import { Consent } from './consent.model';
import { LanguageService } from '../../language';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, catchError, filter, map, of, throwError } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { ErrorService } from '../../error';
import { AuthService } from '../../auth';

@Injectable({
  providedIn: 'root'
})
export class ConsentService {

  private _consents = signal<Consent[]|undefined>(undefined);
  consents = this._consents.asReadonly();
  private _missingConsentSlugs: BehaviorSubject<string[]>;
  public missingConsentSlugs: Observable<string[]>;
  // private _missingConsentSlugs = signal<string[]>([]); // THIS DIDN'T WORK
  // missingConsentSlugs = this._missingConsentSlugs.asReadonly(); // THIS DIDN'T WORK
  activeLanguageSubscription: Subscription;
  activityTimeStampAttributesUser = signal<string[]>(['given_at', 'declined_at', 'withdrawn_at']).asReadonly();
  activityTimeStampAttributes = signal<string[]>(this.activityTimeStampAttributesUser().concat(['created_at'])).asReadonly();
  currentRoute = signal<ActivatedRoute | undefined>(undefined);

  constructor(
    private languageService: LanguageService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private authService: AuthService,
  ) {
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(() => {
        if(this.authService.user.getValue()){
          this.getConsents(true).subscribe();
        }
    });

      this._missingConsentSlugs = new BehaviorSubject<string[]>(undefined);
      this.missingConsentSlugs = this._missingConsentSlugs.asObservable();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let currentRoute = this.route.root;
        while (currentRoute.firstChild) {
          currentRoute = currentRoute.firstChild;
        }
        return currentRoute;
      })
    ).subscribe((currentRoute) => {
      this.currentRoute.set(currentRoute);
      this.checkCurrentRouteForConsent(currentRoute);
    });

  }

  checkCurrentRouteForConsent(currentRoute: ActivatedRoute){
    if (currentRoute.snapshot.data?.consent?.length && this.consents() ) {
      const missingConsentSlugs = this.getMissingConsents(currentRoute.snapshot.data.consent);
      this._missingConsentSlugs.next(missingConsentSlugs);
      // if (missingConsentSlugs?.length) {
      //   this._missingConsentSlugs.next(missingConsentSlugs);
      // }
    } else {
      this._missingConsentSlugs.next([]);
    }
  }

  clearTranslations() {
    this._consents.set(undefined);
  };
  clearData() {
    this.clearTranslations();
  }
  getMissingConsents(consentSlugs: string[]): string[] {
    return consentSlugs.filter(slug => !this.hasConsentSingle(slug));
  }
  hasConsentSingle(slug: string): boolean {
      const latestConsent = this.getLatestConsent(slug);
      return latestConsent && latestConsent.given_at && !latestConsent.withdrawn_at && !latestConsent.declined_at;
  }
  hasConsent(consentSlugs: string[]): boolean {
    return consentSlugs.every(slug => this.hasConsentSingle(slug));
  }
  getLatestConsent(slug: string): Consent | undefined {
    const allConsents = this.consents()?.filter(consent => consent.slug === slug);
    if (!allConsents?.length) {
      return undefined;
    } else {
      return this.sortByLatestActivity(allConsents)[0];
    }
  }

  getConsents (freshFromServer: boolean) : Observable<Consent[]> {

    if (this.consents() && !freshFromServer){
      return of(this.consents());
    }

    return this.http.get<{data: Consent[]}>('api/v1/consent')
      .pipe(
        map(response =>{
          if (response ){
            const firstLoad = !this.consents();
            this._consents.set(response.data);
            if (firstLoad && this.currentRoute()) {
              this.checkCurrentRouteForConsent(this.currentRoute());
            }
            return this.consents();
          };
        }),
        catchError((error) => {
          return this.handleError(error);
        })
      )
  }

  updateConsentStatus(consent: Consent, status: 'given' | 'declined' | 'withdrawn') : Observable<Consent[]> {
    const { id, term_id, term_version_id, user_id, target_type, target_id } = consent;
    const newConsent = {
      id,
      term_id,
      term_version_id,
      user_id,
      target_type,
      target_id,
      locale : this.languageService.activeLanguageObjectSynchronously?.languageKey,
      new_status: status
    };
    
    return this.http.put<{data: Consent[]}>(`api/v1/consent/${consent.id}/user`, newConsent)
      .pipe(
        map(response => {
          this._consents.set(response.data);
          this.checkCurrentRouteForConsent(this.currentRoute());
          return this.consents();
        }),
        catchError((error) => {
          return this.handleError(error);
        })
      );
  }

  sortByLatestActivity(consents: Consent[] = []): Consent[] {
    const sortedConsents = [...consents];
    sortedConsents.sort((a, b) => {
      // Extract the relevant timestamps, defaulting to a very early date if not present
      const aMaxDate = new Date(Math.max(...this.activityTimeStampAttributes().map(prop => a[prop] ? new Date(a[prop]).getTime() : 0), 0));
      const bMaxDate = new Date(Math.max(...this.activityTimeStampAttributes().map(prop => b[prop] ? new Date(b[prop]).getTime() : 0), 0));
    
      // Compare the dates to sort in descending order; if the dates are equal, sort by ID
      const dateComparison = Number(bMaxDate) - Number(aMaxDate);
      if (dateComparison !== 0) {
        return dateComparison;
      }
      return b.id - a.id;
    });
    return sortedConsents;
  }

  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    } else {
      const message = errorResponse.error.message;
      const standardErrorMessageTranslationKey = this.errorService.getCommonErrorMessageTranslationKey(message);
      if(standardErrorMessageTranslationKey){
        errorMessage = standardErrorMessageTranslationKey;
      } else if(message.includes('Request latest data then try again.')){
        errorMessage = 'error.refresh';
      }
      if (errorResponse.error.meta){
        return throwError({message:errorMessage,meta:errorResponse.error.meta});
      }
      return throwError(errorMessage);
    }
  }

}
