<div class="card" [ngClass]="cardClasses()" *transloco="let t">
  @if(progress()){
    <div class="progress-indicator" [style]="'--progress: '+progress()+'%'">
      <div class="progress"></div>
    </div>
  }
  <!-- Header Section -->
  <div class="d-flex align-items-center justify-content-between w-100 header" (click)="toggleCard($event)">
    <div class="d-flex align-items-start align-items-md-center">
      <img
        class="rounded-circle me-md-3 me-1"
        [src]="
          getFlagUrlFromHash(flag()?.hash,'w_32,c_fill,ar_1:1,r_max,f_auto/')
        "
        alt="Flag"
        width="40"
        height="40"
      />
      <div class="flex-grow-1">
        <h5 class="mb-1">{{ nameText() ?? t(nameTranslationKey()) }}</h5>
        @if(savedRangeInputStep()) {
        <!-- <small class="text-muted">{{ t('language.level.' + language.skill.level + '.name') }}</small> -->
        <small class="d-block text-muted">
          {{
            savedRangeInputStep().labelText ?? (savedRangeInputStep().labelTranslationKey | transloco : {scope: savedRangeInputStep().labelTranslationScope })
          }}
        </small>
        }
      </div>
    </div>
    <div
      *ngIf="showDetailsButton() || showEditButton() || showDeleteButton()"
      class="d-flex justify-content-end gap-2"
    >
      @if(showDetailsButton()) {
        <button
        class="btn btn-primary d-sm-block d-none"
        [disabled]="loading() || editMode()"
        (click)="toggleDetailsMode($event)"
      >
        @if(detailsMode()) {
          <span><i class="fa-solid fa-eye-slash"></i></span>
        } @else {
          <span><i class="fa-solid fa-eye"></i></span>
        }
      </button>
      }
      @if(showEditButton()) {
        <button
        class="btn btn-primary"
        [disabled]="loading()"
        (click)="toggleEditMode($event)"
      >
        <i class="fa-solid fa-pen"></i>
      </button>
      }
      @if(showDeleteButton()){
        <button
          class="btn btn-danger d-sm-block d-none"
          [disabled]="loading()"
          (click)="doDelete($event)"
        >
          <i class="fa-solid fa-trash-can"></i>
          <!-- {{ t('common.delete') }} -->
        </button>
      }
    </div>
  </div>

  <!-- Description Section -->
  <div class="text-secondary clickable" [collapse]="!detailsMode()" [isAnimated]="true" (click)="toggleCard($event)">
    <hr />
    <p>{{ text() ?? t('common.write_something_here') }}</p>
  </div>


  <!-- Edit Section -->

  <form
    [formGroup]="editForm"
    (ngSubmit)="onSubmit()"
    *ngIf="editMode() && editForm"
  >
    <hr />
    <div class="form-group">
      @if(numberValueLabelText() || numberValueLabelTranslationKey()) {
        <label for="numberValue" class="form-label">{{ numberValueLabelText() ?? (numberValueLabelTranslationKey() | transloco : {scope:numberValueLabelTranslationScope()}) }}</label>
      }
      <form-control-lickert-scale-bar
        formControlName="numberValue"
        [steps]="rangeInputSteps()"
        [min]="rangeInputSteps()?.[0].value"
        [max]="
          rangeInputSteps()?.[
          rangeInputSteps().length - 1
          ].value
        "
        [stepSize]="1"
        [volumeControlStyle]="true"
      ></form-control-lickert-scale-bar>

      @if(activeRangeInputStep()) {
      <div class="mt-2">
        <div class="fw-bold text-secondary">
          {{
            t(
              activeRangeInputStep().descriptionShortTranslationKey
            )
          }}
        </div>
        <div class="text-muted">
          {{
            t(
              activeRangeInputStep().descriptionTranslationKey
            )
          }}
        </div>
      </div>
      }

      <div
        *ngIf="
          editForm.get('numberValue').invalid &&
          editForm.get('numberValue').touched
        "
        class="text-danger"
      >
        {{ t('validation.required') }}
      </div>
    </div>

    @if(editMode() && allowTranslations() && translationSourceLanguages()?.length && editForm.get('text')) {
      <div class="my-3 card card-gray p-3 border-rounded">
       <multisite-inline-pill-picker
          [items]="translationSourceLanguages()"  
          [selectedItemIdentifiers]="[translationSourceLanguage()?.iso]"
          [selectedPropertyKey]="'iso'"
          [titleTranslationKey]="'survey.choose_language_for_form'"
          [collapsedPrefixTranslationKey]="null"
          [pillType]="'avatar'"
          (onSelect)="setEditingLanguage($event)"
          (onToggleCollapse)="toggleLanguagePickerCollapsed()"
          [collapsed]="languagePickerCollapsed()"
          [collapseOnSelect]="true"
          ></multisite-inline-pill-picker>

     </div>
    }

    <div class="input-self mt-4">
      @if(textInputLabelText() || textInputLabelTranslationKey()) {

        <label for="text" class="form-label d-block">
          {{ textInputLabelText() ?? (textInputLabelTranslationKey() | transloco : {scope : textInputLabelTranslationScope()}) }}
        </label>
      }
      @for(textControl of textControls(); track textControl.key){
        @if(editForm.get(textControl.key)){
          <div
          class="input-group textarea-control mb-3"
          [ngClass]="{
            'ng-invalid':
              editForm.get(textControl.key)?.invalid &&
              (editForm.get(textControl.key)?.touched ||
                editForm.get(textControl.key)?.dirty),
            'ng-valid':
              editForm.get(textControl.key)?.valid &&
              editForm.get(textControl.key)?.touched &&
              editForm.get(textControl.key)?.dirty
          }"
          >
    
          @if(translationSourceLanguage()){
            <span class="input-group-text">
              {{ textControl.type === 'source' ? translationSourceLanguage().name : t(activeLanguageObject()?.name) }}
            </span>
          }
    
            <textarea
              class="form-control"
              [formControlName]="textControl.key"
              [id]="textControl.key"
              rows="2"
              [disabled]="loading()"
            ></textarea>
    
            <div
              *ngIf="
                editForm.get(textControl.key).errors?.required &&
                editForm.get(textControl.key).touched
              "
              class="invalid-feedback"
            >
              {{ t('common.required') }}
            </div>
            <div
              *ngIf="
                editForm.get(textControl.key).errors?.minlength &&
                editForm.get(textControl.key).touched
              "
              class="invalid-feedback"
            >
              {{ t('common.too_short') }}
            </div>
            <div
              *ngIf="
                editForm.get(textControl.key).errors?.maxlength &&
                editForm.get(textControl.key).touched
              "
              class="invalid-feedback"
            >
              {{ t('common.too_long') }}
            </div>
          </div>
        }

      } 
        @if(textInputHelpText() || textInputHelpTranslationKey()){
          <div
          class="form-text text-muted"
         
        >
          {{ textInputHelpText() ?? (textInputHelpTranslationKey() | transloco : {scope : textInputHelpTranslationScope()}) }}
        </div>
        }
      </div>

    <div class="mt-3 d-flex gap-2 justify-content-end">
      <multisite-loader-globe-animation-detail
        *ngIf="loading()"
        height="40"
        width="40"
        class="d-block-inline"
      ></multisite-loader-globe-animation-detail>
      @if(!unsaved()){
        <button
            class="btn btn-danger d-block d-sm-none"
            [disabled]="loading()"
            (click)="doDelete($event)"
          >
            <i class="fa-solid fa-trash-can"></i>
        </button>
      }
      <button class="btn btn-warning" (click)="cancelChanges()">
        {{ unsaved() ? t('common.cancel') : t('common.cancel_changes') }}
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="editForm.invalid"
      >
        {{ unsaved() ? t('common.save') : t('common.submit') }}
      </button>
    </div>
  </form>

  <alert [type]="'warning'" [dismissOnTimeout]="3500" *ngIf="error()">
    <span *transloco="let t">{{ t(error()) }}</span>
  </alert>
</div>