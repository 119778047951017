import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map } from 'rxjs/operators';
import { ErrorService } from '../error';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private _availableTranslationSourceLanguageCodes : string[] = [ // iso_639_1 available in DeepL API
    'ar', // Arabic
    'bg', // Bulgarian
    'cs', // Czech
    'da', // Danish
    'de', // German
    'el', // Greek
    'en', // English (all English variants)
    'es', // Spanish
    'et', // Estonian
    'fi', // Finnish
    'fr', // French
    'hu', // Hungarian
    'id', // Indonesian
    'it', // Italian
    'ja', // Japanese
    'ko', // Korean
    'lt', // Lithuanian
    'lv', // Latvian
    'nb', // Norwegian Bokmål
    'nl', // Dutch
    'pl', // Polish
    'pt', // Portuguese (all Portuguese variants)
    'ro', // Romanian
    'ru', // Russian
    'sk', // Slovak
    'sl', // Slovenian
    'sv', // Swedish
    'tr', // Turkish
    'uk', // Ukrainian
    'zh'  // Chinese (all Chinese variants)
  ];

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,

  ) {

  }

  get availableTranslationSourceLanguageCodes() {
    return this._availableTranslationSourceLanguageCodes;
  }

  getDefaultLocaleFromGenericLanguage(languageCode: string): string {
    const languageMap: { [key: string]: string } = {
      'en': 'en-GB',
      'pt': 'pt-BR', // Brazilian by default!
      'zh': 'zh-HANS' // Simplified Chinese (HANT for Traditional)
    };
    return languageMap[languageCode] || languageCode;
  }

  getTranslation(textsAsObject:{[key:string]:string},target_lang : string, source_lang : string) {

    const keys = Object.keys(textsAsObject);
    const values = Object.values(textsAsObject);

    const params = {
      text: values,
      target_lang: target_lang,
      source_lang: source_lang
    };

    return this.http.post<{translated_text: string[]}>('api/v1/translate', params)
      .pipe(
        map((response) => {

            const translatedTexts = response.translated_text;
            const translatedTextsAsObject = {};
            if(keys.length !== 1){
              keys.forEach((key,index) => {
                translatedTextsAsObject[key] = translatedTexts[index];
              });
            } else {
              translatedTextsAsObject[keys[0]] = translatedTexts;
            }

            return translatedTextsAsObject;
        }),
        catchError(error => this.handleError(error))
    );
  }
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    // if (errorResponse.error.errors?.slug?.[0] === 'The slug has already been taken.'){
    //   errorMessage ="content_management.slug_availability_error";
    //   return throwError(errorMessage);
    // }
    const message = errorResponse.error.message;
    const standardErrorMessageTranslationKey = this.errorService.getCommonErrorMessageTranslationKey(message);
    if(standardErrorMessageTranslationKey){
      errorMessage = standardErrorMessageTranslationKey;
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);
  }
  setPreferredTranslationSourceLanguage(iso: string) {
    localStorage.setItem('preferredTranslationSourceLanguage', iso);
  }
  getPreferredTranslationSourceLanguage() : string | null {
    return localStorage.getItem('preferredTranslationSourceLanguage');
  }
}
