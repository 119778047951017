import { Component, EventEmitter, Input, OnChanges, Output, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth';
import { PaginatedDeliveries } from '../paginated-deliveries.model';
import { MediaService } from '../../content';
import { AvailableLanguage, LanguageService } from '../../language';
import { WindowService } from '../../utilities';
import { Subscription } from 'rxjs';
import { ContributionsService } from '../contributions.service';
import { LinksService } from '@frontend/shared';
import { Link } from '@frontend/core';
import { Delivery } from '../delivery.model';


@Component({
  selector: 'multisite-contributions-home',
  templateUrl: './contributions-home.component.html',
  styleUrls: ['./contributions-home.component.scss']
})
export class ContributionsHomeComponent implements OnChanges {

  loadingObject: {[key:string]:boolean} = {}; // .topic .course .personalisedData
  private subscriptions: Subscription[] = [];
  interculturalistRoleAlertComments: boolean = false;
  error = null;
  paginatedDeliveries : PaginatedDeliveries;
  activeLanguageObject : AvailableLanguage;
  thumbnailTransformations: string = 'ar_16:9,w_700/';
  cloudinary_base_url : string;
  links : Link[] = [];
  
  constructor(
    private route : ActivatedRoute,
    private router : Router,
    private authService : AuthService,
    private mediaService : MediaService,
    private languageService : LanguageService,
    private windowService : WindowService,
    private contributionsService : ContributionsService,
    private linksService : LinksService,
  ) {
    this.cloudinary_base_url = this.mediaService.cloudinary_base_url;

    
  }

  getFallbackFlagUrl (){
    return this.mediaService.fallback_flag_round_url;
  }
  gotoDelivery(delivery : Delivery){
    if(!delivery){return;}
    if (delivery.task.slug == 'insight-subject-written-short'){
      const queryParams = {};
      if (delivery.source_lang) {
        queryParams['source_lang'] = delivery.source_lang;
      }
      if (delivery.target_lang) {
        queryParams['target_lang'] = delivery.target_lang;
      }
      this.router.navigate(['/contributions/insights/create/', delivery.insight?.slug], { queryParams });
    } else if (delivery.task.slug == 'difference-vote-cco'){

      this.router.navigate(['/contributions/differences/vote/', delivery.votes?.[0]?.id]);
    }

  }

  isAnythingLoading(){
    for (let something in this.loadingObject){
      if (this.loadingObject[something]){
        return true;
      }
    }
    return false;
  }
  hasRole (roleName:string){
    return this.authService.checkRole(roleName); // for example "Admin"
  }
  getFlagUrlFromHash(hash:string,transformations : string = ''){
    return this.mediaService.getFlagUrlFromHash(hash,'',true,'.png');
  }
  getDeliveries(page:number = 1, freshFromServer : boolean = false){
    this.loadingObject['initialData'] = true;
    const paginatedDeliveriesSubscription = this.contributionsService.getData("myPaginatedDeliveries", {}, {page,items_per_page:20}).subscribe(reportData => {
      this.paginatedDeliveries = reportData.data as PaginatedDeliveries;
      this.loadingObject['initialData'] = false;
    }, error => {
      this.error = error;
      this.loadingObject['initialData'] = false;
    });
  }
  doGetPaginatedDeliveries(page:number = 1, freshFromServer : boolean = false){
    this.getDeliveries(page, freshFromServer);
  }
  getLinks(){
    let linksRequest = [
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
      {slug:'demo',class:'demo',topic:'demo',site:'demo',subdomain:'demo'},
    ]
    const linksSubscription = this.linksService.getDynamicLinks(linksRequest).subscribe(links =>{
      this.links = links;
    });
    this.subscriptions.push(linksSubscription);
  }
  

  initialise (){
    this.getDeliveries();
    this.getLinks();
   
  }

  ngOnInit() {
    this.windowService.goToTop();
    this.activeLanguageObject = this.languageService.activeLanguageObjectSynchronously;
    const activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
        // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
        if (newActiveLanguage?.languageKey !== this.activeLanguageObject.languageKey){
          this.activeLanguageObject = newActiveLanguage;
          if (this.paginatedDeliveries){
            this.getDeliveries();
            this.getLinks();
          }
        }
    });
    this.initialise();
  }

  ngOnChanges(changesObject) : void {

  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


}
