import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { PaginatedComments } from './comments-paginated.model';
import { of } from 'rxjs';

/* nterface commentsResponse {
  'data': Comment[],//{'likes': UserExtraLite[],'count':number, 'liked': boolean, 'id': number, 'identifier': string},
  'links': any,
  'meta': any
} */

@Injectable({ providedIn: 'root' })
export class CommentsService {

  commentsByModel : any;
  
  constructor(
    private http: HttpClient
    ) {
      this.commentsByModel = {};
    }

    getPlaceholder (){ // a dummy comment
      return {
        comment: 'Exercitation ut labore anim id ullamco ea est amet labore incididunt qui sunt voluptate.',
        id: 44,
        created_at : new Date(),
        commenter : {
          id: 1002,
          fname: 'Jim',
          lname: 'Perkins',
          pre_honorific : '',
          post_honorific : '',
          name_full_honorific : 'Jim Perkins',
          picture: 'https://i.pravatar.cc/300',
          slug: 'jim-perkins',
        },
        picture: 'https://i.pravatar.cc/150?u=a042581f4e29026704d',
        slug: 'this-is-a-comment-but-why-does-it-need-a-slug',
      };
    }

  
  cacheComments (comments : PaginatedComments, modelNameSingular : string, identifier, page : number){
    if (!this.commentsByModel[modelNameSingular]){
      this.commentsByModel[modelNameSingular] = {};
    };
    if (!this.commentsByModel[modelNameSingular][identifier]){
      this.commentsByModel[modelNameSingular][identifier] = {};
    };
    this.commentsByModel[modelNameSingular][identifier][page] = comments;
  }

  getCachedComments (modelNameSingular,identifier,page){
    let cachedComments : PaginatedComments = null;
    if (this.commentsByModel[modelNameSingular] && this.commentsByModel[modelNameSingular][identifier] && this.commentsByModel[modelNameSingular][identifier][page]){
      return this.commentsByModel[modelNameSingular][identifier][page];
    }
    return cachedComments
  };

  clearCachedComments (modelNameSingular,identifier){
    if (this.commentsByModel[modelNameSingular]){
      this.commentsByModel[modelNameSingular][identifier] = null;
    }
  }

  transformComments (commentsArray){
    if (!commentsArray || !commentsArray.length ){ return [];};
    // let transformedComments = [];
    commentsArray.forEach((comment,index,array) => {
      comment.created_at = new Date(comment.created_at);
    });
    return commentsArray;
  }
  postAComment (modelNameSingular : string, identifier : number, message : string){
    let params = {
      'commentable_type' : modelNameSingular,
      'commentable_id' : identifier,
      'message' : message
    };
    return this.http.post<PaginatedComments>(
      'api/v1/comments', params)
        .pipe(
          map(response =>{
            if (response && response.data ){
              this.clearCachedComments (modelNameSingular,identifier)
              return response.data;
            };
          })
        )
  }

  getComments (
    modelNameSingular : string,
    identifier, page : number,
    publishers: boolean,
    authenticated: boolean, // comments intended for authenticated users only
    items_per_page : number, // Must be fixed!*
    sort_by : string, // 'created_at' or 'upvotes' // Must be fixed!*
    sort_order : string, // 'asc' or 'desc' // Must be fixed!*
    freshFromServer : boolean,
  ){
    
    // * "Must be fixed": Until we develop the caching logic more, we cannot allow the user to dynamically changes these values.

    /* publishers
     if true will return only comments by people who have the right to publish social interactions, for example users with the "interculturalist" role
    if publishers is empty, the backend will default to true; 
    */


    items_per_page = items_per_page ?? 10;
    sort_by = sort_by ?? 'created_at';
    sort_order = sort_order ?? 'desc' 
    page = page ? page : 1;

    let cachedComments : PaginatedComments;
    
    if (!freshFromServer) {
      cachedComments = this.getCachedComments (modelNameSingular,identifier,page);
    }
    if (cachedComments){
      return of(cachedComments);
    };
    let url = authenticated ? 'api/v1/user-comments/class/' : 'api/v1/comments/class/';
    url = url+modelNameSingular+'/'+identifier+'?&page='+page
                                                  +'&publishers='+publishers
                                                  +'&items_per_page='+items_per_page
                                                  +'&sort_by='+sort_by
                                                  +'&sort_order='+sort_order
                                                  ;
    /* for example 
      'api/v1/comments/class/episode/how-to-train-online?&page=2&publishers=false;
      'api/v1/user-comments/class/tip/inviting-participants?&page=1&publishers=;
    */
    return this.http.get<PaginatedComments>(url)
        .pipe(
          map(response =>{
            if (response && response.data ){
              response.data = this.transformComments(response.data);
              this.cacheComments(response, modelNameSingular, identifier, page);
              return response;
            };
          })
        )
  };
  setCachedPostingOfCommentForAfterAuthentication (modelNameSingular : string, identifier : number, message : string){
    localStorage.setItem('cachedCommentForAfterAuthentication',JSON.stringify([modelNameSingular, identifier, message]))
  }
  getCachedPostingOfCommentForAfterAuthentication (){
    let cachedComment = localStorage.getItem('cachedCommentForAfterAuthentication');
    if (cachedComment){
      localStorage.removeItem('cachedCommentForAfterAuthentication');
      return {
        'modelNameSingular': JSON.parse(cachedComment)[0],
        'identifier': JSON.parse(cachedComment)[1],
        'message': JSON.parse(cachedComment)[2]
      };
    };
  }

}
