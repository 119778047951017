<div class="national-culture-labels" *ngIf="culturesWithVariants()">
  <!-- <div class="row"> -->
  <!-- <div class="visualisation-labels"> -->
    <!-- <div class="col-lg-6 mb-4"> -->
    <div class="visualisation">
      <div [class]="'national-culture-visualisation' + (culturesWithVariants().length > 1 ? '-group' : '-single')">
        <multisite-national-culture-visualisation
          *ngIf="culturesWithVariantsSubGroup()[0]"
          [cultureWithVariants]="culturesWithVariantsSubGroup()[0]"
          [selectedVariantFiltersDynamic]="selectedVariantFilters()"
          [showIcons]="showIcons()"
          [hideNumberLabels]="true"
          [hideTextLabels]="true"
          [showControls]="false"
          [cardHeight]="cardHeight()"
          [cardWidth]="cardWidth()"
          (click)="doSelectCulture(culturesWithVariantsSubGroup()[0])"
          ngClass="left"
          >
        </multisite-national-culture-visualisation>

        <div [class]="culturesWithVariants().length > 1 ? 'mid' : ''">
          <multisite-national-culture-visualisation
            [cultureWithVariants]="selectedCulture()"
            [selectedVariantFiltersDefault]="selectedVariantFiltersDefault()"
            (updateSelectedVariantFilters)="doUpdateSelectedVariantFilters($event)"
            [showIcons]="showIcons()"
            [cardHeight]="cardHeight()"
            [cardWidth]="cardWidth()"
            [hideNumberLabels]="hideNumberLabels()"
            [hideTextLabels]="hideTextLabels()"
            [labelType]="labelType()"
            [highlightMass]="highlightMass()"
            (toggleShowFilters)="doToggleShowFilters()"
            [showFilters]="showFilters()"
            [showControls]="showControls()"
            [autoPlayFilterLoop]="autoPlayFilterLoop()"
            [autoPlayFilterLoopLimit]="autoPlayFilterLoopLimit()"
            >
          </multisite-national-culture-visualisation>
        </div>

        <multisite-national-culture-visualisation
          *ngIf="culturesWithVariantsSubGroup()[1]"
          [cultureWithVariants]="culturesWithVariantsSubGroup()[1]"
          [selectedVariantFiltersDynamic]="selectedVariantFilters()"
          [showIcons]="showIcons()"
          [cardHeight]="cardHeight()"
          [cardWidth]="cardWidth()"
          [hideNumberLabels]="true"
          [hideTextLabels]="true"
          [showControls]="false"
          (click)="doSelectCulture(culturesWithVariantsSubGroup()[1])"
          ngClass="right"
          >
        </multisite-national-culture-visualisation>

      </div>
    </div>
    @if(show_legend){

    <!-- <div class="col-lg-6"> -->
    <!-- <div class="pat-2"> -->
      <div class="legend">

        @for(massVariantCollection of selectedMassVariantCollectionsOfCollections(); track massVariantCollection; let
        first = $first){
        @if(massVariantCollection.variants?.length){
        <div>
          <h5>
            <span *transloco="let t; scope : 'diversity'">{{t(massVariantCollection.titleTranslationKey)}}</span>
            @if(massVariantCollection.helptextTranslationKey1 || massVariantCollection.helptextTranslationKey2){
            <ng-template #popTemplate>
              <p *transloco="let t; scope : 'diversity'">
                @if(massVariantCollection.helptextTranslationKey1){
                {{t(massVariantCollection.helptextTranslationKey1)}}
                }
                @if(massVariantCollection.helptextTranslationKey2){
                {{t(massVariantCollection.helptextTranslationKey2)}}
                }
              </p>
            </ng-template>
            <button type="button" class="btn btn-link-gray active border-0" [tooltip]="popTemplate">
              <i class="fa-regular fa-info-circle"></i>
            </button>
            }
          </h5>
          @if(first || !selectedMassVariantCollectionsOfCollections()[0]?.variants?.length){
          <div class="d-flex align-items-center justify-content-between w-100">
            <a (click)="doToggleShowLegendDetails()" *transloco="let t"
              class="btn btn-link-gray active border-0 d-flex gap-1">
              <!-- {{
                show_legend_details()
                ? t('common.show_less')
                : t('common.show_more')
                }} -->
              <span *ngIf="!show_legend_details()"><i class="fa-regular fa-square"></i></span>
              <span *ngIf="show_legend_details()"><i class="fa-regular fa-square-check"></i></span>
              <span *transloco="let t">{{t('common.details')}}</span>
            </a>

            <multisite-toggle-switch
              [active]="labelType() === 'name'"
              (toggle)="doToggleLabelType()"
              [optionAiconClasses]="'fa-solid fa-circle-1'"
              [optionBiconClasses]="'fa-solid fa-text'"
              >
            </multisite-toggle-switch>

          </div>
          }
        </div>
        <div class="label-group">
          @for(variant of massVariantCollection.variants; track variant.id){

          <div class="label">
            <span
              [ngClass]="{'segment-pointer': massVariantCollection.titleTranslationKey == 'diversity.in_focus'}"></span>
            <div class="title">
              @if(!hideNumberLabels() && labelType() === 'number'){
              <div class="label-round">
                {{variant.number}}
              </div>
              }
              <div class="h6" *transloco="let t; scope:'diversity'">
                @if(variant.name){
                {{variant.name}}
                } @else {
                {{t(variant.name_translation_key)}}
                }
              </div>
            </div>
            @if(show_legend_details()){
            <p class="text" *transloco="let t; scope:'diversity'">
              @if(variant.description){
              {{variant.description}}
              } @else {
              {{t(variant.description_translation_key)}}
              }
            </p>
            }
          </div>
          }
        </div>
        }
        }
      </div>
    <!-- </div> -->
    }
  </div>
<!-- </div> -->