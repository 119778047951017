@if((!collapsed() || (collapsed() && !collapsedPrefixText() &&
!collapsedPrefixTranslationKey())) && (titleText() || titleTranslationKey())){
<p [ngClass]="titleClasses()">
  {{
    titleText() ??
      (titleTranslationKey() | transloco: { scope: titleTranslationScope() })
  }}
</p>
}
<div class="inline-pill-picker">
  @if(collapsed() && (collapsedPrefixText() ||
  collapsedPrefixTranslationKey())){
  <span>
    {{
      collapsedPrefixText() ??
        (collapsedPrefixTranslationKey()
          | transloco: { scope: collapsedPrefixTranslationScope() })
    }}
  </span>
  }
  @if(pillType() === 'avatar'){
    @for(item of collapsibleItems(); track
      item[selectedPropertyKey()]){
      <multisite-avatar-pill
        (selected)="doOnSelect(item)"
        [fallbackImageUrl]="getFlagUrlFromHash(item)"
        [fallbackTranslationKey]="'tracking.missing'"
        [cursorNone]="disabled()"
        [imageUrl]="getFlagUrlFromHash(item)"
        [active]="selectedItemIdentifiers()?.includes(item[selectedPropertyKey()])"
        [translationKey]="item[labelPropertyKey()]"
        [removalMode]="false"
        [compact]="compact()"
        [clickable]="!disabled()"
      ></multisite-avatar-pill>
    }
  } @else if (pillType() === 'icon'){
    @for(item of collapsibleItems(); track item[selectedPropertyKey()]){

      <multisite-icon-pill
        (click)="doOnSelect(item)"
        [icon]="getIcon(item)"
        [labelText]="item.name"
        [selectable]="disabled() ? false : true"
        [active]="selectedItemIdentifiers()?.includes(item[selectedPropertyKey()])"
        [inverted]="false"
        [shape]="'round'"
        [compact]="true"
      ></multisite-icon-pill>
    }
  }
  @if(collapsedItemCount() > 0 && items()?.length > collapsedItemCount()){
    <a *transloco="let t" (click)="doToggleCollapse()" class="d-flex">
      <span class="btn btn-pill" [ngClass]="{ disabled: disabled() }">
        @if(collapsed()){
        <span class="icon small">
          <i class="fa-solid fa-plus"></i>
        </span>
        } @else {
        <span class="icon small">
          <i class="fa-solid fa-minus"></i>
        </span>
        }
        {{ collapsed() ? t('common.show_more') : t('common.show_less') }}
      </span>
    </a>
  }
</div>
