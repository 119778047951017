import { Component, computed, input } from '@angular/core';
import { Icon } from '../../icons';
import { signal } from '@angular/core';
import { MediaService } from '@frontend/common';

@Component({
  selector: 'multisite-icon-pill',
  templateUrl: './icon-pill.component.html',
  styleUrls: ['./icon-pill.component.scss']
})
export class IconPillComponent {

  labelTranslationKey =input<string | undefined>(undefined); // 'quiz.test_your_knowledge'
  labelText =input<string | undefined>(undefined); // 'Test your knowledge'
  fallbackIconClasses =input<string>(''); // 'fa-solid fa-clock'
  imageTransformations = input<string>('w_32,c_fill,ar_1:1,r_max,f_auto/');
  icon =input<Icon|undefined>(undefined);
  iconClasses = computed<string>(() => {
    if (this.icon()) {
      return this.icon().identifier_1 ?? null;
    } else {
      return this.fallbackIconClasses();
    }
  });
  imgUrl = computed<string>(() => {
    return this.icon()?.identifier_2 ? this.mediaService.getIconUrlFromIconIdentifier2(this.icon().identifier_2,this.imageTransformations()):null;
  });
  selectable =input<boolean>(false); // sets 'selectable' class
  active =input<boolean>(false); // sets 'active' class
  compact =input<boolean>(false);
  shape =input<string>('square'); // 'square' or 'round' sets the class icon-pill-square or icon-pill-round
  inverted =input<boolean>(false); // sets 'inverted' class

  constructor(private mediaService : MediaService) { }

  getUrl(icon: Icon) : string {

    return this.mediaService.getIconUrlFromIconIdentifier2(icon.identifier_2,this.imageTransformations());

   }

}
