import { Component, computed, input, output, signal } from '@angular/core';
import { Cluster, Culture, Language, MediaService, Topic } from '@frontend/common';
import { Icon } from '../../icons';

@Component({
    selector: 'multisite-inline-pill-picker',
    templateUrl: './inline-pill-picker.component.html',
    styleUrls: ['./inline-pill-picker.component.scss']
})
export class InlinePillPickerComponent {
    items = input.required<Language[] | Culture[] | Topic[] | Cluster[]>(); // must have a 'name' property
    selectedItemIdentifiers = input<(number | string)[]>();
    selectedPropertyKey = input.required<string>(); // for example 'id' or 'iso
    collapsedItemCount = input<number>(3); // number of items to show in addition to the selected item when collapsed
    titleClasses = input<string | undefined>(); // for example 'h5' or 'text-muted
    titleText = input<string | undefined>();
    titleTranslationKey = input<string | undefined>();
    titleTranslationScope = input<string | undefined>();
    collapsedPrefixText = input<string | undefined>(); // text appears at start of list when collapsed
    collapsedPrefixTranslationKey = input<string | undefined>();
    collapsedPrefixTranslationScope = input<string | undefined>();
    disabled = input<boolean>(false);
    compact = input<boolean>(true);
    labelPropertyKey = input<string>('name'); // for example 'name' or 'title'
    collapsed = input<boolean>(false);
    collapsibleItems = computed(() => {
        
        const selectedItems = this.items() ? this.items().filter(item => this.selectedItemIdentifiers() && this.selectedItemIdentifiers().includes(item[this.selectedPropertyKey()])) : [];

        const collapsedItemCount = selectedItems.length && this.collapsedItemCount() > 0 ? (Math.min(this.collapsedItemCount(),selectedItems.length)) : this.collapsedItemCount();

        return this.collapsed() 
        ? selectedItems.concat(this.items().filter(item => !this.selectedItemIdentifiers()?.includes(item[this.selectedPropertyKey()])).slice(0, collapsedItemCount)) 
        : this.items();
    });
    collapseOnSelect = input<boolean>(false);

    onSelect = output<Language | Culture | Topic | Cluster>();
    onToggleCollapse = output<void>();

    pillType = input<'icon' | 'avatar'>('icon');

    constructor(private mediaService : MediaService) { }

    getFlagUrlFromHash(item,transformations : string = ''){
        let typedItem = item as Language | Culture;
        return this.mediaService.getFlagUrlFromHash(typedItem.flag?.hash,transformations,true,'.png');
    }
    getIcon(item) : Icon{
        let typedItem = item as Topic;
        return typedItem.icon;
    }

    doOnSelect(item: Language | Culture | Topic | Cluster) {
        this.onSelect.emit(item);
        if(this.collapseOnSelect() && !this.collapsed()){
            this.onToggleCollapse.emit();
        }
    }
    doToggleCollapse() {
        this.onToggleCollapse.emit();
    }
}