import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconPillComponent } from './icon-pill/icon-pill.component';
import { TranslocoModule } from '@jsverse/transloco';
import { IconComponent } from '../icons';



@NgModule({
  declarations: [
    IconPillComponent,
  ],
  exports: [
    IconPillComponent,
  ],
  imports: [
    TranslocoModule,
    CommonModule,
    IconComponent,
  ]
})
export class PillsModule { }
