import { Component, TemplateRef, ViewChild, computed, signal, AfterViewInit } from '@angular/core';
import { AuthService, AvailableLanguage, Consent, Guest, LanguageService, MediaService, User } from '@frontend/common';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ConsentService } from '../consent.service';


@Component({
  selector: 'multisite-consent-request-modal',
  templateUrl: './consent-request-modal.component.html',
  styleUrls: ['./consent-request-modal.component.scss']
})
export class ConsentRequestModalComponent implements AfterViewInit {

  user: User;
  guest: Guest;
  guestInLocalStorage: any;
  @ViewChild('consentRequestModal') consentRequestModal;
  modalRef?: BsModalRef;
  modalConfig = {
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    ariaLabelledBy: 'consent-request-modal',
  };
  consentSettingsRoute: string[] = ['/account/consent'];
  private promptSubscription : Subscription;
  private modalSubscription : Subscription;
  private consentUpdateSubscription : Subscription;
  private consentSubscription : Subscription;
  missingConsentSlugs = signal<string[]>([]);
  showCurrentConsentSetMoreInfo = signal<boolean>(false);
  showingDeclineWarning = signal<boolean>(false);

  currentConsent = computed(() => this.missingConsentSlugs()?.length ? this.consentService.getLatestConsent(this.missingConsentSlugs()[0]) : null);

  loadingObject = signal <{[key:string]:boolean}>({}); // .consents
  error = signal<string|{message:string,meta:any}|undefined>(undefined);
  activeLanguageSubscription: Subscription;
  activeLanguageObject: AvailableLanguage;
  availableLanguages: AvailableLanguage[];

  constructor(
    private modalService: BsModalService,
    private authService : AuthService,
    private router : Router,
    private consentService : ConsentService,
    private languageService : LanguageService,
    private mediaService : MediaService,
  ) {
    this.availableLanguages = this.languageService.availableLanguages;
  }

  checkMissingConsents(missingConsentSlugs: string[] = []){

    let previousMissingConsentSlugsLength = this.missingConsentSlugs()?.length;
    if(missingConsentSlugs?.length ){
      this.missingConsentSlugs.set(missingConsentSlugs);
      if(!previousMissingConsentSlugsLength){ // Really important! Do not open the modal if it is already open; causes horrible bugs
        this.openModal(this.consentRequestModal);
      }
    } else {
      this.missingConsentSlugs.set([]);
    }
  };

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,this.modalConfig);
    this.modalSubscription = this.modalRef.onHidden.subscribe((reason: string | any) => {
      if(typeof reason === 'string' && (reason === 'backdrop-click' || reason === 'esc')){
        this.router.navigate(this.consentSettingsRoute);
        this.showingDeclineWarning.set(false);
      }
    })
  }
  closeModal(){
    this.modalService.hide(this.consentRequestModal);
    this.modalRef.hide();
    this.router.navigate(this.consentSettingsRoute);
    this.showingDeclineWarning.set(false);
  }
  setActiveLanguage(languageKey) {
    this.languageService.setLanguage(languageKey);
  }
  getFlagUrl(availableLanguage:AvailableLanguage,transformations:string, file_extension:string){
    return this.mediaService.getFlagUrlFromHash(availableLanguage?.flag.hash,transformations,true,file_extension);
  }

  updateConsentStatus(consent: Consent, status: 'given' | 'declined' | 'withdrawn'){
    if(status === 'declined' || status === 'withdrawn'){
      if(this.showingDeclineWarning()){
        this.handleConsentStatusUpdate(consent, status);
      } else {
        this.showDeclineWarning(consent);
      }
    } else if (status === 'given'){
      this.handleConsentStatusUpdate(consent, status);
    }
  }
  handleConsentStatusUpdate(consent: Consent, status: 'given' | 'declined' | 'withdrawn'){
    this.error.set(null);
    this.loadingObject()['consents'] = true;
    this.consentUpdateSubscription = this.consentService.updateConsentStatus(consent, status).subscribe(
      (updatedConsent) => {
        this.loadingObject()['consents'] = false;
        this.showingDeclineWarning.set(false);
        if(status === 'given'){
          if(!this.missingConsentSlugs()?.length){
            this.modalService.hide(this.consentRequestModal);
            this.modalRef.hide();
          }
        } else {
          debugger;
          this.modalService.hide(this.consentRequestModal);
          this.modalRef.hide();
          this.router.navigate(this.consentSettingsRoute);
        }
      },
      (error) => {
        this.loadingObject()['consents'] = false;
        this.error.set(error);
      }
    );
  }

  showDeclineWarning(consent: Consent){
    this.showCurrentConsentSetMoreInfo.set(true);
    this.showingDeclineWarning.set(true);
  }


  ngAfterViewInit(): void {
    this.activeLanguageObject = this.languageService.activeLanguageObjectSynchronously;
    this.activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
      // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
      if (newActiveLanguage?.languageKey !== this.activeLanguageObject.languageKey){
        this.activeLanguageObject = newActiveLanguage;
      }
    })
    this.authService.user.subscribe(()=>{
      if(this.authService.user.getValue()){
        this.consentSubscription = this.consentService.getConsents(false).subscribe()
      }
    });
    this.consentService.missingConsentSlugs.subscribe((missingConsentSlugs)=>{
      return this.checkMissingConsents(missingConsentSlugs)
    });
  }

  // ngOnDestroy(): void { // if this component is in the footer, ngOnDestroy will never run

  //   if(this.promptSubscription){
  //     this.promptSubscription.unsubscribe();
  //   }
  //   if(this.modalSubscription){
  //     this.modalSubscription.unsubscribe();
  //   }
  // }


}
