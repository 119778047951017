import {
  Component,
  input,
  output,
  computed,
  SimpleChanges,
  signal,
} from '@angular/core';
import { TranslatablePair, User } from '@frontend/common';
import { TranslationService } from '@frontend/common';

type Poster = {
  fname: string;
  lname: string;
  picture: string;
}

@Component({
  selector: 'frontend-comment-form-type-two',
  templateUrl: './comment-form-type-two.component.html',
  styleUrls: ['./comment-form-type-two.component.scss'],
})
export class CommentFormTypeTwoComponent {
  user = input<User>();
  poster = computed(() => {
    if (this.user()) {
      return {
        fname: this.user().fname,
        lname: this.user().lname,
        picture: this.user().picture,
      };
    } else {
      return {
        fname: 'Not yet logged in',
        lname: 'Guest',
        picture: this.defaultAvatar,
      };
    }
  });
  loading = input<boolean>(false);
  loadingTranslation = signal<boolean>(false);
  disabled = input<boolean>(false);
  clearCommentForm = input<boolean | undefined>(undefined);
  postComment = output<string>();
  rows = input<number>(3);
  translatablePairs = input<TranslatablePair[]>([]); // Allow the user to translate FR->EN, for example


  message: string;
  defaultAvatar =
    'https://res.cloudinary.com/cebt/image/upload/v1616972586/users/avatars/default-avatar.gif';
    error = signal<string|{message:string,meta:any}|undefined>(undefined);
    errorString = computed(() => {
      if(this.error()){
        return typeof this.error() === 'string' ? this.error() : (this.error() as {message:string,meta:any}).message;
      }
    });
    errorObject = computed(() => {
      if(this.error()){
        return typeof this.error() === 'object' ? this.error() : null;
      }
    });

  constructor(private translationService:TranslationService) {}

  sendForTranslation(pair : TranslatablePair) {
    this.error.set(undefined);
    const trimmedMessage = (this.message ?? '').trim();
    const sourceLocale : string = this.translationService.getDefaultLocaleFromGenericLanguage(pair.source_lang.iso);
    const targetLocale : string = this.translationService.getDefaultLocaleFromGenericLanguage(pair.target_lang.iso);
    if(!trimmedMessage){
      return
    }
    this.getTranslation({message:trimmedMessage},targetLocale,sourceLocale);
  }
  getTranslation(textsAsObject:{[key:string]:string},target_lang : string, source_lang : string) {
    const keys = Object.keys(textsAsObject);
    const values = Object.values(textsAsObject);
    this.error.set(undefined);
    this.loadingTranslation.set(true);
    return this.translationService.getTranslation(textsAsObject,target_lang,source_lang).subscribe((translations : {[key:string]:string}) => {
    this.loadingTranslation.set(false);
    this.message = translations['message'];

    }, error => {
      this.loadingTranslation.set(false);
      this.error.set(error);
    }
  );
  }

  doPostComment() {
    const trimmedMessage = (this.message ?? '').trim();
    if(!trimmedMessage){
      this.message = '';
      return;
    }
    this.postComment.emit(this.message);
  }
  ngOnChanges(changesObject: SimpleChanges) {
    if (changesObject.clearCommentForm && changesObject.clearCommentForm.currentValue !== undefined) {
      this.message = '';
    }
  }

}
