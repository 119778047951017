<span class="icon-pill-{{shape()}} classic" [ngClass]="{active: active(), selectable: selectable(), inverted : inverted(), compact: compact()}" *transloco="let t">
  @if(iconClasses()){
    <span class="icon">
      <i [class]="iconClasses()"></i>
    </span>
  } @else if (imgUrl()) {
    <span class="icon">
      <img [src]="imgUrl()" class="imgIcon" [alt]="icon()?.name"/>
    </span>
  }
  <span>{{labelTranslationKey() ?  t(labelTranslationKey()) : labelText()}}</span>
</span>