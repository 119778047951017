import { Culture } from "../cultures";
import { CloudinaryMediaAsset } from "../models";
import { Subject } from "./subject.model";

export class Insight {
    id: number;
    slug: string;
    s: any;
    m: any;
    l: any;
    num: number | null;
    date: Date | null;
    category: string | null;
    type: string | null;
    subject: Subject;
    cultures: Culture[];
    released_at: Date | null;
    created_at: Date | null;
    updated_at: Date | null;
    // deleted_at: Date | null;
    media: CloudinaryMediaAsset[];

    constructor(
        id: number,
        slug: string,
        s: any,
        m: any,
        l: any,
        num: number | null,
        date: Date | null,
        category: string | null,
        type: string | null,
        subject: Subject,
        cultures: Culture[],
        released_at: Date | null,
        created_at: Date | null,
        updated_at: Date | null,
        // deleted_at: Date | null
        media: CloudinaryMediaAsset[],
    ) {
        this.id = id;
        this.slug = slug;
        this.s = s;
        this.m = m;
        this.l = l;
        this.num = num;
        this.date = date;
        this.category = category;
        this.type = type;
        this.subject = subject;
        this.cultures = cultures;
        this.released_at = released_at;
        this.created_at = created_at;
        this.updated_at = updated_at;
        // this.deleted_at = deleted_at;
        this.media = media;
    }

}