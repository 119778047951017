import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  input,
  output,
  signal,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Notification } from '@frontend/common';
import { MainNavigationLink } from '../main-navigation-link.model';
import { Brand, BrandDisplayService } from '../../branding';
import { AuthService, Guest, User, UserRole } from '../../auth';
import { Subscription } from 'rxjs';
import { AccountDropdownLink, AccountDropdownLinks, AvailableLanguage, LanguageService, MediaService } from '@frontend/common';

@Component({
  selector: 'main-navigation-type-two',
  templateUrl: './main-navigation-type-two.component.html',
  styleUrls: ['./main-navigation-type-two.component.scss'],
})
export class MainNavigationTypeTwoComponent implements OnInit {
  logout = output<void>();
  notificationSelected = output<Notification>();
  user = input<User | undefined>(undefined);
  guest = input<Guest | undefined>(undefined);
  darkModeOn = input<boolean>(false);  // when true, dark mode is permanently on
  currentBrandSubscription: Subscription;
  brand: Brand;
  logo = input<string | undefined>(undefined); // 'culturettt' or 'icpendulum'
  // @ViewChild('dropdown') dropdown!: MdbDropdownDirective;
  navCollapsed : boolean = true;
  navLinks = input<MainNavigationLink[] | undefined>(undefined);
  loading = input<boolean>(false);
  activeLanguageSubscription: Subscription;
  activeLanguageObject: AvailableLanguage;
  availableLanguages: AvailableLanguage[];
  scrollOffsetExceeded = input<boolean>(false); // true when the user has scrolled more than the height of the navbar (if implemented in the parent element)
  accountDropdownLinks : AccountDropdownLink[];
  notifications = input<Notification[]>([]);


  constructor(
    private router: Router,
    private brandDisplayService: BrandDisplayService,
    private authService: AuthService,
    private languageService: LanguageService,
    private mediaService: MediaService,
  ) {
    this.accountDropdownLinks = AccountDropdownLinks;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.hideNav();
      }
    });
    this.availableLanguages = this.languageService.availableLanguages;
  }
  handleClick(functionName:string){
    if(functionName){
      this[functionName]();
    }
  }
  doLogout() {
    this.logout.emit();
  }
  setActiveLanguage(languageKey) {
    this.languageService.setLanguage(languageKey);
  }
  navigateTo(target) {
    this.router.navigateByUrl(target);
  }
  hideNav() {
    this.navCollapsed = true;
  }
  isAdmin() {
    return this.authService.checkRole('Admin');
  }
  hasRole(roleName: string) {
    return this.authService.checkRole(roleName); // for example "Admin"
  }

  darkModeActive() {
    return this.darkModeOn() || this.brandDisplayService.darkMode;
  }
  getLogoFile(type: string) {
    if (type === 'logo_banner') {
      if (this.darkModeActive() && this.brand?.logo_banner_inverse) {
        return this.brand.logo_banner_inverse;
      } else {
        return this.brand.logo_banner;
      }
    } else {
      if (this.darkModeActive() && this.brand?.logo_square_inverse) {
        return this.brand.logo_square_inverse;
      } else {
        return this.brand.logo_square;
      }
    }
  }
  getDefaultAvatar (){
    return this.mediaService._fallback_avatar_url;
  }

  onTestDropdownHidden(): void {
    console.log('Dropdown is hidden');
  }
  onTestDropdownShown(): void {
    console.log('Dropdown is shown');
  }
  isTestDropdownOpenChange(): void {
    console.log('Dropdown state is changed');
  }
  onNotificationSelected(notification: Notification) {
    if(!notification){return;}
    this.notificationSelected.emit(notification);
  }
  getFlagUrl(availableLanguage:AvailableLanguage,transformations:string, file_extension:string){
    return this.mediaService.getFlagUrlFromHash(availableLanguage?.flag.hash,transformations,true,file_extension);
  }
  filteredAccountDropdownLinks(accountDropdownLinks: AccountDropdownLink[], userRoles : UserRole[]) {
    return accountDropdownLinks && userRoles ? accountDropdownLinks.filter(link => link.filteredRoles?.length < 1 || this.authService.getMatchingUserRoles(userRoles,link.filteredRoles).length) : [];
  }
  filterRoles(roles : UserRole[],arrayOfRoleTitles : string[]){
    return this.authService.getMatchingUserRoles(roles,arrayOfRoleTitles);
  }

  ngOnInit(): void {
    this.currentBrandSubscription =
      this.brandDisplayService.currentBrand.subscribe((brand) => {
        if (brand) {
          this.brand = brand;
        } else {
          this.brand = null;
        }
      });
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(
        (activeLanguageObject) => {
          this.activeLanguageObject = activeLanguageObject;
        }
      );
  }
}
