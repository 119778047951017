<!-- <nav class="navbar navbar-light py-0" [ngClass]="user() ? 'navbar-expand-custom navbar-loggedin':'navbar-expand-sm navbar-loggedout'"> -->
  <!-- FOR NEW NAVIGATION STYLE CLASS ADDED: nav-reset, nav-bg-dark -->
  <nav class="navbar navbar-light py-0 navbar-expand-md nav-reset "
    [ngClass]="{
      'navbar-loggedin': user(),
      'navbar-loggedout': !user(),
      'nav-bg-active': scrollOffsetExceeded()
    }">
    <!-- FOR NEW NAVIGATION STYLE CLASS changed: container-fluid to container -->
    <div class="container">
      <a class="navbar-brand" [ngClass]="{'light-mode':!darkModeActive()}" routerLink="/" [attr.aria-label]="'Home'">
        <multisite-website-logos [logo]="logo" *ngIf="!brand?.logo_banner"></multisite-website-logos>
        <img *ngIf="brand?.logo_banner" [src]="getLogoFile('logo_banner')" height="60px" width="auto" [alt]="brand.name" class="logo-banner">
        <img *ngIf="brand?.logo_square" [src]="getLogoFile('logo_square')" height="60px" width="auto" [alt]="brand.name" class="logo-square">
      </a>
      <button
        class="navbar-toggler"
        type="button"
        (click)="navCollapsed = !navCollapsed"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars"></i>
      </button> 
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav" [collapse]="navCollapsed" [isAnimated]="true">
        <ul class="navbar-nav" *transloco="let t">
          <li class="nav-item" *ngFor="let link of navLinks()" [ngClass]="{'hide' : 
          (!user() && link.condition == 'hideIfNoUser') ||
          (!user() && !guest() && link.condition == 'hideIfNoUserAndNoGuest') ||
          (!hasRole('Admin') && link.condition == 'hideIfNotAdmin') ||
          (!hasRole('Developer') && link.condition == 'hideIfNotDeveloper')
        }">
            <a class="nav-link" aria-current="page" [routerLink]="link.routerLinkValue"
              routerLinkActive="active">{{link.labelLanguageKey ? t(link.labelLanguageKey) : link.labelText}}</a>
          </li>

        <li class="nav-item d-md-none" *ngIf="user()">
          <div class="profile-details" dropdown>
            <a class="nav-link dropdown-toggle" id="navbarUserDropdown" role="button" dropdownToggle
              (click)="false" aria-expanded="false" aria-controls="navbarUserDropdown-dropdown">
              <div class="user()-details">
                <div class="d-flex align-items-center gap-2">
                  <img class="user()-profile-img" [src]="user()?.picture ? user().picture : getDefaultAvatar()" alt="Profile image">
                  <p class="name h5 text-wrap">{{user()?.fname+' '+user()?.lname}}</p>
                </div>
                <!-- ICON DROPDOWN -->
                <span class="icon">
                  <i class="fa-solid fa-angle-down"></i>
                </span>
                <!-- END OF ICON DROPDOWN -->
              </div>
            </a>
            <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
            aria-labelledby="button-animated">
            <li *ngFor="let link of filteredAccountDropdownLinks(accountDropdownLinks, user()?.roles)" [ngClass]="{'dropdown-header' : link.isHeader}">
              <p *ngIf="link.isHeader">{{t(link.labelTranslationKey)}}</p>
              <hr *ngIf="link.isDivider" class="dropdown-divider">
              <a class="dropdown-item large" [routerLink]="link.routerLinkArray" routerLinkActive="active" (click)="handleClick(link.clickHandler)" *ngIf="!link.isDivider && !link.isHeader">
                <span class="icon-holder">
                  <i [class]="link.iconClasses"></i>
                </span>
                <div>
                  <p>{{t(link.labelTranslationKey)}}</p>
                </div>
              </a>
            </li>
          </ul>
          </div>
        </li>

          <li class="nav-item align-items-center" *ngIf="hasRole('Admin')">
            <div class="splitter"></div>
          </li>
         
          <li class="nav-item languages dropdown">
            <span dropdown>
              <a *ngIf="activeLanguageObject" class="nav-link dropdown-toggle" id="navbarAlternativeDropdown" role="button"
                dropdownToggle (click)="false" [attr.aria-label]="activeLanguageObject.flag_class" aria-expanded="false"
                aria-controls="navbarAlternativeDropdown-dropdown">
                <img [src]="getFlagUrl(activeLanguageObject,'w_24,c_fill,ar_1:1,r_max,f_auto/','.png')" [alt]="t(activeLanguageObject.name)" class="mr-1">
              </a>
              <ul id="navbarAlternativeDropdown-dropdown" *dropdownMenu class="dropdown-menu" role="menu"
                aria-labelledby="navbarAlternativeDropdown">
                <li *ngFor="let availableLanguage of availableLanguages">
                  <a class="dropdown-item" (click)="setActiveLanguage(availableLanguage.languageKey)">
                    <img [src]="getFlagUrl(availableLanguage,'w_16,c_fill,ar_1:1,r_max,f_auto/','.png')"
                      [alt]="t(availableLanguage.name)" class="mr-1">
                    {{t('language.'+availableLanguage.languageKey)}}
                  </a>
                </li>
              </ul>
            </span>
          </li>
          
          <!-- DUPLCIATE CODE start -->
          <li class="nav-item d-none d-md-block" *ngIf="user()">
            <div class="profile-details" dropdown>
              <a class="nav-link dropdown-toggle" id="navbarUserAlternativeDropdown" role="button" dropdownToggle
                (click)="false" aria-expanded="false" aria-controls="navbarUserAlternativeDropdown-dropdown">
                <div class="user()-details">
                  <div class="d-flex align-items-center gap-2">
                    <img class="user()-profile-img" [src]="user()?.picture ? user().picture : getDefaultAvatar()" alt="Profile image">
                    <!-- <p class="name h5 text-wrap">{{user()?.fname+' '+user()?.lname}}</p> -->
                  </div>
                  <!-- ICON DROPDOWN -->
                  <span class="icon light">
                    <i class="fa-solid fa-angle-down"></i>
                  </span>
                  <!-- END OF ICON DROPDOWN -->
                </div>
              </a>
              <ul id="dropdown-animated-alternative" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
              aria-labelledby="button-animated">
              <li *ngFor="let link of filteredAccountDropdownLinks(accountDropdownLinks, user()?.roles)" [ngClass]="{'dropdown-header' : link.isHeader}">
                <p *ngIf="link.isHeader">{{t(link.labelTranslationKey)}}</p>
                <hr *ngIf="link.isDivider" class="dropdown-divider">
                <a class="dropdown-item large" [routerLink]="link.routerLinkArray" routerLinkActive="active" (click)="handleClick(link.clickHandler)" *ngIf="!link.isDivider && !link.isHeader">
                  <span class="icon-holder">
                    <i [class]="link.iconClasses"></i>
                  </span>
                  <div>
                    <p>{{t(link.labelTranslationKey)}}</p>
                  </div>
                </a>
              </li>
            </ul>
            </div>
          </li>
          <!-- DUPLCIATE CODE end -->

          <li class="nav-item" *ngIf="!user()">
            <button class="nav-link btn btn-link text-decoration-none" id="navbarLogging" role="button" [routerLink]="['/login']" routerLinkActive="active" [disabled]="loading()">
              <i class="fa-solid fa-right-from-bracket"></i><span class="nav-link-mobile pl-2">{{ t('common.log_in')}}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>