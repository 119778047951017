import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ScoreHighlightComponent } from './score-highlight.component';

@NgModule({
  declarations: [ScoreHighlightComponent],
  imports: [
    CommonModule,
    TranslocoModule,
  ],
  exports: [ScoreHighlightComponent],
})
export class ScoreHighlightModule {}
