<div *transloco="let t">
  <div class="bell-curve-group">
    @if(visualisation() === 'bell_curve'){
      <div
        [style.transform]="'translateX(' + (((getSliderValue() / max()) * sliderInput.offsetWidth) - (sliderInput.offsetWidth * (bellCurveRelativeWidth/100))/2 )+ 'px)'"
        class="bell-curve" [ngClass]="{'active':sliderActive}" *ngIf="bellCurveRelativeScaleY > 0">
        <multisite-bell-curve ngClass="bell-position"
          [widthRelativeToParentContainer]="bellCurveRelativeWidth"
          [scaleY]="bellCurveRelativeScaleY">
        </multisite-bell-curve>
      </div>
    } @else if(visualisation() === 'icon_array'){
      <multisite-icon-array [selectedValue]="inputValue()" (setValue)="setValueByValue($event)"  class="mb-4"
        [iconClasses]="'fa-solid fa-person'" [numberOfIcons]="25" [max]="100">
      </multisite-icon-array>
    }
    <div class="form-bell-curve">

      <!-- <span>{{setElementHeightProperty ()}}</span> -->
      <div [ngClass]="{'input-container':true}">
        <!-- There u can put top level label -->
        <!-- --element-height should have value of ".label-holder" height -->
        <div class="range-input-column-layout" data-role="rangeslider"
          style="--element-height: {{labelHolder.offsetHeight}}px;">
          <div class="slider-holder" style="--slider-width: {{stepAsPercentage() > 5 ? (100-stepAsPercentageAdjustedForGaps()): 100}}%;">
            <div class="placeholder-thumb" *ngIf="!isValidValue() && sliderThumbImageUrl()"><i class="fa-solid fa-location-question fa-xl"></i></div>
              <input
                type="range"
                #sliderInput
                [value]="inputValue()"
                id="range-input"
                step="1"
                [min]="min()"
                [max]="max()"
                (change)="onValueChange($event.target.value)"
                class="form-range range-input"
                [ngClass]="{'range-input-custom': sliderThumbImageUrl()}"
                [ngStyle]="{'--thumb-image-url': 'url(' + sliderThumbImageUrl() + ')', '--thumb-opacity': isValidValue() ? 1 : 0}"
                (mousedown)="onSliderMouseDown()"
                (mouseup)="onSliderMouseUp()"
                [disabled]="sliderDisabled()"
                >
          </div>
          <div class="label-holder" #labelHolder>
            @for(anchor of anchorSet(); track anchor; let index = $index){
              <label class="align-center" [ngClass]="{'active': isAnchorActive(index), 'label-clickable' : sliderDisabled() === false}"
                (click)="setValueByLabel(index)">
                @if(!anchor){
                  <span style="opacity: 0;" aria-hidden="true">No label</span>
                } @else if(anchorSetTranslationKeys()){
                  {{t('survey.anchor.'+anchor, {scope: anchorSetTranslationScope()} )}}
                } @else {
                  {{anchor}}
                }
                <div class="benchmark-holder-preview">
                  @if(groupedBenchmarks() && groupedBenchmarks().length === anchorSet().length){
                    <div class="d-flex align-items-start">
                      @for(benchmark of groupedBenchmarks()[index].items; track benchmark; let index = $index){
                        <img
                          class="rounded-circle m-1"
                          [tooltip]="benchmark.culture.name"
                          [src]="
                          getFlagUrlFromHash(benchmark.culture,'w_32,c_fill,ar_1:1,r_max,f_auto/')
                          "
                          [alt]="benchmark.culture.name"
                          width="16"
                          height="16"
                        />
                      }
                    </div>
                  }
                </div>
              </label>
            }
          </div>
          
          <div class="benchmark-holder w-100 mt-4">
            <div class="d-flex align-items-start w-100">
              @for(benchmark of groupedBenchmarks(); track benchmark.value; let index = $index){
                 <div class="d-flex flex-column align-items-center w-100">
                 @for(item of benchmark.items; track item.culture_id){
                   <img
                     class="rounded-circle mb-2"
                     [tooltip]="item.culture.name"
                     [src]="
                     getFlagUrlFromHash(item.culture,'w_32,c_fill,ar_1:1,r_max,f_auto/')
                     "
                     [alt]="item.culture.name"
                     width="24"
                     height="24"
                   />
                 }
                 </div>
              }
            </div>
          </div>
          

        </div>
        <!-- There u can put bottom level label -->
      </div>
    </div>
  </div>
</div>