import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ActivityLogService, Comment } from '@frontend/core';
import { PaginatedComments } from '@frontend/shared';
import { CommentsService } from '@frontend/shared';
import { LinksService } from '@frontend/shared';
import { Subscription, combineLatest, filter, forkJoin, of } from 'rxjs';
import { AuthService, User } from '../../auth';
import { Lesson } from './lesson.model';
import { LessonService } from '../lesson.service';
import { PageTitleService } from '../../navigation/title/title.service';
import { CourseModulesByCourse, CourseService } from '../course.service';
import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';
import { CourseModule } from '../course-module.model';
import { LessonExtraLite } from './lesson-extra-lite.model';
import { PlaylistService, PlaylistTextItem, PlaylistTextItemGroup, TopicService } from '../../content';
import { Experience, Progressable } from '../../tracking';
import { DataProcessingService } from '../../utilities/data-processing.service';
import { ActivityLogParams, TrackingService } from '../../tracking/tracking.service';
import { SurveysService } from '../../surveys/surveys.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Guest } from '../../auth/user/guest.model';
import { AvailableLanguage, LanguageService } from '../../language';
import { Course } from '../course.model';

@Component({
  selector: 'multisite-lesson',
  templateUrl: './lesson.component.html',
  styleUrls: ['./lesson.component.scss']
})
export class LessonComponent implements OnInit {

  @ViewChild('niceInfoModal') niceInfoModal;
  @ViewChild('lessonTabs', { static: false }) lessonTabs?: TabsetComponent;
  activeLanguageObject : AvailableLanguage;
  defaultActivityLogParams: ActivityLogParams;
  modalRef?: BsModalRef;
  isCollapsedLessons = false;
  lesson : Lesson;
  lessonSlug : string;
  nextLesson : LessonExtraLite;
  previousLesson : LessonExtraLite;
  courseModules : CourseModulesByCourse;
  course : Course;
  courseSlug : string;
  module: CourseModule;
  playlist : PlaylistTextItemGroup[];
  playlistHighlightItem : PlaylistTextItem;
  currentModuleIndex : number; // so the playlist is initially open at the right module
  questions: any[];
  questionFeedbacks: any = {}; // [question_id]['correction'][any]
  comment : Comment;
  subscriptions : Subscription[] = [];
  courseProgressValue: number;
  clearCommentForm: boolean = false;
  loadingObject: {[key:string]:boolean} = {}; // .topic .course .personalisedData
  user : User;
  guest : Guest;
  isAuthenticated : boolean;
  interculturalistRoleAlertComments: boolean = false;
  error = null;
  surveyResponseError = null;
  paginatedComments : PaginatedComments;
  showTryAgainButtonWithFeedback: string; // 'survey.try_again' - a translation key
  shortenedDescription : string;
  collapseDescription : boolean;
  consumed: boolean;
  maxWidthMediaQuery : string  = '(max-width: 991.98px)'; // If less than this max-width, we'll collapse the lessons list;


  constructor(
    private modalService: BsModalService,
    private topicService: TopicService,
    private lessonService : LessonService,
    private breadcrumbService : BreadcrumbService,
    private courseService : CourseService,
    private linksService: LinksService,
    private surveysService: SurveysService,
    private commentsService: CommentsService,
    private route : ActivatedRoute,
    private router : Router,
    private authService : AuthService,
    private activityLogService : ActivityLogService,
    private pageTitleService : PageTitleService,
    private dataProcessingService : DataProcessingService,
    private trackingService : TrackingService,
    private languageService : LanguageService,
    private playlistService : PlaylistService,
  ) { 
    if (window.matchMedia(this.maxWidthMediaQuery).matches) {
      this.isCollapsedLessons = true;
    } 
  }

  isAnythingLoading(){
    for (let something in this.loadingObject){
      if (this.loadingObject[something]){
        return true;
      }
    }
    return false;
  }

  closeModal(){
    this.modalRef.hide();
    this.consumed = false;
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  toggleWatched(){
    if (!this.user && !this.guest){
      this.openModal(this.niceInfoModal);
    } else {
      this.updateConsumedStatus()
    }
  }
  findCurrentCourseProgress (progressables : Progressable[]){
    if (!progressables?.length || !this.courseModules){
      return null;
    }
    let result = progressables.find(p=>p.type === this.dataProcessingService.convertModelNameToBackendClass('course') && p.slug === this.course.slug)?.progress;
    return result === 0 || result > 0 ? Math.round(result) : null;
  }
  getCourseProgress (){
    const progressablesSubscription = this.trackingService.progressables.subscribe( progressables => {
      this.courseProgressValue = this.findCurrentCourseProgress(progressables);
    });
    this.subscriptions.push(progressablesSubscription);
  }

  // makeFakeLesson () : Lesson {
  //   let lesson : Lesson = this.lessonService.getPlaceholder();
  //   lesson.links = [this.linksService.getPlaceholder()];
  //   return lesson;
  // }
  // makeFakeCourse () : Course {
  //   let course : Course = this.courseService.getPlaceholder();
  //   course.links = [this.linksService.getPlaceholder()];
  //   return course;
  // };

  getConsumedStatusOfCurrentLesson (experiences : Experience[],lessonId:number){
    if (!experiences?.length){return false;};
    const backendClass = this.dataProcessingService.convertModelNameToBackendClass('lesson');
    return Boolean (experiences.find(e=>e.experienceable_type === backendClass && e.experienceable_id === lessonId && e.experience_verb === 'consumed' && e.experience_value == 1));
  };

  getProgress (){
    const totalProgressSubscription = this.trackingService.getTotalProgressCoursesOnly().subscribe();
    this.subscriptions.push(totalProgressSubscription);
    const getProgressablesSubscription = this.trackingService.getProgressables('course',this.course.id).subscribe();
    this.subscriptions.push(getProgressablesSubscription);
  }
  updateConsumedStatus (){
    this.loadingObject.consumed = true;
    const consumedSubscription = this.trackingService.submitExperience('lesson', this.lesson.id,null,this.consumed ? 1:0,'consumed',null)
      .subscribe(
        response => {
          this.loadingObject.consumed = false;
          this.getProgress();
          if(this.nextLesson && this.consumed){
            this.router.navigate(['../'+this.nextLesson.slug], { relativeTo: this.route });
          }
        },
        error => {
          this.loadingObject.consumed = false;
          // TODO - add error handling
        }
      );
    this.subscriptions.push(consumedSubscription);
  }
  makeShortenedLessonDescription (text, characterLimit){
    if (!text){return null;};
    let textHtmlRemoved = text.replace(/<(?:.|\n)*?>/gm, '');
    if (textHtmlRemoved?.length > characterLimit){
      this.collapseDescription = true;
      return textHtmlRemoved.substring(0, characterLimit) + "...";
    } else {
      return null;
    }
  }

  makePlaylist(courseModules : CourseModulesByCourse, courseExperiences : Experience[], lessonToHighlight : LessonExtraLite){

    const lessonExperiences = this.courseService.getLessonExperiencesFromCourseExperiences(courseExperiences,['consumed','completed'])
    const playlist = this.courseService.convertModulesToPlaylistText(courseModules.modules,lessonExperiences);
    let playlistStatusData = this.playlistService.findPlaylistItem(null,lessonToHighlight.slug, playlist);
    this.playlist = playlist;
    this.playlistHighlightItem = playlistStatusData.item;
    this.currentModuleIndex = playlistStatusData.itemGroupIndex;
  }

  joinContent(courseSlug : string, lessonId : number, freshFromServer: boolean){
    this.loadingObject.content = true;

    const isVisitor = !(this.user || this.guest);

    const relatedExperiencesObservable = isVisitor? of([]) : this.courseService.getRelatedExperiences (courseSlug);
    const courseModulesObservable = this.courseService.getCourseModules (courseSlug,freshFromServer);

    const joinedObservable = forkJoin({
      relatedExperiences :relatedExperiencesObservable,
      courseModules :courseModulesObservable,
    })
    .subscribe({ 
      next : (result) => {
          this.loadingObject.content = false;
          this.courseModules = result.courseModules;
          this.consumed = this.getConsumedStatusOfCurrentLesson(result.relatedExperiences,lessonId);
          const allLessonsInOrder : LessonExtraLite[] = this.courseService.getLessonsFromCourseModulesInOrder(result.courseModules.modules);
          const indexOfCurrentLesson = allLessonsInOrder.findIndex(l=>lessonId === l.id);
          if(indexOfCurrentLesson > -1 && indexOfCurrentLesson< allLessonsInOrder.length-1){
            this.nextLesson = allLessonsInOrder[indexOfCurrentLesson+1];
          };
          if(indexOfCurrentLesson > 0){
            this.previousLesson = allLessonsInOrder[indexOfCurrentLesson-1];
          };
          this.currentModuleIndex = result.courseModules.modules.findIndex(m=>m.id === allLessonsInOrder[indexOfCurrentLesson].course_module_id);
          // this.module = result.courseModules.modules[this.currentModuleIndex];
          this.makePlaylist(result.courseModules, result.relatedExperiences, allLessonsInOrder[indexOfCurrentLesson]);

      },
      error: (error) => {
        console.error('An error occurred:', error);
        // Handle the error appropriately, e.g., display an error message to the user
      },
      // complete : () => {
      //   console.log('All observables completed!');

      // },
    });

  }


  handleLessonLoadingError (error){
    this.error = error;  
    this.loadingObject.lesson = false;
    if(this.defaultActivityLogParams){
      let activityLogParams = Object.assign({},this.defaultActivityLogParams);
      activityLogParams.activity_type = 'visited';
      this.activityLogService.log(activityLogParams);
    }
  }

  getUserAndGuest(courseSlug: string, freshFromBackend : boolean){ 

    this.loadingObject.auth = true;
    const userAndGuestCombinedSubscription = combineLatest([this.authService.user, this.authService.guest]).subscribe(([user, guest]) => {
        // Code to run when both user and guest have returned a response or when in future either user or guest emits a new value
        if (user || guest) {
          this.user = user;
          this.guest = guest;
        } else if (!this.course?.allow_visitors){
            this.router.navigate(['../'], { relativeTo: this.route });
        }
        this.getLesson(this.lessonSlug, courseSlug, freshFromBackend);
        this.checkForCachedComment();
        if(this.route.snapshot.params['topic']){
          this.getTopic(this.route.snapshot.params['topic'],freshFromBackend);
        }
        this.loadingObject.auth = false;
      },
      error => {
        this.loadingObject.auth = false;
      }
    );
      this.subscriptions.push(userAndGuestCombinedSubscription);
  }
  getLesson (slug:string, courseSlug: string, freshFromBackend : boolean){
    this.loadingObject.lesson = true;
    this.error = null;
    const lessonSubscription = 
    this.lessonService.getLesson (slug,freshFromBackend)
        .subscribe(
          response => {
            this.loadingObject.lesson = false;
            this.lesson = response;
            this.lessonSlug = response.slug;
            this.joinContent(courseSlug,response.id,freshFromBackend);

            this.defaultActivityLogParams = {
              'model':'lesson',
              'identifier' : this.lessonSlug,
              'identifier_type' : 'slug',
              'activity_type' : 'viewed',
              'properties' : null
            };

            this.getLinks(this.route.snapshot.params['lesson']);
            this.getComments(1, false);
            this.getQuestions();
            this.getProgress();
            this.getCourseProgress();
            if (this.defaultActivityLogParams){
              this.activityLogService.log(this.defaultActivityLogParams);
            }
            this.pageTitleService.setTitle(response.name);
            this.breadcrumbService.setBreadcrumbFragment({urlFragment:'course',fragmentName:this.course.name});
            this.shortenedDescription = this.makeShortenedLessonDescription(response.description, 100);
          },
          error => {
            this.handleLessonLoadingError(error);
          }
        );
    this.subscriptions.push(lessonSubscription);
  };
  // getNextLessonOldWay (lessonIndex : number, lessonsArray: LessonExtraLite[], modulesIndex: number, modulesArray: CourseModule[]) : LessonExtraLite{
  //   if (lessonIndex<lessonsArray.length-1){
  //     return lessonsArray[lessonIndex+1];
  //   } else if (modulesIndex < modulesArray.length-1){
  //     return modulesArray[modulesIndex+1].lessons[0];
  //   }
  // }
  getNextLesson (lessonIndex : number, lessonsArray: LessonExtraLite[], modulesIndex: number, modulesArray: CourseModule[]) : LessonExtraLite{
    if (lessonIndex<lessonsArray.length-1){
      return lessonsArray[lessonIndex+1];
    } else if (modulesIndex < modulesArray.length-1){
      return modulesArray[modulesIndex+1].lessons[0];
    }
  }
  getPreviousLesson (lessonIndex : number, lessonsArray: LessonExtraLite[], modulesIndex: number, modulesArray: CourseModule[]) : LessonExtraLite{
    if (lessonIndex>0){
      return lessonsArray[lessonIndex-1];
    } else if (modulesIndex > 0){
      return modulesArray[modulesIndex-1].lessons[modulesArray.length-1];
    }
  }
  gotoLesson(item:PlaylistTextItem){
    this.router.navigate(['../'+item.identifier_string], { relativeTo: this.route });
  }
  navigateTo(routeArray){
    if (this.modalRef){
      this.closeModal();
    }
    this.router.navigate(routeArray);
  }
  getLinks (lessonSlug){
    this.loadingObject.links = true;
    const linksSub = this.linksService.getLinks('lesson',lessonSlug)
      .subscribe(
        response => {
          this.lesson.links = response;
          this.loadingObject.links = false;
        },
        error => {
          this.loadingObject.links = false;
        }
      );
    this.subscriptions.push(linksSub);
  };
  getQuestions (){
    this.loadingObject.questions = false;
    const questionsSub = this.surveysService.getQuestionsByModel('lesson',this.lesson.slug,this.lesson.id, null,null)
      .subscribe(
        response => {
          this.questions = response;
          if(!this.lessonTabs || !this.lessonTabs.tabs || !this.lessonTabs.tabs[0] || !this.lessonTabs.tabs[1]){
            debugger; // investigate why this is sometimes not available. If the debugger never tiggers, forget thsi problem
          }
          if (response?.length && this.lessonTabs?.tabs[1]){
            this.lessonTabs.tabs[0].active = false;
            this.lessonTabs.tabs[1].active = true;
          } else {
            this.lessonTabs.tabs[0].active = true;
            this.lessonTabs.tabs[1].active = false;
          }
          this.loadingObject.questions = false;
        },
        error => {
          this.loadingObject.questions = false;
        }
      );
    this.subscriptions.push(questionsSub);
  };
  doSubmitSurveyResponse(responseData){
    if ((!this.user && !this.guest) || (this.course?.allow_guests && !this.guest)){
      this.openModal(this.niceInfoModal);
      return;
    }
    this.loadingObject.questionResponse = true;
    const questionResponseSub = this.surveysService.submitSingleQuestionResponse(responseData.survey_id,responseData.question_id,responseData.response)
    .subscribe(
      response => {
        if (response?.corrections?.length){
          this.questionFeedbacks[responseData.question_id] = response.corrections.find(c=>c.question_id ===responseData.question_id);
        };
        if (response?.survey_correction === 'tease'){
          this.showTryAgainButtonWithFeedback = 'survey.try_again';
        } else {
          this.showTryAgainButtonWithFeedback = null;
        }
        this.loadingObject.questionResponse = false;
        this.getProgress ()
      },
      error => {
        this.surveyResponseError = error?.message;
        this.loadingObject.questionResponse = false;
      }
    );
    this.subscriptions.push(questionResponseSub);
  }
  getComments (page, freshFromServer){
    this.loadingObject.comments = true;
    const commentsSub = this.commentsService.getComments('lesson',this.route.snapshot.params['lesson'],page, false, false,10,'created_at','desc',freshFromServer)
      .subscribe(
        response => {
          this.paginatedComments = response;
          this.loadingObject.comments = false;
        },
        error => {
          this.loadingObject.comments = false;
        }
      );
    this.subscriptions.push(commentsSub);
  };
  postComment (message,lesson_id){
    lesson_id = lesson_id ? lesson_id : this.lesson.id;
    if (!this.isAuthenticated){
      this.commentsService.setCachedPostingOfCommentForAfterAuthentication('lesson',lesson_id,message);
      this.authService.setRouteForRedirectAfterAuth(['lessons/'+this.lesson.slug]);
      this.navigateTo(['/login']);
      return;
    }
    this.loadingObject.postingComments = true;
    const postCommentSub = this.commentsService.postAComment('lesson',lesson_id,message)
      .subscribe(()=>{
        this.loadingObject.postingComments = false;
        this.clearCommentForm = !this.clearCommentForm;
        if (!this.authService.checkRole("Interculturalist")){
          this.interculturalistRoleAlertComments = true;
        }
        let current_page = this.paginatedComments?.meta.current_page ? this.paginatedComments?.meta.current_page : 1;
        this.getComments(current_page, true);
      });
    this.subscriptions.push(postCommentSub);
  };
  checkForCachedComment (){
    let cachedComment = this.commentsService.getCachedPostingOfCommentForAfterAuthentication();
    if (cachedComment && cachedComment.modelNameSingular === 'lesson'){
      this.postComment(cachedComment.message,cachedComment.identifier);
    };
  };
  getTopic(topicSlug : string, freshFromBackend : boolean) {
    this.loadingObject.topic = true;
    const topicSubscription = this.topicService.getTopic(topicSlug,freshFromBackend).subscribe((topic) => {
      this.breadcrumbService.setBreadcrumbFragment({urlFragment:'topic',fragmentName:topic.name});
      this.loadingObject.topic = false;
      },
      error => {
        this.loadingObject.topic = false;
      });
    this.subscriptions.push(topicSubscription);
  }
  getCourse(courseSlug : string, freshFromBackend : boolean) {
    this.loadingObject.course = true;
    const courseSubscription = this.courseService.getCourse(courseSlug,freshFromBackend).subscribe((course) => {
      this.course = course;
      this.breadcrumbService.setBreadcrumbFragment({urlFragment:'course',fragmentName:course.name});
      this.loadingObject.course = false;
      this.getUserAndGuest(courseSlug,freshFromBackend);
      },
      error => {
        this.loadingObject.course = false;
      });
    this.subscriptions.push(courseSubscription);
  }

  ngOnInit(): void {
    if(this.route.snapshot.params['lesson'] && this.route.snapshot.params['course']){
      this.lessonSlug = this.route.snapshot.params['lesson'];
      this.courseSlug = this.route.snapshot.params['course'];

      this.getCourse(this.courseSlug,false);
    }
    this.activeLanguageObject = this.languageService.activeLanguageObjectSynchronously;
    const activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
      // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
      if (newActiveLanguage?.languageKey !== this.activeLanguageObject.languageKey){
        this.activeLanguageObject = newActiveLanguage;
        if(this.lessonSlug && this.courseSlug){
          this.getCourse(this.courseSlug,false);
        }
      }
    });
    this.subscriptions.push(activeLanguageSubscription);
    const routeSubscription = this.router.events // for when the user browses to a different lesson but stays in this component
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        if(this.lesson && this.lesson.slug !== this.route.snapshot.params['lesson']){
          this.lessonSlug = this.route.snapshot.params['lesson'];
          this.getLesson(this.route.snapshot.params['lesson'],this.courseSlug,false);
        }
      });
    this.subscriptions.push(routeSubscription);
    
  }
  ngOnDestroy () {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
