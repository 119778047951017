<div class="delivery-status-card" (click)="onButtonClick()">
  <div class="d-flex gap-2 flex-column flex-md-row">
    @if(getThumbnailUrl(delivery())){
      <div class="img-holder">
        @if(delivery().task?.type === 'video' || delivery().task?.category === 'video'){
          <div class="play-video">
            <i class="fa-solid fa-circle-play"></i>
            <span *transloco="let t">{{t('common.video')}}</span>
          </div>
        }
        <img
          [src]="getThumbnailUrl(delivery())" [alt]="'Illustration of the task'"
        />
      </div>
    }
    <div class="content-holder">
      <div class="status-row">
        @if(deliveryStatus()){
          <span>
            <span class="badge badge-pill" [ngClass]="deliveryStatus().badgeClasses">
              <i [class]="deliveryStatus().iconClasses"></i>
              <span>{{deliveryStatus().labelTranslationKey | transloco}}</span>
            </span>
          </span>

        }
        <span class="d-flex align-items-center gap-1">
          <multisite-icon-pill
            [fallbackIconClasses]="deliveryType()?.iconClasses"
            [labelTranslationKey]="deliveryType()?.labelTranslationKey"
            [compact]="true"
            [shape]="'round'"
            >
          </multisite-icon-pill>
        </span>
      </div>
      <h6 class="title">{{title()}}</h6>
      <p class="subtitle">
        <i class="fa-solid fa-circle-info"></i>
        {{delivery().task?.description}}
      </p>
      <div class="questions-container">
        <p class="card-text">{{ truncatedSubjectDescription() }}</p>
      </div>
    </div>
  </div>
  <div class="action-row">
    <div class="cultures">
        <multisite-avatar-pill
          *ngFor="let culture of cultures()"
          [removalMode]="false"
          [compact]="true"
          [active]="false"
          [fallbackTranslationKey]="'tracking.missing'"
          [cursorNone]="true"
          [clickable]="false"
          [imageUrl]="getFlagUrlFromHash(culture.flag?.hash,'.png')"
          [text]="culture.name"
        ></multisite-avatar-pill>
    </div>

    <div class="right-holder">
      @if(delivery().task?.credits_max && showCredits()){
        <span class="credits">
          <i class="fa-solid fa-coins"></i>
          <span>{{delivery().task?.credits_max}}</span>
        </span>
      }
      <button
        type="button"
        class="btn btn-primary"
        (click)="onButtonClick()"
        *transloco="let t"
      >
        {{ t('common.go') }}
        <i class="pl-2 fa-solid fa-arrow-right"></i>
      </button>
    </div>
  </div>
</div>
