import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'multisite-toggle-switch',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']

})
export class ToggleSwitchComponent {

  active = input<boolean>(false);
  disabled = input<boolean>(false);
  toggle = output<void>();
  optionAiconClasses = input<string | undefined>();
  optionBiconClasses = input<string | undefined>();

  doToggle() {
    if (!this.disabled()) {
      this.toggle.emit();
    }
  }
}
