import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, computed, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Delivery, ContributionsService, Task, Insight} from '..';
import { AuthService, User } from '../../auth';
import { BreadcrumbService } from '../../navigation/breadcrumb/breadcrumb.service';
import { MediaService } from '../../content/media';
import { AvailableLanguage, Language, LanguageService, TranslatablePair } from '../../language';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { ProfileUserService } from '../../profile-user/profile-user.service';
import { MetaText } from '../../content';
import { Culture, CultureService, CultureVariantFilter } from '../../cultures';
import { TranslationService } from '../../translation/translation.service';
import { DataProcessingService, FormCachingService, WindowService } from '../../utilities';
import { PageTitleService } from '../../navigation';
import { TranslocoService } from '@jsverse/transloco';
import { MetaTextService } from '../../content/metatext.service';
import { RangeInputStep } from '../../forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommentsService, PaginatedComments } from '@frontend/shared';
import { Vote } from '../../votes';

@Component({
  selector: 'multisite-deliverable-edit-parent',
  templateUrl: './deliverable-edit-parent.component.html',
  styleUrls: ['./deliverable-edit-parent.component.scss']
})
export class DeliverableEditParentComponent implements OnInit, OnDestroy {

  formDataPendingSubmission = signal<{[key:string]: any} | undefined>(undefined);
  allTargetLanguageFormControlsAreNull = signal<boolean>(false); // Need to know whether to open the submission modal or not
  canSkipSubmissionFormModal = computed<boolean>(() => !this.sourceLanguage() || this.allTargetLanguageFormControlsAreNull());
  languagesInitialised = signal<boolean>(false); // When true, we can initialise the translatable form controls
  deliverableIdentifier = signal<string | number | undefined>(undefined); // deliverable slug or id
  layoutColumns = signal<number[]>([6,6]);

  submissionForm : FormGroup;

  loadingObject = signal <{[key:string]:boolean}>({});
  deliveryLocked = computed<boolean>(()=> !!this.delivery()?.submitted_at); // for example, after submission
  truncatedTitle = signal<string | undefined>(undefined); // Some deliverables with an editable title in child components may re-use this if the user saves the deliverable with an empty title
  allLanguages = signal<Language[]|undefined>(undefined);
  myLanguagesApprovedForEditing = signal<Language[]|undefined>(undefined);
  englishLanguage = computed(() => this.allLanguages()?.find(l=>l.iso === 'en'));
  activeLanguageObject = signal<AvailableLanguage | undefined>(undefined);
  instructions = signal<MetaText[]>([]);
  instructionsPreferredOrder = ['rules','generalising','judging','general','style','writing','targeting','sources']; // These particular ypes are based instructions for insight-editing. We may need to develop a more generalised approach for other deliverabes
  instructionTypes = computed(() => {
    const availableTypes = this.instructions().map(instruction => instruction.type).filter((value, index, self) => self.indexOf(value) === index);
    return this.instructionsPreferredOrder.filter(type => availableTypes.includes(type)).concat(availableTypes.filter(type => !this.instructionsPreferredOrder.includes(type)));
  });
  show_instruction_details = signal<boolean>(false);
  subscriptions : Subscription[] = [];
  submissionModalSubscription : Subscription; // should be handled separately from the other subscriptions because we want only one instance of the modal (multiple modals cause problems) so any new modal subscription should overwrite the previous one
  cultures = signal<Culture[] | undefined>(undefined);
  culturesWithVariants = signal<Culture[] | undefined>(undefined);
  deliverableCulture = computed<Culture | undefined>(() => {
    if(this.deliverable()?.cultures?.length === 1){
      return this.cultures()?.find(culture => culture.slug === this.deliverable().cultures[0].slug);
    }
  }); // the selected culture needed by a child (deliverable) component
  cultureGroupSlugs : string[] = [];
  delivery = signal<Delivery | undefined>(undefined);
  deliveryEditorType = computed(() => {
    if(this.delivery()?.insight && ['create','edit'].includes(this.delivery().task?.type)){
      return 'insight-edit';
    } else if (this.delivery()?.differences && this.delivery().differences.length && this.delivery().votes && this.delivery().votes[0] && this.delivery().task?.type === 'vote'){
      return 'difference-vote';
    }
    return null;
  });
  deliverable = signal<Insight | Vote | undefined>(undefined); // add more types as needed
  deliverable_translations_key = signal<string | undefined>(undefined); // 'insight_translations' or 'vote_translations'
  deliverable_translations = computed<{[key:string]:string} | undefined>(() => {
    return this.delivery()?.[this.deliverable_translations_key()];
  });
  deliverablePublishedAttribute = signal<string|undefined>(undefined);
  sourceLanguage = signal<Language | undefined>(undefined);
  targetLanguage = signal<Language | undefined>(undefined);
  sourceLanguageIsAdminApprovedAndTopLevel = computed(() => {
    const mySourceLanguage = this.myLanguagesApprovedForEditing()?.find(language => language.iso === this.sourceLanguage()?.iso);
    const skillLevels : RangeInputStep[] = this.languageService.languageSkillLevelRangeInputSteps;
    const topLevel = skillLevels[skillLevels.length - 1].value;
    return skillLevels?.length && mySourceLanguage?.skill?.admin_approved && mySourceLanguage?.skill?.level === topLevel;
  });
  translatablePairsForCommenting = computed(() => {
    const pairs : TranslatablePair[] = [];
    if(this.sourceLanguage() && this.sourceLanguage().iso !== this.englishLanguage().iso){
      pairs.push({source_lang:this.sourceLanguage(),target_lang:this.englishLanguage()});
    }
    if(this.targetLanguage() && this.targetLanguage().iso !== this.englishLanguage().iso){
      pairs.push({source_lang:this.targetLanguage(),target_lang:this.englishLanguage()});
    }
    return pairs;
  });
  collapseObject = signal<{[key:string]:boolean}>({});
  visualisationLabelType = signal<'number'|'name'>('name');
  show_visualisation_legend = signal<boolean>(true);
  show_visualisation_legend_details = signal<boolean>(false);
  show_visualisation_filters = signal<boolean>(false);
  show_visualisation_controls = signal<boolean>(true);
  visualisationCardHeight = signal<number>(400);
  visualisationCardWidth = signal<number>(220);
  active_visualisation_filters = signal<{[key:string]:boolean}>({});
  autoPlayFilterLoop = signal<boolean>(true);
  visualisationFilterLoopAutoPlayLimit = signal<number>(1);
  visualisationFilters = signal<CultureVariantFilter[]>([]);
  selectedVisualisationFilters = signal<CultureVariantFilter[]>([]); // only for the initial state of the visualisation
  highlightMassCultureItemsInVisualisation = signal<boolean>(true);
  toggleObject : {[key:string]:boolean} = {};
  modalRef?: BsModalRef;
  @ViewChild('submissionModal') submissionModal;
  submissionSuccessful = signal<boolean>(false); // we need this so that two different modal contents can share the same modal. Swapping between different modal references doesn't work well.
  paginatedComments = signal<PaginatedComments | undefined>(undefined);
  clearCommentForm = signal<boolean | undefined>(undefined);
  formCachingServiceIdentifier = signal<string | undefined>(undefined);

  error = signal<string|{message:string,meta:any}|undefined>(undefined);
  errorString = computed(() => {
    if(this.error()){
      return typeof this.error() === 'string' ? this.error() : (this.error() as {message:string,meta:any}).message;
    }
  });
  errorObject = computed(() => {
    if(this.error()){
      return typeof this.error() === 'object' ? this.error() : null;
    }
  });
  commentError = signal<string|{message:string,meta:any}|undefined>(undefined);
  commentErrorString = computed(() => {
    if(this.commentError()){
      return typeof this.commentError() === 'string' ? this.commentError() : (this.commentError() as {message:string,meta:any}).message;
    }
  });
  commentErrorObject = computed(() => {
    if(this.commentError()){
      return typeof this.commentError() === 'object' ? this.commentError() : null;
    }
  });
  user : User = null; // Needed for comments
  languages : Language[];
  successModalImageTagQueryParams : URLSearchParams;

  // EXTENDABLE for new types of deliverables and tasks

  deliverableTypeInUrl = signal<'differences' | 'insights' | undefined>(undefined);
  taskType = signal<'create' | 'vote' | undefined>(undefined); // // | 'review' | 'translate', etc.
  backendDeliverableType = computed(() => {
    if(this.deliverableTypeInUrl() === 'differences'){
      return this.taskType() === 'vote' ? 'vote' : 'vote'; // This makes no sense, but in future we might have some different scenarios
    } else if (this.deliverableTypeInUrl() === 'insights'){
      return 'insight';
    }
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private contributionsService: ContributionsService,
    private breadcrumbService : BreadcrumbService,
    private authService : AuthService,
    private languageService : LanguageService,
    private mediaService : MediaService,
    private profileUserService : ProfileUserService,
    private sanitizer: DomSanitizer,
    private cultureService : CultureService,
    private translationService : TranslationService,
    private formCachingService: FormCachingService,
    private pageTitleService : PageTitleService,
    private translocoService : TranslocoService,
    private metaTextService : MetaTextService,
    private windowService : WindowService,
    private modalService: BsModalService,
    private dataProcessingService : DataProcessingService,
    private commentsService : CommentsService,
  ) {
    this.visualisationFilters.set(this.cultureService.variantFilters);
    this.successModalImageTagQueryParams = new URLSearchParams();
    this.successModalImageTagQueryParams.append('gallery','interculturalist'); // could be an comma-separated list like this: 'interculturalist,socialising,business,cultures'
  }

  setLoadingStatus(key: string, value: boolean){
    // So we can detect deep changes in the object
    this.loadingObject.set({...this.loadingObject(),[key]:value});
  }
  isAnythingLoading(){
    for (let something in this.loadingObject()){
      if (this.loadingObject()[something]){
        return true;
      }
    }
    return false;
  }
  gotoTop() {
    this.windowService.goToTop();
  }
  openModal(template: TemplateRef<void>) {
    this.modalRef = this.modalService.show(template);
    this.submissionModalSubscription = this.modalRef.onHidden.subscribe((reason: string | any) => {
      if(typeof reason === 'string' && (reason === 'backdrop-click' || reason === 'esc')){
        if(this.submissionSuccessful()){
          this.quit();
        }
      }
    })
  }
  closeModal(){
    if(this.modalRef){
      this.modalRef.hide();
    }
    if(this.submissionSuccessful()){
      this.quit();
    }
  }
  setDelivery(delivery : Delivery){
    this.delivery.set(delivery);
    if(delivery.task?.slug === 'difference-vote-cco' && delivery.votes[0]){
      this.layoutColumns.set([9,3]);
      this.deliverableIdentifier.set(delivery.votes[0].id);
      this.deliverable.set(delivery.votes[0]);
      this.deliverable_translations_key.set('vote_translations');
      this.deliverablePublishedAttribute.set('archive_id');
      let title = delivery.differences?.[0]?.name;
      const truncatedTitle = title.length > 100 ? title.substring(0,85)+'...' : title;
      this.truncatedTitle.set(truncatedTitle);
      this.pageTitleService.setTitle(truncatedTitle); 
    } else if (this.backendDeliverableType() === 'insight'){
      this.deliverableIdentifier.set(delivery.insight.slug);
      this.deliverable.set(delivery.insight);
      this.deliverable_translations_key.set('insight_translations');
      this.deliverablePublishedAttribute.set('released_at');
      let title = delivery.insight.s;
      const truncatedTitle = title.length > 100 ? title.substring(0,85)+'...' : title;
      this.truncatedTitle.set(truncatedTitle);
      this.pageTitleService.setTitle(truncatedTitle);
    }
    if(this.deliverable().cultures){
      const cultureSlugs = this.deliverable().cultures.map(culture => culture.slug);
      if(!this.dataProcessingService.matchTwoArrays(this.cultureGroupSlugs,cultureSlugs)){
        this.cultureGroupSlugs = [].concat.apply([], cultureSlugs); 
        this.initialiseCultures(this.cultureGroupSlugs);
      }
    }
  }
  getInstructionsOfType(type : string){
    return this.instructions().filter(instruction => instruction.type === type);
  }
  toggleCollapse(key: string){
    this.collapseObject()[key] = !this.collapseObject()[key];
  }
  setCollapse(key: string, value: boolean){
    if(this.collapseObject()[key] !== value){
      this.collapseObject()[key] = value;
    }
  }

  getComments (page, freshFromServer){
    this.commentError.set(undefined);
    this.setLoadingStatus('comment',true);
    const commentsSub = this.commentsService.getComments('delivery',this.delivery()?.id,page, false, true,3,'created_at','desc',freshFromServer)
      .subscribe(
        response => {
          this.paginatedComments.set(response);
          if(response.data.length > 0){
            this.collapseObject()['editorialComments'] = false;
          }
          this.setLoadingStatus('comment',false);
        },
        error => {
          this.commentError.set(error);
          this.setLoadingStatus('comment',false);
        }
      );
    this.subscriptions.push(commentsSub);
  };
  postComment (message : string){
    this.commentError.set(undefined);
    this.setLoadingStatus('comment',true);
    const postCommentSub = this.commentsService.postAComment('delivery',this.delivery().id,message)
      .subscribe((result)=>{
        this.setLoadingStatus('comment',false);
        this.clearCommentForm.set(!this.clearCommentForm());
        // if (!this.authService.checkRole("Interculturalist")){
        //   this.interculturalistRoleAlertComments = true;
        // }
        let current_page = this.paginatedComments()?.meta.current_page ? this.paginatedComments()?.meta.current_page : 1;
        this.getComments(current_page, true);
      },
      error => {
          this.commentError.set(error);
          this.setLoadingStatus('comment',false);
      });
    this.subscriptions.push(postCommentSub);
  };
  toggleVisualisationLabelType(){
    this.visualisationLabelType.set(this.visualisationLabelType() === 'number' ? 'name' : 'number');
  }
  toggleShowVisualisationFilters(){
    this.show_visualisation_filters.set(!this.show_visualisation_filters());
  }
  toggleShowInstructionDetails(){
    this.show_instruction_details.set(!this.show_instruction_details());
  }
  toggleEditorialCommentsCollapsed(){
    this.show_instruction_details.set(!this.show_instruction_details());
  }
  toggleShowVisualisationLegend(){
    this.show_visualisation_legend.set(!this.show_visualisation_legend());
  }
  toggleShowVisualisationLegendDetails(){
    this.show_visualisation_legend_details.set(!this.show_visualisation_legend_details());
  }

  setEditingLanguage (language: Language){
    this.translationService.setPreferredTranslationSourceLanguage(language.iso);
    if(this.targetLanguage() && this.targetLanguage().iso === language.iso){
      this.sourceLanguage.set(undefined);
    } else {
      this.sourceLanguage.set(language);
    }
  }
  setEditingLanguageDefault (){
    if (this.myLanguagesApprovedForEditing() && this.myLanguagesApprovedForEditing().length > 0) {
      let language;
      if(this.translationService.getPreferredTranslationSourceLanguage()){
        language = this.myLanguagesApprovedForEditing().find(language => language.iso === this.translationService.getPreferredTranslationSourceLanguage());
        this.setCollapse('languagePicker',true);
      } else if (this.activeLanguageObject() ){
        language = this.myLanguagesApprovedForEditing().find(language => language.iso === this.activeLanguageObject().languageKey);
      }
      if(language){
        this.setEditingLanguage(language);
      }
    }
  }

  quit(){
    this.router.navigate(['/contributions']);
  }
  save(formData: {[key:string]: any},action : 'update' | 'submit'){
    this.formDataPendingSubmission.set(formData);
    this.error.set(undefined);
    if(this.sourceLanguage() && (action === 'submit' && this.submissionForm?.get('submitMultipleLanguages')?.value !== 'target+source')){
      delete formData.translations;
    }
    this.setLoadingStatus(action,true);
    this.contributionsService.updateDelivery(this.delivery(), formData, this.backendDeliverableType(), this.deliverableIdentifier(),action).subscribe(
      response => {
        this.setLoadingStatus(action,false);
        this.setDelivery(response);
        if(action === 'submit'){
          this.submissionSuccessful.set(true);
          if(this.canSkipSubmissionFormModal()){ // If there is a source language, the modal should be open already
            this.openModal(this.submissionModal);
          }
          this.formCachingService.clearDataItem(this.formCachingServiceIdentifier());
        }
      },
      error => {
        this.setLoadingStatus(action,false);
        this.error.set(error);
      }
    );
  }
  getLanguagesAndMyLanguages(freshFromServer: boolean) {
    this.setLoadingStatus('languages',true);
    this.setLoadingStatus('myLanguages',true);

    const languagesSubscription = combineLatest([
      this.languageService.getLanguages(freshFromServer),
      this.profileUserService.getMyLanguages(freshFromServer)
    ]).subscribe(
      ([languagesResponse, myLanguagesResponse]) => {
        this.allLanguages.set(languagesResponse);
        this.myLanguagesApprovedForEditing.set(myLanguagesResponse.filter(l=>l.skill.admin_approved && l.iso !== 'en' && this.translationService.availableTranslationSourceLanguageCodes.includes(l.iso)).concat([this.englishLanguage()]));
        const targetLanguage = this.allLanguages().find(language => language.iso === this.delivery().target_lang);
        if (targetLanguage){
          this.targetLanguage.set(targetLanguage);
        }
        this.setEditingLanguageDefault();
        this.languagesInitialised.set(true);
        this.getComments(1, false);
        this.setLoadingStatus('languages',false);
        this.setLoadingStatus('myLanguages',false);
      },
      (error) => {
        this.setLoadingStatus('languages',false);
        this.setLoadingStatus('myLanguages',false);
      }
    );
    this.subscriptions.push(languagesSubscription);
  }

  requiredTrueValidator(): ValidatorFn {
    // This should not be necessary but for some reason Angular's built-in Validators.required is not working for the approveTranslation
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return value === true ? null : { required: true };
    };
  }
  
  submit(submissionData:{formData:{[key:string]:any},allTargetLanguageFormControlsAreNull:boolean}){
    
    this.formDataPendingSubmission.set(submissionData.formData);
    this.allTargetLanguageFormControlsAreNull.set(submissionData.allTargetLanguageFormControlsAreNull);
    if(this.canSkipSubmissionFormModal()){ // We assume the form is valid but there are some optional translation fields that are empty, so we can skip the submission form confirming the translations
      this.confirmSubmission();
      return;
    }
    this.submissionForm = new FormGroup({});

    if(this.sourceLanguageIsAdminApprovedAndTopLevel()){
      if(!this.deliverable()?.[this.deliverablePublishedAttribute()]){ // After publishing, the user can only submit changes to the target language specified in the delivery
        this.submissionForm.addControl('submitMultipleLanguages', new FormControl('', Validators.required));
      }
    }
    this.submissionForm.addControl('approveTranslation', new FormControl(false, this.requiredTrueValidator()));
    this.submissionForm.updateValueAndValidity();
    this.openModal(this.submissionModal);
  }
  confirmSubmission(){
    this.error.set(undefined);
    const translation = this.translocoService.translate('common.cannot_change_after_submit_are_you_sure');
    if (confirm(translation)) {
      this.save(this.formDataPendingSubmission(),'submit');
    }
  };

  getCulturesWithVariants(cultureSlugs : string[]){
    this.setLoadingStatus('variants',true);
    const cultureIds = this.cultures().filter(culture => cultureSlugs.includes(culture.slug)).map(culture => culture.id);
    const culturesSubscription = this.cultureService.getCulturesWithVariants(cultureIds,'geographic','national',false).subscribe((cultures :  Culture[]) => {
      this.culturesWithVariants.set(cultures);
      this.setLoadingStatus('variants',false);
    }, error => this.setLoadingStatus('variants',false));
    this.subscriptions.push(culturesSubscription);

  }
  initialiseCultures(cultureGroupSlugs : string[] = []){

    this.setLoadingStatus('cultures',true);
    const culturesSubscription = this.cultureService.getCultures('geographic','national',false).subscribe((cultures :  Culture[]) => {
      this.cultures.set(cultures);
      if(cultureGroupSlugs.length > 0){
        this.getCulturesWithVariants(cultureGroupSlugs);
      }
      this.setLoadingStatus('cultures',false);
    });
    this.subscriptions.push(culturesSubscription);

  }
  
  getInstructions(task :Task){
    this.setLoadingStatus('instructions',true);
    const instructionsSubscription = this.metaTextService.getMetaTexts(false,'task',task.id,'content_creation').subscribe(instructions => {
      this.instructions.set(instructions);
      this.setLoadingStatus('instructions',false);
    },error => this.setLoadingStatus('instructions',false));
    this.subscriptions.push(instructionsSubscription);
    
  }
  initialise(){
    this.setLoadingStatus('delivery',true);
    const deliverySubscription = this.contributionsService.getMyDelivery(this.taskType(),this.backendDeliverableType(), this.deliverableIdentifier(),this.deliverable_translations_key()).subscribe(delivery => {
      this.setDelivery(delivery);
      this.setLoadingStatus('delivery',false);
      const taskTranslationKey = 'contributions.task.'+this.delivery().task.type;
      const taskTranslation = this.translocoService.translate(taskTranslationKey);
      this.breadcrumbService.setBreadcrumbFragment({urlFragment:'task_type',fragmentName:taskTranslationKey === taskTranslation ? this.delivery().task.type : taskTranslation});
      this.getLanguagesAndMyLanguages(false);
      if(!this.delivery().task?.metaTexts){
        this.getInstructions(this.delivery().task);
      } else {
        this.instructions.set(this.delivery().task.metaTexts);
      }
    },error => this.setLoadingStatus('delivery',false));
    this.subscriptions.push(deliverySubscription);
  }
  ngOnInit(): void {
    this.gotoTop();
    const deliverableIdentifier = this.route.snapshot.params['identifier'];
    const routeParamIsANumber = !isNaN(Number(deliverableIdentifier));
    this.deliverableIdentifier.set(routeParamIsANumber ? +deliverableIdentifier: deliverableIdentifier);
    this.deliverableTypeInUrl.set(this.route.snapshot.params['deliverable_type']);
    this.formCachingServiceIdentifier.set(Object.values(this.route.snapshot.params).join('-'));
    this.taskType.set(this.route.snapshot.params['task_type']);
    this.activeLanguageObject.set(this.languageService.activeLanguageObjectSynchronously);
    const activeLanguageSubscription = this.languageService.activeLanguageObject.subscribe( (newActiveLanguage) => {
        // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
        if (newActiveLanguage?.languageKey !== this.activeLanguageObject().languageKey){
          this.activeLanguageObject.set(newActiveLanguage);
          this.initialise();
        }
    });
    this.subscriptions.push(activeLanguageSubscription);
    this.collapseObject().editorialComments = true;
    this.initialise();
    const userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
    });
    this.subscriptions.push(userSubscription);
    this.selectedVisualisationFilters.set(this.visualisationFilters().filter(filter => ['class','community','population'].includes(filter.key)));
  }
  ngOnDestroy () : void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if(this.submissionModalSubscription){
      this.submissionModalSubscription.unsubscribe();
    }
  }

}
