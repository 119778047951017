import { Injectable } from '@angular/core';

import {
  FieldRequirements,
  GenericFormControl,
  Language,
  TranslatableFormControl,
  WordAndCharacterRequirements
} from '../language';
import { FormControlBooleanRequirements } from './form-control-requirements.model';
import { FormCachingService, FormDataStandard } from '../utilities';
import { Vote } from '../votes';
import { Insight } from './insight.model';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

@Injectable() // provided in the lazy-loaded ContributionsModule
export class DeliverableService {
  // For sharing of code between deliverable components

  private _placeholderDeliveryTitles: string[] = [];

  constructor(private formCachingService: FormCachingService)
  {
    this._placeholderDeliveryTitles = [
      // The backend will generate a title starting with one of these strings. We don't want to insert a placeholder into the form.
      'My text about',
      '[TITLE NEEDED]'
    ];
  }

  getFieldRequirements(
    allTranslatableFormControls: TranslatableFormControl[],
    allNonTranslatableFormControls: GenericFormControl[],
    wordAndCharacterRequirements: {
      words?: WordAndCharacterRequirements[];
      characters?: WordAndCharacterRequirements[];
    },
    taskBasedFormControlRequirements: {
      required?: FormControlBooleanRequirements;
    },
    language_code: string,
    field_key: string,
    translatable: boolean
  ): FieldRequirements {
    const types = ['words', 'characters'];
    let requirementsAnyLanguage = {};
    let requirementsThisLanguage = {};
    let fieldRequirements: FieldRequirements = {};
    const defaultFieldRequirements = translatable
      ? allTranslatableFormControls.find(
          (control) => control.pairKey === field_key
        )?.defaultRequirements
      : allNonTranslatableFormControls.find(
          (control) => control.key === field_key
        )?.defaultRequirements;

    types.forEach((type) => {
      requirementsAnyLanguage[type] = wordAndCharacterRequirements?.[type].find(
        (requirements) =>
          requirements.languages.length === 0 &&
          requirements.field_keys.includes(field_key)
      );

      requirementsThisLanguage[type] =
        wordAndCharacterRequirements?.[type].find(
          (requirements) =>
            language_code &&
            requirements.languages.includes(language_code) &&
            requirements.field_keys.includes(field_key)
        ) ?? requirementsAnyLanguage[type];
      fieldRequirements[type + '_min_warning'] =
        requirementsThisLanguage[type]?.warning?.min ??
        defaultFieldRequirements?.characters?.min ??
        0;
      fieldRequirements[type + '_max_warning'] =
        requirementsThisLanguage[type]?.warning?.max ??
        defaultFieldRequirements?.characters?.max ??
        null;
      fieldRequirements[type + '_min_required'] =
        requirementsThisLanguage[type]?.required?.min ??
        defaultFieldRequirements?.characters?.min ??
        0;
      fieldRequirements[type + '_max_required'] =
        requirementsThisLanguage[type]?.required?.max ??
        defaultFieldRequirements?.characters?.max ??
        null;
    });

    fieldRequirements['required'] =
      taskBasedFormControlRequirements?.required?.field_keys.includes(
        field_key
      ) ?? defaultFieldRequirements?.required;

    return field_key ? fieldRequirements : null;
  }

  getSavedAndCachedFormData(
    formCachingServiceIdentifier: string,
    allNonTranslatableFormControls: GenericFormControl[],
    allTranslatableFormControls: TranslatableFormControl[],
    deliverable: Insight | Vote,
    sourceLanguage: Language,
    deliverable_translations: { [key: string]: string }
  ): FormDataStandard {
    const cachedFormData = this.formCachingService.getDataItem(
      formCachingServiceIdentifier
    );
    const returnObject: FormDataStandard = {};
    const genericFormControlKeys = allNonTranslatableFormControls.map(
      (control) => control.key
    );
    for (const key of genericFormControlKeys) {
      returnObject[key] = cachedFormData?.[key] ?? deliverable?.[key];
    }
    const translatableFormControls = allTranslatableFormControls.filter(
      (control) => control.type === 'target'
    );
    for (const control of translatableFormControls) {
      returnObject[control.key] =
        cachedFormData?.[control.key] ??
        (control.deliverableTitle &&
        this.isPlaceholderDeliveryTitle(
          deliverable?.[control.deliverableAttribute]
        )
          ? null
          : deliverable?.[control.deliverableAttribute]);
    }
    let translations;
    if (sourceLanguage) {
      translations = cachedFormData?.translations?.[sourceLanguage.iso];
      for (const control of translatableFormControls) {
        let savedTranslation =
          deliverable_translations?.[control.deliverableAttribute]?.[
            sourceLanguage.iso
          ];
        if (savedTranslation && !translations?.[control.key]) {
          translations = translations ?? {};
          translations[control.key] = savedTranslation;
        }
      }
    }
    return { ...returnObject, translations };
  }
  cacheFormData(
    formCachingServiceIdentifier: string,
    deliverableForm: FormGroup,
    allNonTranslatableFormControls: GenericFormControl[],
    allTranslatableFormControls: TranslatableFormControl[],
    sourceLanguage: Language
  ): void {
    let cachedTranslations =
      this.formCachingService.getDataItem(formCachingServiceIdentifier)
        ?.translations ?? {};
    const dataToCache: FormDataStandard = {};

    const genericFormControlKeys = allNonTranslatableFormControls.map(
      (control) => control.key
    );
    const genericAndTranslatableFormControlKeys = genericFormControlKeys.concat(
      allTranslatableFormControls
        .filter((control) => control.type === 'target')
        .map((control) => control.key)
    );
    for (const key of genericAndTranslatableFormControlKeys) {
      if (deliverableForm.get(key)) {
        let value = deliverableForm.get(key).value;
        value = typeof value === 'string' ? value.trim() : value;
        dataToCache[key] = value ?? null;
      }
    }
    if (sourceLanguage) {
      const translationSourceFormControls = allTranslatableFormControls.filter(
        (control) => control.type === 'source'
      );
      let formDataStandardTranslationCurrentLanguage: { [key: string]: string };
      for (const control of translationSourceFormControls) {
        if (deliverableForm.get(control.key)) {
          formDataStandardTranslationCurrentLanguage =
            formDataStandardTranslationCurrentLanguage ?? {};
          let value = deliverableForm.get(control.key).value;
          value = typeof value === 'string' ? value.trim() : value;
          formDataStandardTranslationCurrentLanguage[control.pairKey] =
            value ?? null;
        }
      }
      if (formDataStandardTranslationCurrentLanguage) {
        cachedTranslations[sourceLanguage.iso] =
          formDataStandardTranslationCurrentLanguage;
      }
    }
    dataToCache.translations = cachedTranslations;
    this.formCachingService.cacheFormData(
      dataToCache,
      formCachingServiceIdentifier
    );
  }
  getTargetControlFromSourceControlKey(
    allTranslatableFormControls: TranslatableFormControl[],
    sourceControlKey: string
  ): TranslatableFormControl {
    const sourceControl = allTranslatableFormControls.find(
      (c) => c.key === sourceControlKey && c.type === 'source'
    );
    if (sourceControl) {
      return allTranslatableFormControls.find(
        (c) => c.pairKey === sourceControl.pairKey && c.type === 'target'
      );
    }
  }
  getFormSourceLanguageControlsAsTextObjectForTranslation(
    sourceControls: TranslatableFormControl[],
    deliverableForm: FormGroup
  ): { [key: string]: string } {
    return sourceControls.reduce((acc, control) => {
      let value = deliverableForm.get(control.key).value;
      value = typeof value === 'string' ? value.trim() : value;
      acc[control.key] = value ?? null;
      return acc;
    }, {});
  }
  handleFormTranslations(
    translations: { [key: string]: string },
    deliverableForm: FormGroup,
    allTranslatableFormControls: TranslatableFormControl[],
    deliveryLocked: boolean
  ): void {
    const patchObject = {};
    for (const key in translations) {
      const targetControl = this.getTargetControlFromSourceControlKey(
        allTranslatableFormControls,
        key
      );
      if (targetControl && deliverableForm.get(targetControl.key)) {
        patchObject[targetControl.key] = translations[key];
        if (!deliveryLocked) {
          deliverableForm.get(targetControl.key).enable();
        }
      }
    }
    deliverableForm.patchValue(patchObject);
  }
  addOrRemoveEditingLanguage(
    deliverableForm: FormGroup,
    formCachingServiceIdentifier: string,
    allTranslatableFormControls: TranslatableFormControl[],
    allNonTranslatableFormControls: GenericFormControl[],
    targetControls : TranslatableFormControl[],
    sourceLanguage: Language,
    deliverable: Insight | Vote,
    deliverable_translations: { [key: string]: string },
    wordAndCharacterRequirements: {
        words?: WordAndCharacterRequirements[];
        characters?: WordAndCharacterRequirements[];
    },
    taskBasedFormControlRequirements: {
        required?: FormControlBooleanRequirements;
    },
    deliveryLocked: boolean,
  ): void {
    const translationSourceFormControls = allTranslatableFormControls.filter(control => control.type === 'source');
    if (!sourceLanguage) {
      if (deliverableForm) {
        for (const control of translationSourceFormControls) {
          if (deliverableForm.get(control.key)) {
            deliverableForm.removeControl(control.key);
            if (deliverableForm.get(control.pairKey)?.disabled && !deliveryLocked) {
              deliverableForm.get(control.pairKey).enable();
            }
          }
        }
        deliverableForm.updateValueAndValidity();
      }
    } else {
      const cachedFormData = this.getSavedAndCachedFormData(
        formCachingServiceIdentifier,
        allNonTranslatableFormControls,
        allTranslatableFormControls,
        deliverable,
        sourceLanguage,
        deliverable_translations
      );
      if (deliverableForm) {
        for (const control of translationSourceFormControls) {
          const cachedValue = cachedFormData.translations?.[control.pairKey];
          if (!deliverableForm.get(control.key)) {
            const translatableControlValidators = this.generateControlValidators(this.getFieldRequirements(
                allTranslatableFormControls,
                allNonTranslatableFormControls,
                wordAndCharacterRequirements,
                taskBasedFormControlRequirements,
                sourceLanguage.iso,
                control.pairKey,
                true)
            );
            deliverableForm.addControl(control.key, new FormControl(cachedValue, translatableControlValidators));
          } else {
            deliverableForm.patchValue({ [control.key]: cachedValue });
          }
          if (cachedValue) {
            deliverableForm.get(control.key).markAsDirty();
          }
          if (deliveryLocked) {
            deliverableForm.get(control.key).disable();
          }
        }
        this.disableTargetLanguageTranslatableFieldsIfNecessary(cachedFormData, sourceLanguage, targetControls, deliverableForm);
        deliverableForm.updateValueAndValidity();
      }
    }
  }
  disableTargetLanguageTranslatableFieldsIfNecessary(cachedFormData : FormDataStandard, sourceLanguage : Language, targetControls : GenericFormControl[], deliverableForm : FormGroup){ // Applies the rule that the source language should be done first
    if(sourceLanguage && this.checkAllCachedFieldsAreNull(cachedFormData, targetControls)){
      this.disableFormControls(deliverableForm,targetControls);
    }
  }
  prepareFormDataForSaving(
    deliverableForm: FormGroup,
    allTranslatableFormControls: TranslatableFormControl[],
    allNonTranslatableFormControls: GenericFormControl[],
    sourceLanguage: Language,
    truncatedTitle: string,
    action: 'update' | 'submit',
    deliveryTargetLanguage: string, // e.g. 'fr'
  ): any {
    const formData = { translations: null };
    const genericFormControlKeys = allNonTranslatableFormControls.map(control => control.key);
    const translationTargetFormControls = allTranslatableFormControls.filter(control => control.type === 'target');

    for (const key of genericFormControlKeys) {
      formData[key] = deliverableForm.get(key).value;
    }
    for (const control of translationTargetFormControls) {
      let value = deliverableForm.get(control.key).value;
      value = typeof value === 'string' ? value.trim() : value;
      const trimmedValue = value ?? null;
      if (action === 'update' && control.deliverableTitle && !trimmedValue) {
        // We will allow the user to save the deliverable with an empty title, but we'll insert a placeholder
        let prefix = '[TITLE NEEDED]';
        if (truncatedTitle?.startsWith(prefix)) {
          formData[control.deliverableAttribute] = truncatedTitle;
        } else {
          formData[control.deliverableAttribute] = prefix + ' ' + truncatedTitle;
        }
      } else {
        formData[control.deliverableAttribute] = trimmedValue;
      }
    }
    if (sourceLanguage) {
      let translations;
      const translationSourceFormControls = allTranslatableFormControls.filter(control => control.type === 'source');
      for (const control of translationSourceFormControls) {
        translations = translations ?? {};
        let value = deliverableForm.get(control.key).value;
        value = typeof value === 'string' ? value.trim() : value;

        // HANDLE MISSING TRANSLATIONS IN OPTIONAL FIELDS
        // Do not allow missing translations (missing target language) when the delivery expects a target language
        const fieldIsRequired = deliverableForm.get(control.key).validator?.({} as AbstractControl)?.required;
        const targetControlDefinition = allTranslatableFormControls.find(c => c.pairKey === control.pairKey && c.type === 'target');
        let valueOfTargetControl = deliverableForm.get(targetControlDefinition.key).value;
        const trimmedValueOfTargetControl = typeof valueOfTargetControl === 'string' ? valueOfTargetControl.trim() : valueOfTargetControl;
        if(!fieldIsRequired && value && !trimmedValueOfTargetControl && action === 'submit'){
          if(deliveryTargetLanguage){
            value = null;
          }
        }
        // END OF HANDLE MISSING TRANSLATIONS IN OPTIONAL FIELDS


        translations[control.deliverableAttribute] = value ?? null;
      }
      if (translations) {
        formData.translations = {
          [sourceLanguage.iso]: translations
        };
      }
    }
    return formData;
  }
  optionalTranslatableControlHasMissingTranslation(
    deliverableForm: FormGroup,
    allTranslatableFormControls: TranslatableFormControl[],
    controlDefinition: TranslatableFormControl
  ): boolean | null {
    if (!controlDefinition || controlDefinition.type !== 'source') {
      return null;
    }
    const formControl = deliverableForm.get(controlDefinition.key);
    if (!formControl) {
      return null;
    }
    const controlDefinitionTargetLanguage = allTranslatableFormControls.find(
      control => control.pairKey === controlDefinition.pairKey && control.type === 'target'
    );
    if (!controlDefinitionTargetLanguage) {
      return null; // This should never be true
    }
    const formControlTargetLanguage = deliverableForm.get(controlDefinitionTargetLanguage.key);
    if (!formControlTargetLanguage) {
      return null;
    }
    const formControlIsRequired = formControlTargetLanguage?.validator?.({} as AbstractControl)?.required;
    if (formControlIsRequired) {
      return null;
    }
    if (
      formControl.value &&
      formControl.valid &&
      !(formControlTargetLanguage.value ? formControlTargetLanguage.value.trim() : null) &&
      (formControl.touched || formControl.dirty)
    ) {
      return true;
    }
    return false;
  }
  generateInitialFormGroupObject(
    cachedFormData: FormDataStandard,
    allNonTranslatableFormControls: GenericFormControl[],
    translationTargetFormControls: TranslatableFormControl[],
    targetLanguage: Language,
    wordAndCharacterRequirements : {words?:WordAndCharacterRequirements[],characters?:WordAndCharacterRequirements[]},
    taskBasedFormControlRequirements : {required?:FormControlBooleanRequirements},

  ): { [key: string]: FormControl } {
    const formGroupObject: { [key: string]: FormControl } = {};

    for (const control of allNonTranslatableFormControls) {
      const untranslatableControlValidators: ValidatorFn[] = this.generateControlValidators(this.getFieldRequirements(
        translationTargetFormControls,
        allNonTranslatableFormControls,
        wordAndCharacterRequirements,
        taskBasedFormControlRequirements,
        targetLanguage.iso,
        control.key,
        false));
      formGroupObject[control.key] = new FormControl(cachedFormData[control.key], untranslatableControlValidators);
    }
    for (const control of translationTargetFormControls) {
      const translatableControlValidators: ValidatorFn[] = this.generateControlValidators(this.getFieldRequirements(
        translationTargetFormControls,
        allNonTranslatableFormControls,
        wordAndCharacterRequirements,
        taskBasedFormControlRequirements,
        targetLanguage.iso,
        control.pairKey,
        true)
    );
      formGroupObject[control.key] = new FormControl(cachedFormData[control.key], translatableControlValidators);
    }

    return formGroupObject;
  }
  checkAllCachedFieldsAreNull(cachedFormData : FormDataStandard, formControls : GenericFormControl[] = []) : boolean {
    return formControls.every(control => !cachedFormData?.[control.key]);
  };
  generateControlValidators(fieldRequirements: FieldRequirements): ValidatorFn[] {
    const controlValidators = [];
    if (fieldRequirements?.characters_min_required) {
      controlValidators.push(Validators.minLength(fieldRequirements.characters_min_required));
    }
    if (fieldRequirements?.characters_max_required) {
      controlValidators.push(Validators.maxLength(fieldRequirements.characters_max_required));
    }
    if (fieldRequirements?.required) {
      controlValidators.push(Validators.required);
    }
    return controlValidators;
  }
  noFormControlsAreNull(deliverableForm: FormGroup, formControls: GenericFormControl[]): boolean {
    if(!deliverableForm){return true;} 
    return formControls.every(control => ((deliverableForm.get(control.key)?.value) ?? '').trim() || null);
  };
  disableFormControls(deliverableForm: FormGroup, formControls: GenericFormControl[]){
    if(!deliverableForm || !formControls){return;};
    formControls.forEach(control => {
        deliverableForm.get(control.key)?.disable();
    });
  };

  isPlaceholderDeliveryTitle(title: string): boolean {
    if (!title) {
      return false;
    }
    return this._placeholderDeliveryTitles.some((placeholderTitle) =>
      title.startsWith(placeholderTitle)
    );
  }
}
