import { Injectable } from '@angular/core';
import { Link } from '@frontend/core';
import { ContentItem, MediaService, MetaText } from '../content';
import { DataProcessingService } from '../utilities';
import { RangeInputStep } from '../forms';
import { ColourService } from '../branding';

@Injectable({
  providedIn: 'root',
})
export class DesignService {
  loremIpsum: string;
  randomIconClassses : string[];
  private _rangeInputSteps : RangeInputStep[] = [];


  constructor( private mediaService : MediaService, private dataProcessingService : DataProcessingService, private colourService : ColourService) {
    this.loremIpsum =
      'Lorem ipsum dolor sit amet consectetur adipiscing elit Donec a diam vel ex volutpat molestie eget at nisl Aenean convallis sodales vestibulum Duis sed odio tortor Quisque et sapien laoreet';
    const iconClassses = [
      'check',
      'atom',
      'balance-scale',
      'bell',
      'bookmark',
      'briefcase',
      'bullseye',
      'calendar',
      'chart-pie',
      'check',
      'comment',
    ];
    this.randomIconClassses = iconClassses.map(ic=>'fa-solid fa-'+ic);
    for (let i = 0; i <= 8; i++) {
      this._rangeInputSteps.push({
        value: i,
        labelTranslationKey: `test.score_level_feedback.${i}`,
        descriptionTranslationKey: `test.score_level_feedback.${i}_comment`,
        descriptionShortTranslationKey: `test.score_level_feedback.${i}_comment`,
        colour: this.colourService.trafficLightsAsHex[i],
      });
    }
  }

  get rangeInputSteps() : RangeInputStep[]{
    return this._rangeInputSteps;
  }

  generateLoremIpsum(wordCount: number, capitaliseFirstLetter : boolean = true) {
    let words = this.loremIpsum.split(/(\s+)/).filter((word) => word !== ' ');

    if (words.length < wordCount) {
      let duplicatedWords = [...words];

      while (duplicatedWords.length < wordCount) {
        duplicatedWords = duplicatedWords.concat(words);
      }
      words = duplicatedWords;
    }

    // Shuffle the array of words
    for (let i = words.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [words[i], words[j]] = [words[j], words[i]];
    }

      let loremIpsum = words.slice(0, wordCount).join(' ');

    return capitaliseFirstLetter ? loremIpsum.charAt(0).toUpperCase() + loremIpsum.slice(1) : loremIpsum;
  }
  getPlaceholderMetaTextSingle(id : number = null, withTextAndTitle : boolean = false, withIcon : boolean = true): MetaText {
    let metaText : MetaText = {
      id: id,
      text: this.generateLoremIpsum(this.dataProcessingService.getRandomInteger(5, 15)),
      icon: withIcon ? { identifier_1: this.randomIconClassses[Math.floor(Math.random()*this.randomIconClassses.length)], identifier_2: null } : null,
    };
    if (withTextAndTitle) {
      metaText.s = metaText.text;
      metaText.m = this.generateLoremIpsum(this.dataProcessingService.getRandomInteger(15,40))
    }
    return metaText;
  }

  generateMetaText(count: number, withTextAndTitle : boolean = false, withIcon : boolean = true): MetaText[] {
    let metaTexts: MetaText[] = [];
    for (let i = 0; i < count; i++) {
      metaTexts.push(this.getPlaceholderMetaTextSingle(i+1, withTextAndTitle, withIcon));
    }
    return metaTexts;
  }

  getPlaceholderLink() {
    // make a dummy link
    let link: Link = {
      id: 244,
      name: 'A podcast about virtual teams',
      label: '21st Century Work Life',
      url: 'https://www.virtualnotdistant.com/podcasts',
      description:
        'A podcast about virtual teams and other aspects of working life. Hosted by Pilar Orti.',
      cta_label: 'Listen',
      cta_type: 'podcast',
      category: null,
      type: null,
      media: {
        id: 156,
        medially_type: 'App\\Models\\Link',
        medially_id: 244,
        file_url:
          'https://res.cloudinary.com/cebt/image/upload/v1671029175/resources/podcastlogo_yagluo.png',
        file_name: 'resources/podcastlogo_yagluo.png',
        file_type: 'image',
        size: 22870,
        uploader_id: 1,
        description: 'Podcast logo: 21st Century Work Life',
        credits: 'Pilar Orti',
        permission: null,
        category: 'intercultural',
        base_url: 'https://res.cloudinary.com/cebt/image/upload/',
        transformations_url: 'c_thumb,w_200,g_face/',
        asset_url:
          'v1611931415/persons/smiling-businessperson-globalintegration-flickr-cc2-5347195359_8b3a32e4ab_o_y5tcks.jpg',
        type: 'concepts',
        created_at: new Date('2022-12-14T13:00:03.000000Z'),
        updated_at: new Date('2022-12-14T13:00:03.000000Z'),
      },
      icon: {
        identifier_1: 'fa-clock',
        identifier_2: null,
      },
    };
    return link;
  }

  getPlaceholderContentItem(include:{subtitle?:boolean,media?:boolean}={}): ContentItem {
    // generate a dummy instance

    const contentItem = {
      id: 59,
      title: this.generateLoremIpsum(8), // 'Ipsum vitae sed voluptate tempora molestiae eum odio.',
      titleTranslationKey: null,
      description: this.generateLoremIpsum(14), // 'Dolorem odio et cupiditate qui tenetur dignissimos et veniam facilis aspernatur dicta tempora ut.',
      subtitle: include.subtitle ? this.generateLoremIpsum(14) : null,
      subtitleTranslationKey: null,
      descriptionTranslationKey: null,
      cta_text: null,
      cta_translationKey: null,
      body: '',
      slug: 'ipsum-vitae-sed-voluptate-tempora-molestiae-eum-odio17164',
      tags: [
        {
          id: 233,
          name: 'Cayman Islands',
          slug: 'cayman-islands',
          type: 'culture',
          icon_type: null,
          icon_identifier: null,
          img_url: null,
        },
        {
          id: 238,
          name: 'Quebec',
          slug: 'quebec',
          type: 'culture',
          icon_type: null,
          icon_identifier: null,
          img_url: null,
        },
        {
          id: 494,
          name: 'CultureConnector',
          slug: 'cultureconnector',
          type: 'topic',
          icon_type: null,
          icon_identifier: null,
          img_url: null,
        },
        {
          id: 548,
          name: 'Impact evaluation',
          slug: 'impact-evaluation',
          type: 'tool_feature',
          icon_type: 'fa',
          icon_identifier: 'fa-solid fa-weight-scale',
          img_url: null,
        },
      ],
      media: include.media ? this.mediaService.getPlaceholder() : null,
      rating: 4,
      video: {
        name: this.generateLoremIpsum(8), //'Interview about recruitment with Nannette Ripmeester on the Intercultural Toolbox',
        host_identifier: '625875981',
        host_params: null,
        duration: 2869000,
        host: 'vimeo',
      },
      additional_media: null,
      user: null,
      meta: null,
      duration: 43,
      date: null,
    };

    return contentItem;
  }
}
