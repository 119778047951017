<div class="container" *transloco="let t">
  <div class="row">
    <div class="col-md-8 p-2">
      <p *ngIf="!isAnythingLoading() && !paginatedDeliveries?.data.length">
        <span>{{ t('common.none_yet') }}</span>
      </p>
      <multisite-loader-globe-animation-detail
        *ngIf="loadingObject.initialData"
        [width]="100"
        [height]="100"
      ></multisite-loader-globe-animation-detail>

      <div class="d-flex flex-column gap-2">
        <multisite-delivery-status-card
          *ngFor="let delivery of paginatedDeliveries?.data"
          [delivery]="delivery"
          (selected)="gotoDelivery($event)"
          [showCredits]="hasRole('Admin')"
        ></multisite-delivery-status-card>
      </div>

      <div class="my-3">
        <frontend-pagination-type-three
          *ngIf="
            paginatedDeliveries?.meta && paginatedDeliveries.meta.last_page > 1
          "
          [classes]="'pg-purple justify-content-center'"
          [loading]="isAnythingLoading()"
          [meta]="paginatedDeliveries.meta"
          [lastPage]="paginatedDeliveries.meta.last_page"
          [links]="paginatedDeliveries.links"
          (changePage)="doGetPaginatedDeliveries($event, false)"
        ></frontend-pagination-type-three>
      </div>
    </div>
    <div class="col-md-4 p-2">
      @if(hasRole('Admin')){
        <div class="d-flex align-items-center flex-wrap gap-1 w-100">
          <multisite-score-highlight
            class="flex-grow-1"
            [titleText]="'Credits'"
            [iconClasses]="'fa-solid fa-coins'"
            [score]="20000"
          ></multisite-score-highlight>
          <multisite-score-highlight
            class="flex-grow-1"
            [titleText]="'Tasks'"
            [iconClasses]="'fa-solid fa-square-check'"
            [score]="16"
            [denominator]="52"
            [rgbColourVariable]="'var(--bs-success-rgb)'"
          ></multisite-score-highlight>
        </div>
      }
      @if(hasRole('Admin')){
        <multisite-link-type-six
          *ngFor="let link of links"
          [imageTransformations]="'w_100,c_thumb/'"
          [titleText]="link.label"
          [description]="link.description"
          [imageUrl]="link.media?.base_url"
          [media]="link.media"
          [cta_label]="link.cta_label"
          [cta_type]="link.cta_type"
          [url]="link.url"
        ></multisite-link-type-six>
      }
    </div>
  </div>
</div>
