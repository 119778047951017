export class WordAndCharacterRequirements {

        languages: string[]; // iso language keys; for example ['zh','jp','ko']; empty means all/default languages
        field_keys: string[]; // form field keys, for example 'description' or 'heading'
        warning: {
            min: number;
            max: number;
        };
        required: {
            min: number;
            max: number;
        };
        
  }